/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a input text
 */

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

class AsyncSearchSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            validateMsg: '',
            isValid: true,
        };
    }

    //on change handler
    onChange = (selectedValue) => {
        const { value } = selectedValue;

        const { isValid, validateMsg } = this.validate(value);

        this.setState({ value, isValid, validateMsg });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    //do validation
    validate = (checkValue) => {
        const value = checkValue || this.state.value;

        let isValid = true;
        let validateMsg = '';
        if (this.props.validateFunc) {
            const validObj = this.props.validateFunc(value);
            if (!validObj.validated) {
                isValid = false;
                validateMsg = validObj.msg;
            }
        }

        return { isValid, validateMsg };
    };

    //set validation msg
    setValidation = (isValid, validateMsg) => {
        this.setState({ isValid, validateMsg });
    };

    render() {
        let className = 'input-text';

        className = className ? 'form-select form-select-blue ' + className : 'form-select form-select-blue';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        //select components
        const DropdownIndicator = (props) => (
            <components.DropdownIndicator {...props}>
                <div className="btn-drop-async" />
            </components.DropdownIndicator>
        );
        const customStyles = {
            valueContainer: () => ({
                paddingLeft: 0,
                height: 20,
            }),
            singleValue: () => ({
                marginLeft: 0,
                fontSize: 17,
                fontWeight: 500,
                color: '#000',
            }),
        };

        let innerClass = 'inner';
        if (this.props.noBorder) {
            innerClass += ' no-border';
        }

        const isNotValid = !!this.props.validateFunc && !this.state.isValid;
        let titleClass = 'title';
        if (isNotValid) {
            titleClass += ' red';
        }

        return (
            <div className={className}>
                <div className={innerClass}>
                    <div className={titleClass}>{this.props.title || ''}</div>
                    <AsyncSelect
                        role={this.props.role}
                        components={{ DropdownIndicator }}
                        classNamePrefix="selectcomp"
                        loadOptions={this.props.loadOptions}
                        placeholder={this.props.placeholder}
                        styles={customStyles}
                        isClearable={false}
                        onChange={this.onChange}
                        defaultOptions={this.props.defaultOptions}
                        defaultValue={this.props.defaultValue}
                        noOptionsMessage={() => this.props.noOptionsMessage}
                    />
                </div>
                {isNotValid && <div className="error">{this.state.validateMsg}</div>}
            </div>
        );
    }
}

AsyncSearchSelectComponent.defaultProps = {
    title: '',
    defaultOptions: [],
    defaultValue: null,
    placeholder: '',
    noOptionsMessage: 'No options',
    className: null,
    noBorder: false,
    role: 'selectAsyncTestId',
    validateFunc: null,
    onChange: () => {},
    loadOptions: () => [],
};
AsyncSearchSelectComponent.propTypes = {
    title: PropTypes.string,
    defaultOptions: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    noBorder: PropTypes.bool,
    role: PropTypes.string,
    validateFunc: PropTypes.func,
    onChange: PropTypes.func,
    loadOptions: PropTypes.func,
};

export default AsyncSearchSelectComponent;
