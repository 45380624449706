/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: secureContractsList
 * path: /contracts
 *
 * this is a page that shows added contracts
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import HeaderSecure from '../../../components/theme/HeaderSecure';
import SearchWidget from '../../../components/elements/SearchWidget';
import ProviderImage from '../../../components/elements/ProviderImage';
import Popup from '../../../components/popups/Popup';
import Alert from '../../../components/popups/Alert';
import Loading from '../../../components/elements/Loading';
import pageUrl from '../../../const/pages';
import config from '../../../config';
import trans from '../../../trans';
import Utils from '../../../lib/Utils';
import '../../../styles/secure.scss';

const ContractsListPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //state variables
    const defContract = ContractsStore.getFilteredContracts();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [contracts, setContracts] = useState(defContract);

    //ref variables
    const refPopup = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //filter contracts handler that allows to set filtered contracts array
    const filterContracts = (searchText, statusContract, order) => {
        setContracts(ContractsStore.getFilteredContracts(searchText, statusContract, order));
    };

    //show cashback popup
    // const showCashbackPopup = async (contractBrokerStatusValue, contractId) => {
    //     switch (contractBrokerStatusValue) {
    //         case 'active_without_fee':
    //         case 'reject':
    //             refPopup.current.show();
    //             break;
    //         case 'open':
    //         case 'deactivation_broker':
    //             await activateBrokerContract(contractId);
    //             break;
    //         default:
    //             break;
    //     }
    //     return true;
    // };

    //activate contract cashback

    // const activateBrokerContract = async (contractId) => {
    //     setIsLoading(true);
    //     const res = await ContractsStore.activateBrokerForContracts([contractId]);
    //     if (!res.status) {
    //         setIsLoading(false);
    //         refAlert.current.error(res.msg);
    //         return false;
    //     }
    //     const newContracts = ContractsStore.getFilteredContracts();
    //     setContracts(newContracts);
    //     setIsLoading(false);
    //     return true;
    // };

    //render contracts function
    const renderContract = (contract, index) => {
        const { productTitle, providerTitle, providerLogoUrl, numberValue, yearlyCostValue, statusTitle, numberDocuments, tariffsArr } = contract;
        return (
            <div className="item" key={index}>
                <div className="item-title" onClick={() => navigate(pageUrl.secureContractDetail, { state: { contractId: contract.idValue } })}>
                    <div className="title">{providerTitle}</div>
                    <div className="title-product">{productTitle}</div>
                </div>
                <div className="detail">
                    <div className="detail-item provider">
                        <ProviderImage title={providerTitle} imageSrc={providerLogoUrl} />
                    </div>
                    <div className="detail-item">
                        <div className="title">{trans.t('secureContractsListPage', 'contractNumber')}.:</div>
                        <div>{numberValue || '-'}</div>
                    </div>
                    <div className="detail-item">
                        <div className="title">{trans.t('secureContractsListPage', 'contractYearlyCost')}:</div>
                        <div>{Utils.formatPrice(yearlyCostValue)}</div>
                    </div>
                    <div className="detail-item">
                        <div className="title">{trans.t('secureContractsListPage', 'contractStatus')}:</div>
                        <div>{statusTitle || '-'}</div>
                    </div>
                    <div className="detail-item">
                        <div className="title">{trans.t('secureContractsListPage', 'contractDocuments')}:</div>
                        <div>{numberDocuments}</div>
                        <div className="add-documents" onClick={() => navigate(pageUrl.secureContractDocuments, { state: { contractId: contract.idValue } })}>
                            {trans.t('secureContractsListPage', 'btnAddDocuments')}
                        </div>
                    </div>
                    {!!tariffsArr.length && (
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractsListPage', 'contractTariffs')}:</div>
                            {tariffsArr.map((tariff, index) => (
                                <div key={index}>{tariff}</div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="app secure-contract-list-page">
            <HeaderSecure isTopMenu isSlideMenu selected={pageUrl.secureContractsList} isGradient>
                <div className="contract-filter">
                    <h1>{trans.t('secureContractsListPage', 'h1')}</h1>
                    <div className="contract-filter-inner">
                        <div className="button-new" onClick={() => navigate(pageUrl.onboardingContractAdd)}>
                            {trans.t('secureContractsListPage', 'btnNewContract')}
                        </div>
                        <SearchWidget onChange={filterContracts} filterList={DataStore.contractStatusList} />
                    </div>
                </div>
            </HeaderSecure>
            <div className="content">
                <div className="wrapper-big">
                    <div className="contracts">{contracts.map((contract, index) => renderContract(contract, index))}</div>
                </div>
            </div>
            <Popup
                ref={refPopup}
                isAutoHeight
                title={trans.t('secureContractsListPage', 'contractPopupTitle')}
                leftBtnTitle={trans.t('secureContractsListPage', 'contractPopupLeftBtnTitle')}
                rightBtnTitle={trans.t('secureContractsListPage', 'contractPopupRightBtnTitle')}
                rightFunc={() => window.open(config.bitrix24Urls.contractPopupUrl, '_blank').focus()}
            >
                <div className="contract-popup-text">
                    {trans.t('secureContractsListPage', 'contractPopupText1')}
                    <br />
                    <br />
                    {trans.t('secureContractsListPage', 'contractPopupText2')}
                </div>
            </Popup>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractsListPage;
