/**
 * Created by Max Gornostayev on 09/26/22
 *
 * pageId: authStartLogin
 * path: /start/login
 *
 * this is a start page where user could select what he needs: login with person or company account
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';

const StartLoginPage = observer(() => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const nextPrivatePerson = () => {
        navigate(pageUrl.authSigninPage);
    };

    //button handler
    const nextCompany = () => {
        navigate(pageUrl.authSigninPage, { state: { isCompany: true } });
    };

    //main rendering
    return (
        <div className="app auth-start-page" role="app">
            <Header />
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        <h1>{trans.t('authStartLoginPage', 'h1')}</h1>
                        <div className="desc">{trans.t('authStartLoginPage', 'text')}</div>
                        <Button role="btnPrivatePerson" text={trans.t('authStartLoginPage', 'registerPrivateBtn')} onClick={nextPrivatePerson} isFullWidth className="margin-top-48" />
                        <Button
                            role="btnCompany"
                            text={trans.t('authStartLoginPage', 'registerCompanyBtn')}
                            onClick={nextCompany}
                            isFullWidth
                            className="margin-top-12"
                            isTransparentOrange
                        />
                        <div className="row-center">
                            <ButtonLink
                                role="btnLogin"
                                text={trans.t('authStartLoginPage', 'registerLoginBtn')}
                                onClick={() => navigate(pageUrl.authStartRegister)}
                                className="margin-top-70"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
        </div>
    );
});

export default StartLoginPage;
