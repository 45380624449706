/**
 * Created by Max Gornostayev on 02/15/22
 *
 * This is a class determines translation logic
 */

import langFile from './lang/de';

class Trans {
    /*
     * get translation from file
     * @id1 - string - collection in object of translation file
     * @id2 - string - property of collection - where translation is written
     * @defaul string - default value, by default it's empty string
     */
    static t(id1, id2, defaul) {
        if (defaul !== '' && !defaul) {
            defaul = '';
        }

        if (!langFile.hasOwnProperty(id1)) {
            return defaul;
        }
        if (!langFile[id1].hasOwnProperty(id2)) {
            return typeof langFile[id1] === 'string' ? langFile[id1] : defaul;
        }
        return langFile[id1][id2];
    }

    /*
     * get translation from file
     * @id1 - string - collection in object of translation file
     * @id2 - string - property of collection - where translation is written
     * @params - object - that contains params that should be replaced
     * @defaul string - default value, by default it's empty string
     */
    static tParams(id1, id2, params, defaul) {
        let translation = Trans.t(id1, id2, defaul);
        Object.keys(params).map((key) => {
            translation = translation.replace(`{${key}}`, params[key]);
        });
        return translation;
    }

    /*
     * get translation and convert html tags
     * @id1 - string - collection in object of translation file
     * @id2 - string - property of collection - where translation is written
     * @defaul string - default value, by default it's empty string
     */
    static tHTML(id1, id2, defaul) {
        let translation = Trans.t(id1, id2, defaul);
        translation = translation.replace('\n', '<br />');
        let strings = [];

        const condition = (t, tag) => {
            return t && t.indexOf(tag) !== -1;
        };

        const findIndexes = (translation, strings, type, tagStart, tagEnd) => {
            if (!condition(translation, tagStart)) {
                return false;
            }
            const indexStart = translation.indexOf(tagStart);
            const indexEnd = translation.indexOf(tagEnd);
            if (indexStart === -1 || indexEnd === -1) {
                return false;
            }
            strings.push({
                text: translation.slice(0, indexStart),
                type: 'text',
            });
            strings.push({
                text: translation.slice(indexStart + 3, indexEnd),
                type,
            });
            translation = translation.slice(indexEnd + 4);

            return { translation, strings };
        };

        let isFound = true;
        while (isFound) {
            let isBreak = true;
            const objBold = findIndexes(translation, strings, 'bold', '{b}', '{/b}');
            if (objBold) {
                isBreak = false;
                translation = objBold.translation;
                strings = objBold.strings;
            }
            const objUnderline = findIndexes(translation, strings, 'underline', '{u}', '{/u}');
            if (objUnderline) {
                isBreak = false;
                translation = objUnderline.translation;
                strings = objUnderline.strings;
            }
            const objNewline = findIndexes(translation, strings, 'newline', '{n}', '{/n}');
            if (objNewline) {
                isBreak = false;
                translation = objNewline.translation;
                strings = objNewline.strings;
            }
            isFound = !isBreak;
        }
        if (translation.length > 0) {
            strings.push({ text: translation, type: 'text' });
        }
        const renderItem = (item, key) => {
            switch (item.type) {
                case 'bold':
                    return (
                        <span key={key} className="bold" role={'bold-' + key}>
                            {item.text}
                        </span>
                    );
                case 'underline':
                    return (
                        <span key={key} className="underline" role={'underline-' + key}>
                            {item.text}
                        </span>
                    );
                case 'newline':
                    return (
                        <span key={key} className="newline">
                            {item.text}
                        </span>
                    );
                case 'text':
                default:
                    return item.text;
            }
        };
        return <div>{strings.map((item, key) => renderItem(item, key))}</div>;
    }
}

export default Trans;
