/**
 * Created by Max Gornostayev on 04/30/22
 *
 * path: /auth/email-verifizieren
 * id: authSignupStepThree
 *
 * this is a page that shows third-step registration form, after this page we need go to the fourth-step
 * registration page where we must enter password and check it
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { timer } from 'rxjs';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import SignupSteps from '../../components/elements/SignupSteps';
import pageUrl from '../../const/pages';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import trans from '../../trans';
import '../../styles/terms.scss';
import '../../styles/auth.scss';

const SignupStepThreePage = observer(({ UserStore }) => {
    const { customer, isCompany } = UserStore;

    //state variables
    const [isEmailNotSent, setIsEmailNotSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //get prop 'emailToken' and 'passwordToken' from another screen
    const { state } = useLocation();
    const emailToken = state && state.hasOwnProperty('emailToken') ? state.emailToken : '';
    const passwordToken = state && state.hasOwnProperty('passwordToken') ? state.passwordToken : '';

    //ref variable
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //subscription
    let subscription = null;

    //start countdown when page is mounted
    useEffect(() => {
        if (!UserStore.isCompany) {
            subscription = timer(200).subscribe(() => sendConfirmation());
        }

        return () => {
            if (subscription && subscription.unsubscribe) subscription.unsubscribe();
        };
    }, []);

    //send confirmation email
    const sendConfirmation = async () => {
        if (!isEmailNotSent) {
            const res = await customer.sendEmailConfirmation(emailToken);
            if (!res.status) {
                setIsEmailNotSent(true);
            }
        }
    };

    const repeatConfirmation = async () => {
        setIsLoading(true);
        if (!UserStore.isCompany) {
            const res = await customer.sendEmailConfirmation(emailToken);
            setIsLoading(false);
            if (!res.status) {
                refAlert.current.error(res.msg);
                return false;
            }
        } else {
            const res = await UserStore.updateEmailPhone({});
            setIsLoading(false);
            if (!res.status && res.errorId !== 'otp_is_already_sent') {
                refAlert.current.error(res.msg);
                return false;
            }
        }
        refAlert.current.show(trans.t('signupStepThreePage', 'confirmationIsSent'));
        return true;
    };

    //send confirmation email
    const ctaClick = () => navigate(pageUrl.authSignupStepFour, { state: { token: passwordToken } });

    return (
        <div className="app auth signup-step-3-page">
            <Header isBack expertMsg={trans.t('signupStepThreePage', 'expertMsg')} />
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-top-inner">
                        <h1>{trans.t('signupStepThreePage', 'h1')}</h1>
                        <SignupSteps currentStep={3} />
                    </div>
                    <div className="item">
                        <div className="item-number">01</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('signupStepThreePage', 'titleLinkEmail')}</div>
                            <div className="item-text">{trans.t('signupStepThreePage', 'descLinkEmail')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">02</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('signupStepThreePage', 'titleEmailLetter')}</div>
                            {!UserStore.isCompany && <div className="item-text">{trans.t('signupStepThreePage', 'descEmailLetterPrivate')}</div>}
                            {UserStore.isCompany && (
                                <div className="item-text">
                                    {trans.t('signupStepThreePage', 'descEmailLetter')}
                                    <span className="item-link" onClick={repeatConfirmation}>
                                        {trans.t('signupStepThreePage', 'descEmailLink')}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">03</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('signupStepThreePage', 'titleCreatePassw')}</div>
                            <div className="item-text">{trans.t('signupStepThreePage', 'descCreatePassw')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">04</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('signupStepThreePage', 'titleViewContract')}</div>
                            <div className="item-text">{trans.t('signupStepThreePage', 'descViewContract')}</div>
                        </div>
                    </div>
                </div>
            </div>
            {!isCompany && (
                <Bottom>
                    <Button role="btnNext" text={trans.t('signupStepThreePage', 'btnNext')} onClick={ctaClick} isRightIcon width={350} />
                </Bottom>
            )}
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignupStepThreePage;
