/**
 * Created by Max Gornostayev on 10/5/22
 *
 * this is a component that show expert component
 */

import React from 'react';
import IconExpert from '../../assets/icon-expert.svg';
import trans from '../../trans';

export default function ExpertContent({ expertClass, expertTitle, expertMsg }) {
    return (
        <div className={'expert-content-element ' + (expertClass || '')}>
            <div className="wrapper">
                <img src={IconExpert} alt="Experte" />
                <div className="texts">
                    <div className="title">{expertTitle || trans.t('common', 'expertTitle')}</div>
                    <div className="text">{expertMsg}</div>
                </div>
            </div>
        </div>
    );
}
