const obj = {
    products: [
        {
            id: '30404',
            title: 'Ausbildungsversicherung',
            serviceplatformId: 'educational_policy',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30405',
            title: 'Bauleistungsversicherung',
            serviceplatformId: 'construction_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30407',
            title: 'Bausparkredit',
            serviceplatformId: 'building_credit',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26666'],
            status: '714',
        },
        {
            id: '30409',
            title: 'Bauherrenhaftpflichtversicherung',
            serviceplatformId: 'property_construction',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30410',
            title: 'Bauherrenrechtsschutzversicherung',
            serviceplatformId: 'construction_legal_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30411',
            title: 'Feuer- Rohbauversicherung',
            serviceplatformId: 'fire_insurance_shell',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30412',
            title: 'Betriebliche Altersvorsorge',
            serviceplatformId: 'company_pension',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26662'],
            status: '714',
        },
        {
            id: '30413',
            title: 'Berufshaftpflichtversicherung',
            serviceplatformId: 'professional_liability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30414',
            title: 'Berufsunfähigkeitsversicherung',
            serviceplatformId: 'disability',
            isBrokerPossible: true,
            finapiId: 'DISABILITYINSURANCE',
            categories: ['26669'],
            status: '714',
        },
        {
            id: '30415',
            title: 'Betriebshaftpflichtversicherung',
            serviceplatformId: 'public_liability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30416',
            title: 'Bootsversicherung',
            serviceplatformId: 'boat_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30417',
            title: 'Dienstunfähigkeitsversicherung',
            serviceplatformId: 'invalidity_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26669'],
            status: '714',
        },
        {
            id: '30418',
            title: 'Schwere-Krankheiten-Versicherung',
            serviceplatformId: 'dread_disease',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30419',
            title: 'Einkommensversicherung',
            serviceplatformId: 'income_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30420',
            title: 'Elektronikversicherung',
            serviceplatformId: 'electronics',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30421',
            title: 'Mobile Elektronikversicherung',
            serviceplatformId: 'mobile_electronics_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30422',
            title: 'Stationäre Elektronikversicherung',
            serviceplatformId: 'stationary_electronics_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30423',
            title: 'Gewerbliche Wohngebäudeversicherung',
            serviceplatformId: 'business_building_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30424',
            title: 'Gewerbliche PKW Versicherung',
            serviceplatformId: 'business_car_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30425',
            title: 'Gewerbliche Rechtsschutzversicherung',
            serviceplatformId: 'business_law_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30426',
            title: 'Gewerbefinanzierung',
            serviceplatformId: 'business_credit',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30427',
            title: 'Gesetzliche Rentenversicherung',
            serviceplatformId: 'public_pension_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26662'],
            status: '714',
        },
        {
            id: '30430',
            title: 'Gesetzliche Krankenversicherung',
            serviceplatformId: 'public_health_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30431',
            title: 'Gewässerschadenhaftpflichtversicherung',
            serviceplatformId: 'water_damage_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30432',
            title: 'Glasversicherung',
            serviceplatformId: 'glass_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30433',
            title: 'Haushaltsversicherung',
            serviceplatformId: 'household_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30434',
            title: 'Haushaltsgeräteversicherung',
            serviceplatformId: 'household_appliances_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30435',
            title: 'Hunde OP-Versicherung',
            serviceplatformId: 'dog_operation_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30436',
            title: 'Hundehalterhaftplichtversicherung',
            serviceplatformId: 'liability_insurance_for_dogs',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30437',
            title: 'Hundekrankenversicherung',
            serviceplatformId: 'dog_health_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30438',
            title: 'Auto-Inhaltsversicherung',
            serviceplatformId: 'car_content_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30439',
            title: 'Kapitallebensversicherung',
            serviceplatformId: 'endowment_policy',
            isBrokerPossible: true,
            finapiId: 'ENDOWMENTINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30440',
            title: 'Kautionsversicherung',
            serviceplatformId: 'fidelity_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30441',
            title: 'Mietkautionsversicherung',
            serviceplatformId: 'rent_deposit_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30442',
            title: 'KFZ Schutzbrief',
            serviceplatformId: 'car_protection_letter',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30443',
            title: 'KFZ-Teilkaskoversicherung inkl. Haftpflicht',
            serviceplatformId: 'car_partly_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30444',
            title: 'Kfz-Haftpflichtversicherung',
            serviceplatformId: 'car_liability_insurance',
            isBrokerPossible: true,
            finapiId: 'CARINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30445',
            title: 'Kfz-Vollkaskoversicherung inkl. Haftpflicht und Teilkasko',
            serviceplatformId: 'car_fully_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30446',
            title: 'Krankentagegeldversicherung',
            serviceplatformId: 'sickness_daily_allowance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30447',
            title: 'Pferde OP-Versicherung',
            serviceplatformId: 'horse_operation_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30448',
            title: 'Pferdekrankenversicherung',
            serviceplatformId: 'horse_health_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30449',
            title: 'Beihilfe Krankenversicherung',
            serviceplatformId: 'allowance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30450',
            title: 'Krankenzusatzversicherung',
            serviceplatformId: 'supplementary_health_insurance',
            isBrokerPossible: true,
            finapiId: 'SUPPLEMENTARYHEALTHINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30451',
            title: 'Pferdehalterhaftpflichtversicherung',
            serviceplatformId: 'horse_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30452',
            title: 'Pflegerente',
            serviceplatformId: 'care_pension',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26669'],
            status: '714',
        },
        {
            id: '30453',
            title: 'Photovoltaik-Versicherung',
            serviceplatformId: 'photovoltaic_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30456',
            title: 'Autokredit',
            serviceplatformId: 'car_credit',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26666'],
            status: '714',
        },
        {
            id: '30458',
            title: 'Gebäuderechtsschutzversicherung',
            serviceplatformId: 'building_legal_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30459',
            title: 'Private Rechtsschutzversicherung',
            serviceplatformId: 'legal_expense_insurance',
            isBrokerPossible: true,
            finapiId: 'LEGALINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30460',
            title: 'Reisekrankenversicherung',
            serviceplatformId: 'travel_health_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30461',
            title: 'Reiseversicherung',
            serviceplatformId: 'travel_insurance',
            isBrokerPossible: false,
            finapiId: 'TRAVELINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30462',
            title: 'Gruppenreiseversicherung',
            serviceplatformId: 'group_travel_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30463',
            title: 'Private Rentenversicherung',
            serviceplatformId: 'private_pension_insurance',
            isBrokerPossible: true,
            finapiId: 'PRIVATEPENSIONINSURANCE',
            categories: ['26669'],
            status: '714',
        },
        {
            id: '30465',
            title: 'Riester-Rente',
            serviceplatformId: 'riester_pension',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26662'],
            status: '714',
        },
        {
            id: '30466',
            title: 'Rürüp-/Basisrente',
            serviceplatformId: 'base_pension',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26662'],
            status: '714',
        },
        {
            id: '30467',
            title: 'Sterbegeld',
            serviceplatformId: 'death_benefit',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30468',
            title: 'Unfallversicherung',
            serviceplatformId: 'accident_insurance',
            isBrokerPossible: true,
            finapiId: 'ACCIDENTINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30469',
            title: 'Veranstaltungshaftpflichtversicherung',
            serviceplatformId: 'event_liability_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30470',
            title: 'Vermögensschadenhaftpflichtversicherung',
            serviceplatformId: 'property_loss_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30471',
            title: 'Wohngebäudeversicherung',
            serviceplatformId: 'homeowners_insurance',
            isBrokerPossible: true,
            finapiId: 'HOMEINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30473',
            title: 'Reiseabbruchversicherung',
            serviceplatformId: 'travel_interruption_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30474',
            title: 'Reisegepäckversicherung',
            serviceplatformId: 'baggage_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30475',
            title: 'Reisehaftpflichtversicherung',
            serviceplatformId: 'travel_liability_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30476',
            title: 'Reiserücktrittsversicherung',
            serviceplatformId: 'travel_cancelation',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30477',
            title: 'Reiseunfallversicherung',
            serviceplatformId: 'travel_accident_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30478',
            title: 'Katzen OP-Versicherung',
            serviceplatformId: 'cat_operation_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30479',
            title: 'Katzenkrankenversicherung',
            serviceplatformId: 'cat_health_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30480',
            title: 'Anhängerversicherung',
            serviceplatformId: 'trailer_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30481',
            title: 'Wohnwagen Versicherung',
            serviceplatformId: 'caravan_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30482',
            title: 'Wohnmobil-Versicherung',
            serviceplatformId: 'camper_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30483',
            title: 'Motorrad Versicherung',
            serviceplatformId: 'motorcycle_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30484',
            title: 'Inventarversicherung',
            serviceplatformId: 'inventory_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30485',
            title: 'Erwerbsunfähigkeitsversicherung',
            serviceplatformId: 'unemployability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30486',
            title: 'Ferienmobilversicherung',
            serviceplatformId: 'holiday_mobile_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30487',
            title: 'Auslandskrankenversicherung',
            serviceplatformId: 'foreign_health_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30488',
            title: 'Diensthaftpflichtversicherung',
            serviceplatformId: 'service_liability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30489',
            title: 'LWK Versicherung',
            serviceplatformId: 'truck_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30490',
            title: 'Private Haftpflichtversicherung',
            serviceplatformId: 'private_liability',
            isBrokerPossible: true,
            finapiId: 'LIABILITYINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30491',
            title: 'Private Krankenversicherung',
            serviceplatformId: 'private_health_insurance',
            isBrokerPossible: true,
            finapiId: 'HEALTHINSURANCE',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30492',
            title: 'Private Pflegeversicherung',
            serviceplatformId: 'care_insurance',
            isBrokerPossible: true,
            finapiId: 'CAREINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30493',
            title: 'Brillenversicherung',
            serviceplatformId: 'eyeglasses_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30495',
            title: 'Golfausrüstungsversicherung',
            serviceplatformId: 'golf_equipment_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30496',
            title: 'Laptopversicherung',
            serviceplatformId: 'laptop_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30497',
            title: 'Musikinstrumentenversicherung',
            serviceplatformId: 'insurance_of_musical_instruments',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30499',
            title: 'Smartphone Versicherung',
            serviceplatformId: 'smartphone_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30500',
            title: 'Sportwaffenversicherung',
            serviceplatformId: 'sporting_gun_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30501',
            title: 'Tabletversicherung',
            serviceplatformId: 'tablet_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30504',
            title: 'Wintersportversicherung',
            serviceplatformId: 'winter_sports_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30505',
            title: 'Zahnzusatzversicherung',
            serviceplatformId: 'denture',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26665'],
            status: '714',
        },
        {
            id: '30506',
            title: 'Sonstige Versicherungen',
            serviceplatformId: 'other_insurances',
            isBrokerPossible: false,
            finapiId: 'INSURANCE',
            categories: ['26668'],
            status: '714',
        },
        {
            id: '30507',
            title: 'Sterbegeldversicherung',
            serviceplatformId: 'death_benefit_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26669'],
            status: '714',
        },
        {
            id: '30508',
            title: 'Studentenversicherung',
            serviceplatformId: 'students_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667', '26668', '26669'],
            status: '714',
        },
        {
            id: '30509',
            title: 'Verkehrsrechtsschutzversicherung',
            serviceplatformId: 'traffic_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30510',
            title: 'Krankenhaustagegeldversicherung',
            serviceplatformId: 'hospital_daily_benefits',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30511',
            title: 'Angelausrüstungsversicherung',
            serviceplatformId: 'fishing_equipment_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30512',
            title: 'Drohnenversicherung',
            serviceplatformId: 'drone_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30513',
            title: 'E-Bikeversicherung',
            serviceplatformId: 'e_bike_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30514',
            title: 'Grundfähigkeitsversicherung',
            serviceplatformId: 'basic_skill_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30515',
            title: 'Hausratversicherung',
            serviceplatformId: 'home_content_insurance',
            isBrokerPossible: true,
            finapiId: 'HOMECONTENTINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30516',
            title: 'Fahrradversicherung',
            serviceplatformId: 'bicycle_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30517',
            title: 'Jagdwaffenversicherung',
            serviceplatformId: 'hunt_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30518',
            title: 'Fondsgebundene Lebensversicherung',
            serviceplatformId: 'unit_linked_life_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30519',
            title: 'Fondsgebundene Rentenversicherung',
            serviceplatformId: 'investment_pension_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26662'],
            status: '714',
        },
        {
            id: '30520',
            title: 'Au-Pairversicherung',
            serviceplatformId: 'aupair_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30521',
            title: 'Automobilclub',
            serviceplatformId: 'automobile_club',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30523',
            title: 'Grundbesitzerhaftpflichtversicherung',
            serviceplatformId: 'base_holder',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30526',
            title: 'Kreditversicherung',
            serviceplatformId: 'credit_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30534',
            title: 'Garantieversicherung',
            serviceplatformId: 'guarantee_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30537',
            title: 'Girokonto',
            serviceplatformId: 'current_account',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26668'],
            status: '714',
        },
        {
            id: '30538',
            title: 'Heilpraktikerversicherung',
            serviceplatformId: 'healer_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30539',
            title: 'Kinder-Invaliditätsversicherung',
            serviceplatformId: 'childrens_disability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30541',
            title: 'Kreuzfahrtversicherung',
            serviceplatformId: 'cruise_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30553',
            title: 'Wassersportversicherung',
            serviceplatformId: 'water_sports',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30556',
            title: 'Betriebsschliessungsversicherung',
            serviceplatformId: 'closure_of_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30557',
            title: 'Risikolebensversicherung',
            serviceplatformId: 'term_life_insurance',
            isBrokerPossible: true,
            finapiId: 'LIFEINSURANCE',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30560',
            title: 'Vermieterhaftpflichtversicherung',
            serviceplatformId: 'landlord_liability_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30561',
            title: 'Laubenversicherung',
            serviceplatformId: '',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30563',
            title: 'Betriebsunterbrechung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30565',
            title: 'Cyberversicherung',
            serviceplatformId: 'cyber_​​insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30573',
            title: 'Kreditausfallversicherung',
            serviceplatformId: ' credit_default_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30577',
            title: 'Maschinenversicherung',
            serviceplatformId: 'rent_loss_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '30590',
            title: 'Tier-Seuchenversicherung',
            serviceplatformId: 'animal_disease_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '715',
        },
        {
            id: '32473',
            title: '',
            serviceplatformId: 'bundled_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '32813',
            title: 'Transportversicherung',
            serviceplatformId: 'transport_insurance',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '36569',
            title: '',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '52367',
            title: 'Sturmversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52368',
            title: 'Hagelversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52369',
            title: 'Tierversicherung',
            serviceplatformId: '',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52370',
            title: 'Einheitsversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52371',
            title: 'Technische Versicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52372',
            title: 'Einbruchdiebstahl und Raub- Versicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52373',
            title: 'Versicherung zusätzlicher Gefahren zur Feuer- bzw. Feuer-Betriebsunterbrechungs- Versicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52375',
            title: 'Telekommunikation',
            serviceplatformId: 'electronics',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '714',
        },
        {
            id: '52377',
            title: 'Beistandsleistungsversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '52378',
            title: 'Luft- und Raumfahrzeug- Haftpflichtversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '52379',
            title: 'landwirtschaftliche Feuerversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664'],
            status: '714',
        },
        {
            id: '30593',
            title: 'Langzeitreiseversicherung - Schüler & Studenten',
            serviceplatformId: 'long_term_travel_insurance_student',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30594',
            title: 'Langzeitreiseversicherung - Au-Pair',
            serviceplatformId: 'long_term_travel_insurance_au_pair',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30595',
            title: 'Langzeitreiseversicherung - Work and Travel',
            serviceplatformId: 'long_term_travel_insurance_work_and_travel',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30596',
            title: 'Langzeitreiseversicherung - Langzeitreisende (3 Monate bis 5 Jahre)',
            serviceplatformId: 'long_term_travel_insurance_long_term_travelers',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30597',
            title: 'Versicherung für ausländische Gäste in Deutschland',
            serviceplatformId: 'insurance_for_foreign_guests_in_germany',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30598',
            title: 'Smartwatch-/ Sportwatchversicherungen',
            serviceplatformId: 'insurance_smartwatch_sportwatch',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30599',
            title: 'Moped- und Rollerversicherung',
            serviceplatformId: 'moped_and_scooter_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30600',
            title: 'Fahrradversicherung (Gewerbe)',
            serviceplatformId: 'bicycle_insurance_commercial',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '716',
        },
        {
            id: '30601',
            title: 'E-Bike- und Pedelec-Versicherung (Gewerbe)',
            serviceplatformId: 'e_bike_insurance_commercial',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26664'],
            status: '716',
        },
        {
            id: '30602',
            title: 'Führerschein-Versicherung',
            serviceplatformId: 'driving_license_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30603',
            title: 'Hörgeräteversicherung',
            serviceplatformId: 'hearing_aid_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30604',
            title: 'Kaninchen-Krankenversicherung',
            serviceplatformId: 'rabbit_health_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30605',
            title: 'Kaninchen-OP-Versicherung',
            serviceplatformId: 'rabbit_surgery_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30606',
            title: 'Jagdhaftpflichtversicherung',
            serviceplatformId: 'hunting_liability_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30607',
            title: 'Hochzeitsversicherung',
            serviceplatformId: 'wedding_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '30608',
            title: 'E-Scooter- und E-Roller-Versicherung',
            serviceplatformId: 'e_scooter_insurance',
            isBrokerPossible: false,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '32454',
            title: 'Gebündelte Sachversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664', '26667'],
            status: '716',
        },
        {
            id: '52374',
            title: 'Leitungswasserversicherung',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26667'],
            status: '716',
        },
        {
            id: '52654',
            title: '',
            serviceplatformId: '',
            isBrokerPossible: true,
            finapiId: '',
            categories: ['26664', '26667'],
            status: '716',
        },
        {
            id: '52687',
            title: 'Krankenversicherung',
            serviceplatformId: 'private_health_insurance',
            isBrokerPossible: true,
            finapiId: 'HEALTHINSURANCE',
            categories: ['26665'],
            status: '714',
        },
    ],
    providers: [
        {
            id: '26689',
            title: 'Aachen Münchener Versicherung AG',
            logo: null,
            serviceplatformId: 'aachen_m_nchener_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26693',
            title: 'ACE European Group Limited',
            logo: null,
            serviceplatformId: 'ace_european_group_limited',
            finapiId: '873e260c-a1db-43b1-92eb-7227d098911a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26694',
            title: 'ADAC Versicherung AG',
            logo: null,
            serviceplatformId: 'adac_rechtsschutz_versicherungs_ag',
            finapiId: 'f9085603-2199-4249-abba-54d9967a2849',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26696',
            title: 'ADCURI GmbH',
            logo: null,
            serviceplatformId: 'adcuri_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26697',
            title: 'ADLER Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'adler_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26698',
            title: 'ADLER Versicherung AG',
            logo: null,
            serviceplatformId: 'adler_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26699',
            title: 'Admiral Direkt.de GmbH',
            logo: null,
            serviceplatformId: 'admiral_direkt',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26700',
            title: 'ADVIGON VERSICHERUNG AG',
            logo: null,
            serviceplatformId: 'advigon_versicherung_ag',
            finapiId: '655653ef-6bf4-420c-b0dc-c7b20d63c255',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26701',
            title: 'ADVOCARD Rechtsschutzversicherung AG',
            logo: null,
            serviceplatformId: 'advocard_rechtsschutzversicherung_ag',
            finapiId: 'c404fffa-aedc-4bb5-9c29-17fde2587050',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26702',
            title: 'AGILA Haustierversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'agila_haustierversicherung_ag',
            finapiId: 'f29abac6-1dd1-4175-b6a5-e4fbd14be545',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26703',
            title: 'AIG Europe Limited',
            logo: null,
            serviceplatformId: 'aig_europe_limited',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26708',
            title: 'Allianz Private Krankenversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'allianz_private_krankenversicherungs_ag',
            finapiId: '301af9e9-6473-4425-8ec6-3d295b4ba27b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26709',
            title: 'Allianz Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'allianz_versicherungs_ag',
            finapiId: '99176c5a-7f6a-4759-97a6-d8d7e003229c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26710',
            title: 'Allianz24, Vereinte Spezial Versicherung AG',
            logo: null,
            serviceplatformId: 'allianz24_vereinte_spezial_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26711',
            title: 'Allrecht Rechtsschutzversicherung AG',
            logo: null,
            serviceplatformId: 'allrecht_rechtsschutzversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26712',
            title: 'Allsecur Deutschland AG',
            logo: null,
            serviceplatformId: 'allsecur_deutschland_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26713',
            title: 'ALTE LEIPZIGER Bauspar AG',
            logo: null,
            serviceplatformId: 'alte_leipziger_bauspar_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26663'],
        },
        {
            id: '26714',
            title: 'ALTE LEIPZIGER Lebensversicherung auf Gegenseitigkeit',
            logo: null,
            serviceplatformId: 'alte_leipziger_lebensversicherung_a_g_',
            finapiId: '3e0d5b69-4439-4349-b4cb-e05e47fac9e5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26715',
            title: 'ALTE LEIPZIGER Pensionskasse AG',
            logo: null,
            serviceplatformId: 'alte_leipziger_pensionskasse_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26716',
            title: 'Alte Leipziger Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'alte_leipziger_versicherung_ag',
            finapiId: '46de6603-28aa-4cdf-aa92-0ec023cc9091',
            isBrokerPossible: true,
            status: '687',
            categories: ['0', '26667'],
        },
        {
            id: '26718',
            title: 'ALTE OLDENBURGER Krankenversicherung von 1927 Versicherungsverein auf Gegenseitigkeit',
            logo: null,
            serviceplatformId: 'alte_oldenburger_versicherungsverein_a_g_',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: [],
        },
        {
            id: '26724',
            title: 'AOK Rheinland Pfalz Saarland',
            logo: null,
            serviceplatformId: 'aok_rheinland_pfalz_saarland',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26726',
            title: 'ARAG SE',
            logo: null,
            serviceplatformId: 'arag_allgemeine_rechtsschutz_versicherungs_ag',
            finapiId: '2f0a1196-3fef-4c02-9423-833b46ce36a5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26727',
            title: 'ARAG Allgemeine Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'arag_allgemeine_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26728',
            title: 'ARAG Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'arag_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26731',
            title: 'Aspecta Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'aspecta_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26732',
            title: 'Aspecta Versicherung AG',
            logo: null,
            serviceplatformId: 'aspecta_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26734',
            title: 'ASSTEL Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'asstel_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26736',
            title: 'ASTRA S.A. Versicherungen',
            logo: null,
            serviceplatformId: 'astra_s_a_versicherungen',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26737',
            title: 'Atradius Kreditversicherung - Niederlassung der Atradius Credit Insurance N.V.',
            logo: null,
            serviceplatformId: 'atradius_kreditversicherung_niederlassung_der_atradius_credit_insurance_n_v_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26744',
            title: 'AutoEuropa Bank',
            logo: null,
            serviceplatformId: 'autoeuropa_bank',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['14363'],
        },
        {
            id: '26745',
            title: 'AXA ART Versicherung AG',
            logo: null,
            serviceplatformId: 'axa_art_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26747',
            title: 'AXA Krankenversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'axa_krankenversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26748',
            title: 'AXA Lebensversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'axa_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26749',
            title: 'AXA Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'axa_versicherung_ag',
            finapiId: '97d95d77-a66f-4769-bda8-c50b33de8fcf',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26752',
            title: 'Badische Rechtsschutzversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'badische_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26753',
            title: 'Badischer Gemeinde-Versicherungs-Verband',
            logo: null,
            serviceplatformId: 'badischer_gemeinde_versicherungs_verband',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26762',
            title: 'Barmenia Allgemeine Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'barmenia_allgemeine_versicherungs_ag',
            finapiId: '4b0fbe64-2c78-4129-958f-115a70d5270c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26763',
            title: 'Barmenia Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'barmenia_krankenversicherung_a_g_',
            finapiId: '000e34e3-3637-4fa7-b8a8-7c1bfdecbd1a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26764',
            title: 'Barmenia Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'barmenia_lebensversicherung_a_g_',
            finapiId: '7912c99c-eab8-4367-b5b9-7a0325503daa',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26765',
            title: 'Barmer GEK',
            logo: null,
            serviceplatformId: 'barmer_gek',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26766',
            title: 'Basler Versicherungs-AG',
            logo: null,
            serviceplatformId: 'basler_lebensversicherungs_gesellschaft',
            finapiId: 'e1d20e8c-6c81-42db-b625-c569bb8d82f2',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26767',
            title: 'Basler Securitas Versicherungs-AG',
            logo: null,
            serviceplatformId: 'basler_securitas_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26769',
            title: 'Bavaria direkt OVAG',
            logo: null,
            serviceplatformId: 'bavaria_direkt_ovag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26771',
            title: 'Bayerische Beamten Versicherung AG',
            logo: null,
            serviceplatformId: 'bayerische_beamten_versicherung_ag',
            finapiId: 'c697c780-640e-4527-b765-7d0b7b282796',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26772',
            title: 'Bayerische Beamtenkrankenkasse Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'bayerische_beamtenkrankenkasse_ag',
            finapiId: 'ab77acfb-65e8-4414-9857-bb7d777cbc91',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26773',
            title: 'Bayerische Hausbesitzer-Versicherungs-Gesellschaft a.G.',
            logo: null,
            serviceplatformId: 'bayerische_hausbesitzer_versicherungs_gesellschaft_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26774',
            title: 'Bayerische Landesbrandversicherung AG',
            logo: null,
            serviceplatformId: 'bayerische_landesbrandversicherung_ag',
            finapiId: 'e927ec40-f6f1-45e3-a135-ebc11e3c8c23',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26776',
            title: 'BB Bank Feuer und Einbruchschadenkasse der BBBank VVaG',
            logo: null,
            serviceplatformId: 'bb_bank_feuer_und_einbruchschadenkasse_der_bbbank_vvag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26668', '26667'],
        },
        {
            id: '26780',
            title: 'Berlinische Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'berlinische_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26781',
            title: 'Berlin-Kölnische Sachversicherung AG',
            logo: null,
            serviceplatformId: 'berlin_k_lnische_sachversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26783',
            title: 'BHW Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'bhw_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26784',
            title: 'BIG Direkt Gesund',
            logo: null,
            serviceplatformId: 'big_direkt_gesund',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26785',
            title: 'BKK 24',
            logo: null,
            serviceplatformId: 'bkk_24',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26786',
            title: 'BKK EUREGIO',
            logo: null,
            serviceplatformId: 'bkk_euregio',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26787',
            title: 'BKK firmus',
            logo: null,
            serviceplatformId: 'bkk_firmus',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26788',
            title: 'BKK Pfalz',
            logo: null,
            serviceplatformId: 'bkk_pfalz',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26789',
            title: 'BKK VDN',
            logo: null,
            serviceplatformId: 'bkk_vdn',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26790',
            title: 'BKK VerbundPlus',
            logo: null,
            serviceplatformId: 'bkk_verbundplus',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26791',
            title: 'BKK VOR ORT',
            logo: null,
            serviceplatformId: 'bkk_vor_ort',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26794',
            title: 'VRK Versicherungsverein auf Gegenseitigkeit im Raum der Kirchen',
            logo: null,
            serviceplatformId: 'bruderhilfe_sachversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26797',
            title: 'Canada Life Assurance Europe plc Niederlassung für Deutschland',
            logo: null,
            serviceplatformId: 'canada_life_assurance_europe_ltd_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26798',
            title: 'Cardif Versicherung',
            logo: null,
            serviceplatformId: 'cardif_versicherung',
            finapiId: '949192fc-0e47-49f7-9eeb-004e55ec3145',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26799',
            title: 'Care Concept AG',
            logo: null,
            serviceplatformId: 'care_concept_versicherungsmakler_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26801',
            title: 'Central Asset Management AG',
            logo: null,
            serviceplatformId: 'central_asset_management_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: [],
        },
        {
            id: '26802',
            title: 'Generali Deutschland Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'central_krankenversicherung_ag',
            finapiId: '70e1852b-2904-4c53-8465-7fea2bb1cff5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26803',
            title: 'Chartis Europe SA Tour Chartis Paris la D',
            logo: null,
            serviceplatformId: 'chartis_europe_sa_tour_chartis_paris_la_d',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26805',
            title: 'CIC Versicherungs AG',
            logo: null,
            serviceplatformId: 'cic_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26806',
            title: 'CiV Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'civ_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26808',
            title: 'CNA Insurance Company Limited -Direktion Deutschland-',
            logo: null,
            serviceplatformId: 'cna_insurance_company_limited_direktion_deutschland_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['0', '26664'],
        },
        {
            id: '26809',
            title: 'Coface_ SA',
            logo: null,
            serviceplatformId: 'coface__sa',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26815',
            title: 'Concordia Krankenversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'concordia_krankenversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26816',
            title: 'Concordia Rechtsschutz-Versicherungs-AG',
            logo: null,
            serviceplatformId: 'concordia_rechtsschutz_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26817',
            title: 'Concordia Versicherungs-Gesellschaft auf Gegenseitigkeit',
            logo: null,
            serviceplatformId: 'concordia_versicherungs_gesellschaft_a_g_',
            finapiId: '4732afad-1375-463c-a30b-3268b80d470e',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26818',
            title: 'Condor Lebensversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'condor_allgemeine_versicherungs_ag',
            finapiId: '983757eb-bc95-4eed-8122-9ab43f87545e',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26822',
            title: 'Continentale Assekuranz',
            logo: null,
            serviceplatformId: 'continentale_assekuranz',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '26823',
            title: 'Continentale Krankenversicherung a.G.',
            logo: null,
            serviceplatformId: 'continentale_krankenversicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26824',
            title: 'Continentale Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'continentale_lebensversicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26825',
            title: 'Continentale Sachversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'continentale_sachversicherung_ag',
            finapiId: '8eb6dbfe-93b4-4807-a860-fad163c3d6c5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26826',
            title: 'COSMOS Lebensversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'cosmos_direkt',
            finapiId: 'ce8a4de1-951e-45eb-84fb-f571bd797572',
            isBrokerPossible: false,
            status: '687',
            categories: ['26664', '26665', '26667', '26669'],
        },
        {
            id: '26827',
            title: 'Cosmos Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'cosmos_versicherung_ag',
            finapiId: 'b267a2ff-c25a-4dae-bbed-efaeff2baeb3',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26829',
            title: 'Credit Life AG',
            logo: null,
            serviceplatformId: 'credit_life_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26834',
            title: 'CSS Versicherung AG',
            logo: null,
            serviceplatformId: 'css_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26835',
            title: 'D.A.S. Allgemeine Rechtsschutz-Versicherungs-AG',
            logo: null,
            serviceplatformId: 'd_a_s_allgemeine_rechtsschutz_versicherungs_ag',
            finapiId: 'D.A.S. Rechtsschutz AG',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26836',
            title: 'D.A.S. Deutscher Automobil Schutz Allgemeine Rechtsschutz-Versicherungs-AG',
            logo: null,
            serviceplatformId: 'd_a_s_deutscher_automobil_schutz_allgemeine_rechtsschutz_versicherungs_ag',
            finapiId: '31aa3d1f-c3ec-495a-9397-b6e7535f8158',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26839',
            title: 'DAK Gesundheit',
            logo: null,
            serviceplatformId: 'dak_gesundheit',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26840',
            title: 'DARAG Deutschland AG',
            logo: null,
            serviceplatformId: 'darag_deutsche_versicherungs_und_r_ckversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26841',
            title: 'DBV Deutsche Beamtenversicherung AG',
            logo: null,
            serviceplatformId: 'dbv_deutsche_beamtenversicherung_ag',
            finapiId: '2a0a6df3-d13c-426a-a40f-10f52e39fd02',
            isBrokerPossible: true,
            status: '689',
            categories: [],
        },
        {
            id: '26847',
            title: 'Debeka Allgemeine Versicherung Aktiengesellschaft Sitz Koblenz am Rhein',
            logo: null,
            serviceplatformId: 'debeka_allgemeine_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26849',
            title: 'Debeka BKK',
            logo: null,
            serviceplatformId: 'debeka_bkk',
            finapiId: 'e63d172e-7f55-482c-b413-63bc17cb25c6',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26850',
            title: 'Debeka Krankenversicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'debeka_krankenversicherungsverein_a_g_',
            finapiId: 'f14fdcd4-69de-4a47-9485-bdb7957e66da',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26851',
            title: 'Debeka Lebensversicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'debeka_lebensversicherungsverein_a_g_',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26852',
            title: 'Debeka Pensionskasse AG',
            logo: null,
            serviceplatformId: 'debeka_pensionskasse_ag',
            finapiId: '4f3cb5f7-b0d2-4bc1-bdf7-46c2b37cfcc7',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26853',
            title: 'Degenia',
            logo: null,
            serviceplatformId: 'degenia',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26856',
            title: 'Delta Direkt Lebensversicherung Aktiengesellschaft München',
            logo: null,
            serviceplatformId: 'delta_direkt_lebensversicherung_ag_m_nchen',
            finapiId: '3f276e9b-19dc-483a-807a-241c752f5fa6',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26857',
            title: 'Delta Lloyd Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'delta_lloyd_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26860',
            title: 'DEURAG Deutsche Rechtsschutz-Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'deurag_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26861',
            title: 'Deutsche Ärzteversicherung AG',
            logo: null,
            serviceplatformId: 'deutsche_rzteversicherung_ag',
            finapiId: '53c8d0b6-6e4a-4f69-81ec-aaedde655fd6',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667', '26668'],
        },
        {
            id: '26863',
            title: 'deutsche internet versicherung AG',
            logo: null,
            serviceplatformId: 'deutsche_internet_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665', '26662'],
        },
        {
            id: '26864',
            title: 'Deutsche Lebensversicherungs AG',
            logo: null,
            serviceplatformId: 'deutsche_lebensversicherungs_ag',
            finapiId: 'a2d1a2cd-f224-4f6f-bfba-7e8f985d5038',
            isBrokerPossible: false,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '26865',
            title: 'Deutscher Herold Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'deutscher_herold_allgemeine_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26869',
            title: 'Deutscher Ring Sachversicherungs-AG',
            logo: null,
            serviceplatformId: 'deutscher_ring_sachversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26874',
            title: 'DEVK Rechtsschutz-Versicherung AG',
            logo: null,
            serviceplatformId: 'devk_rechtsschutz_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26876',
            title: 'DFV - Deutsche Familienversicherung AG',
            logo: null,
            serviceplatformId: 'dfv_deutsche_familienversicherung_ag',
            finapiId: 'e9f26715-3692-4887-ab38-a4aefb21fa52',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667'],
        },
        {
            id: '26877',
            title: 'Dialog Lebensversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'dialog_lebensversicherungs_ag',
            finapiId: '810c3dfe-45be-49eb-a908-83715cc84b81',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26878',
            title: 'Die Bergische Krankenversicherung',
            logo: null,
            serviceplatformId: 'die_bergische_krankenversicherung',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26879',
            title: 'Die Continentale BKK',
            logo: null,
            serviceplatformId: 'die_continentale_bkk',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26884',
            title: 'DIREKTE LEBEN Versicherung AG',
            logo: null,
            serviceplatformId: 'direkte_leben_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26886',
            title: 'DLVAG Allianz Deutsche Lebensversicherung',
            logo: null,
            serviceplatformId: 'dlvag_allianz_deutsche_lebensversicherung',
            finapiId: 'e2073a34-f759-4a2b-a60c-1a25ed0b661a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '26887',
            title: 'DMB Rechtsschutz-Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'dmb_rechtsschutz_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26888',
            title: 'DOCURA VVaG',
            logo: null,
            serviceplatformId: 'docura_brandkasse_deutscher_lehrer_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26889',
            title: 'Dolleruper Freie Brandgilde VVaG',
            logo: null,
            serviceplatformId: 'dolleruper_freie_brandgilde_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26890',
            title: 'Domcura AG',
            logo: null,
            serviceplatformId: 'domcura_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26891',
            title: 'Donau Versicherung',
            logo: null,
            serviceplatformId: 'donau_versicherung',
            finapiId: 'a1605b88-71bd-4bd5-93d5-8f2cab12496f',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26895',
            title: 'Düsseldorfer Versicherung Krankenversicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'd_sseldorfer_versicherung_krankenversicherungsverein_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26899',
            title: 'Envivas Krankenversicherung',
            logo: null,
            serviceplatformId: 'envivas_krankenversicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26900',
            title: 'ERGO Austria',
            logo: null,
            serviceplatformId: 'ergo_austria',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26669', '26667', '26665', '26664', '26662'],
        },
        {
            id: '26901',
            title: 'ERGO Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'ergo_direkt_krankenversicherung_ag',
            finapiId: '3fcb858c-8488-45f4-ad97-9efa690b7dc9',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26902',
            title: 'ERGO Lebensversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ergo_direkt_lebensversicherung_ag',
            finapiId: 'f1ee59a9-d173-45f5-af35-3115603cfc3e',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26903',
            title: 'ERGO Direkt Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ergo_direkt_versicherung_ag',
            finapiId: '709ec3b4-416f-46e9-8cee-46e6c5a0044c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26904',
            title: 'ERGO Vorsorge Lebensversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ergo_lebensversicherung_ag',
            finapiId: 'afbbc27d-9f75-49fb-bbf2-d7bbb574a847',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26905',
            title: 'ERGO Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ergo_versicherung_ag',
            finapiId: '37076084-8efa-438e-ac33-814eed0a2edf',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26907',
            title: 'Euler Hermes Kreditversicherungs-AG',
            logo: null,
            serviceplatformId: 'euler_hermes_kreditversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26908',
            title: 'Europ Assistance Versicherungs-AG',
            logo: null,
            serviceplatformId: 'europ_assistance_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26909',
            title: 'EUROPA Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'europa_krankenversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26910',
            title: 'EUROPA Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'europa_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26911',
            title: 'EUROPA Sachversicherung AG',
            logo: null,
            serviceplatformId: 'europa_sachversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26912',
            title: 'Europäische Reiseversicherung AG',
            logo: null,
            serviceplatformId: 'europ_ische_reiseversicherung_ag',
            finapiId: '2260ba52-c515-4ce3-adcc-f2656b38d868',
            isBrokerPossible: true,
            status: '687',
            categories: ['14362'],
        },
        {
            id: '26915',
            title: 'Fahrlehrerversicherung VVaG',
            logo: null,
            serviceplatformId: 'fahrlehrerversicherung_vvag',
            finapiId: 'e782e78a-2f2b-4c59-8561-ade39670d4c4',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26916',
            title: 'Familienfürsorge Lebensversicherung AG im Raum der Kirchen',
            logo: null,
            serviceplatformId: 'familienf_rsorge_lebensversicherung_ag_im_raum_der_kirchen',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26917',
            title: 'Familienschutz Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'familienschutz_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26920',
            title: 'FinanceLife Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'financelife_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26925',
            title: 'Fortis Deutschland Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'fortis_deutschland_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26928',
            title: 'Friends Provident Life Assurance Limited',
            logo: null,
            serviceplatformId: 'friends_provident_life_assurance_limited',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '26930',
            title: 'GARANTA Versicherungs-AG',
            logo: null,
            serviceplatformId: 'garanta_versicherungs_ag',
            finapiId: 'a91082a4-308f-4464-9bd0-387c93f8e036',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26931',
            title: 'Gemeinnützige Haftpflicht-Vers.-Anstalt Darmstadt',
            logo: null,
            serviceplatformId: 'gemeinn_tzige_haftpflicht_vers_anstalt_darmstadt',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26932',
            title: 'Generali Deutschland Pensionskasse AG',
            logo: null,
            serviceplatformId: 'generali_deutschland_pensionskasse_ag',
            finapiId: '3ba6b1a1-ff93-47a3-b5d2-f9251f482d1e',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26933',
            title: 'Generali Versicherung AG',
            logo: null,
            serviceplatformId: 'generali_versicherung_ag',
            finapiId: '3ec6330e-11f9-4ffb-a0c2-158b10912caa',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26934',
            title: 'GENO BANK ESSEN',
            logo: null,
            serviceplatformId: 'geno_bank_essen',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '26936',
            title: 'Gerling Konzern',
            logo: null,
            serviceplatformId: 'gerling_konzern',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665', '26662'],
        },
        {
            id: '26940',
            title: 'Gothaer Allgemeine Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'gothaer_allgemeine_versicherung_ag',
            finapiId: 'ca9c1c15-81f9-4d42-ae65-aa06d5585bff',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26942',
            title: 'Gothaer Krankenversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'gothaer_krankenversicherung_ag',
            finapiId: 'c035af72-2905-4ca6-a935-4aed421a8aee',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26943',
            title: 'Gothaer Lebensversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'gothaer_lebensversicherung_ag',
            finapiId: 'de61ae1e-f826-42b7-b9cb-a96387efeb4f',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26945',
            title: 'Grazer Wechselseitige Versicherung',
            logo: null,
            serviceplatformId: 'grazer_wechselseitige_versicherung',
            finapiId: 'cd98025a-862e-41af-b102-da945781079a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26946',
            title: 'GRUNDEIGENTÜMER-VERSICHERUNG Versicherungsverein auf Gegenseitigkeit',
            logo: null,
            serviceplatformId: 'grundeigent_mer_versicherung_versicherungsverein_auf_gegenseitigkeit_vvag_',
            finapiId: '64649c69-a862-4b85-901c-ce860e208302',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26947',
            title: 'GVO Gegenseitigkeit Versicherung Oldenburg VVaG',
            logo: null,
            serviceplatformId: 'gvo_gegenseitigkeit_versicherung_oldenburg_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26948',
            title: 'GVV-Kommunalversicherung V.V.a.G.',
            logo: null,
            serviceplatformId: 'gvv_kommunalversicherung_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26950',
            title: 'Haftpflichtkasse Darmstadt- Haftpflichtversicherung des Deutschen Hotel- und Gaststättengewerbes - V.V.a.G.',
            logo: null,
            serviceplatformId: 'haftpflichtkasse_darmstadt_haftpflichtversicherung_des_deutschen_hotel_und_gastst_ttengewerbes_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664'],
        },
        {
            id: '26951',
            title: 'Häger Versicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'h_ger_versicherungsverein_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26953',
            title: 'HALLESCHE Krankenversicherung auf Gegenseitigkeit',
            logo: null,
            serviceplatformId: 'hallesche_krankenversicherung_a_g_',
            finapiId: '3dafc0fd-a0e2-4005-9337-2a4615e072d7',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26669'],
        },
        {
            id: '26954',
            title: 'Hamburger Feuerkasse Versicherung AG',
            logo: null,
            serviceplatformId: 'hamburger_feuerkasse_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26955',
            title: 'Hamburger Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'hamburger_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26956',
            title: 'Hamburg-Mannheimer Rechtsschutzversicherungs-AG',
            logo: null,
            serviceplatformId: 'hamburg_mannheimer_rechtsschutzversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26957',
            title: 'Hamburg-Mannheimer Versicherungs-AG',
            logo: null,
            serviceplatformId: 'Hamburg_mannheimer_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '26958',
            title: 'HANNOVER LEASING GmbH & Co. KG',
            logo: null,
            serviceplatformId: 'hannover_leasing_gmbh_co_kg',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14363'],
        },
        {
            id: '26959',
            title: 'Hannoversche Direktversicherung AG',
            logo: null,
            serviceplatformId: 'hannoversche_direktversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26960',
            title: 'Hannoversche Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'hannoversche_lebensversicherung_ag',
            finapiId: 'c2f61cc4-48b5-478b-a7bc-aebee743990b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26963',
            title: 'HanseMerkur Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'hansemerkur_allgemeine_versicherung_ag',
            finapiId: '912167d8-4119-419c-a789-7da862725272',
            isBrokerPossible: false,
            status: '688',
            categories: [],
        },
        {
            id: '26964',
            title: 'HanseMerkur Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'hansemerkur_krankenversicherung_ag',
            finapiId: '96a691d1-61cf-4230-8f91-e08fdba21e75',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '26967',
            title: 'Harsewinkeler Versicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'harsewinkeler_versicherungsverein_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['0', '26667'],
        },
        {
            id: '26969',
            title: 'HDI Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'hdi_lebensversicherung_ag',
            finapiId: 'c645d176-3b79-4504-8b2a-bf051bd489c0',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26970',
            title: 'HDI Versicherung AG',
            logo: null,
            serviceplatformId: 'hdi_versicherung_ag',
            finapiId: '0fac4b8b-b09d-4d83-a0ed-6a9fabea1fb8',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26973',
            title: 'HEB-Hausratversicherung',
            logo: null,
            serviceplatformId: 'heb_hausratversicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26974',
            title: 'Heidelberger Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'heidelberger_lebensversicherung_ag',
            finapiId: 'ad69f297-8411-4be2-98bc-26b1ee3596ec',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26976',
            title: 'HEK-Hanseatische Krankenkasse',
            logo: null,
            serviceplatformId: 'hek_hanseatische_krankenkasse',
            finapiId: '02c7b43c-6942-4c8a-8a00-77a751d0b8ad',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26977',
            title: 'Helvetia Schweizerische Versicherungsgesellschaft AG Direktion für Deutschland',
            logo: null,
            serviceplatformId: 'helvetia_schweizerische_lebensversicherung_ag',
            finapiId: 'fa79b9a3-9e63-4738-bb87-a5f1e737af43',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26978',
            title: 'Helvetia Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'helvetia_schweizerische_versicherungsgesellschaft_ag',
            finapiId: '82a5e331-f15f-4561-aa64-021c3382b533',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26980',
            title: 'HKK erste Gesundheit',
            logo: null,
            serviceplatformId: 'hkk_erste_gesundheit',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26983',
            title: 'HUK24 AG',
            logo: null,
            serviceplatformId: 'huk24_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26984',
            title: 'HUK-COBURG-Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'huk_coburg_allgemeine_versicherung_ag',
            finapiId: '882ffac4-0235-409f-91e0-d79aa6564e1b',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '26985',
            title: 'HUK-COBURG-Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'huk_coburg_krankenversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26986',
            title: 'HUK-COBURG-Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'huk_coburg_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '26987',
            title: 'HVS Versicherungsservice (Marsh GmbH)',
            logo: null,
            serviceplatformId: 'hvs_versicherungsservice_marsh_gmbh_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '26990',
            title: 'Ideal Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'ideal_lebensversicherung_a_g_',
            finapiId: '938bd3f8-9352-483a-9929-87d65e28d832',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26991',
            title: 'IDEAL Versicherung AG',
            logo: null,
            serviceplatformId: 'ideal_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26993',
            title: 'IKK Classic',
            logo: null,
            serviceplatformId: 'ikk_classic',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26994',
            title: 'IKK Gesund Plus',
            logo: null,
            serviceplatformId: 'ikk_gesund_plus',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '26997',
            title: 'Inora Life Ltd. -Direktion Deutschland-',
            logo: null,
            serviceplatformId: 'inora_life_ltd_direktion_deutschland_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '26998',
            title: 'INTER Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'inter_allgemeine_versicherung_ag',
            finapiId: '87179086-9f85-4d9a-9e00-6200f07821c6',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '26999',
            title: 'INTER Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'inter_krankenversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27002',
            title: 'Interlloyd Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'interlloyd_versicherungs_ag',
            finapiId: '8dbed535-448d-404a-bba2-d80bffdfb4ce',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27003',
            title: 'InterRisk Lebensversicherungs-AG Vienna Insurance Group',
            logo: null,
            serviceplatformId: 'interrisk_lebensversicherungs_ag',
            finapiId: '7b3da52e-d442-45be-9e35-93e92e2beac6',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27004',
            title: 'InterRisk Versicherungs-AG Vienna Insurance Group',
            logo: null,
            serviceplatformId: 'interrisk_versicherung_ag',
            finapiId: '1ee9d4a7-1549-4ad2-b364-850071156276',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664'],
        },
        {
            id: '27007',
            title: 'ISSELHORSTER Versicherung V.a.G.',
            logo: null,
            serviceplatformId: 'isselhorster_versicherung_v_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['14364'],
        },
        {
            id: '27008',
            title: 'Itzehoer Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'itzehoer_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27009',
            title: 'Itzehoer Versicherung- Brandgilde von 1691 V.V.a.G.',
            logo: null,
            serviceplatformId: 'itzehoer_versicherung',
            finapiId: 'b8ca968e-ef90-4d17-8eb2-bc2d6c4f8057',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27010',
            title: 'Jurpartner Rechtsschutz-Versicherung AG',
            logo: null,
            serviceplatformId: 'jurpartner_rechtsschutz_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27013',
            title: 'Karlsruher Beamten-Versicherung AG',
            logo: null,
            serviceplatformId: 'karlsruher_beamten_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27016',
            title: 'Kaufmännische Krankenkasse – KKH',
            logo: null,
            serviceplatformId: 'kaufm_nnische_krankenkasse_kkh',
            finapiId: '675377d9-dfbf-4f81-b287-5312e692b1c5',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27018',
            title: 'Kirchliche Zusatzversorgungskasse (KZVK)',
            logo: null,
            serviceplatformId: 'kirchliche_zusatzversorgungskasse_kzvk_',
            finapiId: 'b705a836-6389-46e7-9a20-d3d804a5e1ef',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27019',
            title: 'KlickEnergie GmbH & Co. KG',
            logo: null,
            serviceplatformId: 'klick_energie',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['56833'],
        },
        {
            id: '27021',
            title: 'KRAVAG-ALLGEMEINE Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'kravag_allgemeine_versicherungs_ag',
            finapiId: '17e401ee-d52f-49bd-b6fc-d0e02e255ac8',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27035',
            title: 'Landeskrankenhilfe V.V.a.G.',
            logo: null,
            serviceplatformId: 'landeskrankenhilfe_v_v_a_g_',
            finapiId: '8c89cc35-820e-498b-bd9c-9cb27b0ef980',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27038',
            title: 'Landeslebenshilfe V.V.a.G.',
            logo: null,
            serviceplatformId: 'landeslebenshilfe_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27046',
            title: 'Lebensversicherung von 1871 auf Gegenseitigkeit München',
            logo: null,
            serviceplatformId: 'lebensversicherung_von_1871_versicherungsverein_a_g_',
            finapiId: 'fc9576dc-48d6-4c8a-867e-a92d1b3a4a1a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27048',
            title: 'Liberty Mutual Insurance Europe Limited',
            logo: null,
            serviceplatformId: 'liberty_mutual_insurance_europe_limited',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27049',
            title: 'Lippische Landes-Brandversicherungsanstalt',
            logo: null,
            serviceplatformId: 'lippische_landes_brandversicherungsanstalt',
            finapiId: '170a3d44-0c6d-4cb5-bdb7-dcc886ddeb8b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27050',
            title: 'LLH Landeslebenshilfe V.V.a.G.',
            logo: null,
            serviceplatformId: 'llh_landeslebenshilfe_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27051',
            title: 'Lloyd´s Versicherer London - Niederlassung f. Deutschland',
            logo: null,
            serviceplatformId: 'lloyd_s_versicherer_london_niederlassung_f_deutschland',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27052',
            title: 'LSH Versicherung VaG',
            logo: null,
            serviceplatformId: 'lsh_versicherung_vag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27053',
            title: 'LVM Krankenversicherungs-AG',
            logo: null,
            serviceplatformId: 'lvm_krankenversicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27054',
            title: 'LVM Landwirtschaftlicher Versicherungsverein Münster a.G.',
            logo: null,
            serviceplatformId: 'lvm_landwirtschaftlicher_versicherungsverein_m_nster_a_g_',
            finapiId: '80b710aa-3fc1-4177-acce-6998729cb3d6',
            isBrokerPossible: false,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27055',
            title: 'LVM Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'lvm_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27056',
            title: 'LVM Rechtsschutzversicherungs-AG',
            logo: null,
            serviceplatformId: 'lvm_rechtsschutzversicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27061',
            title: 'Mannheimer Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'mannheimer_versicherung_ag',
            finapiId: '830810b5-8e1c-4ccf-8094-1c522673cf83',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27062',
            title: 'Markel International',
            logo: null,
            serviceplatformId: 'markel_international',
            finapiId: 'deb51329-9917-4347-a01c-1ed8ca8c6d30',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664'],
        },
        {
            id: '27063',
            title: 'Mecklenburgische Krankenversicherungs-AG',
            logo: null,
            serviceplatformId: 'mecklenburgische_krankenversicherungs_ag',
            finapiId: '0f9c33f0-925e-4987-bdbd-25d8b46cd6e1',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27064',
            title: 'Mecklenburgische Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'mecklenburgische_lebensversicherungs_ag',
            finapiId: '18fa0f7a-e0f5-4b4d-a01f-4259b04fb544',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27065',
            title: 'Mecklenburgische Versicherungsgesellschaft a.G.',
            logo: null,
            serviceplatformId: 'mecklenburgische_versicherungsgesellschaft_a_g_',
            finapiId: '993cd474-c90d-4975-83d4-a4ac8d3ab591',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27066',
            title: 'Medien-Versicherung a.G.',
            logo: null,
            serviceplatformId: 'medien_versicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667', '26668'],
        },
        {
            id: '27067',
            title: 'Merkur Versicherung',
            logo: null,
            serviceplatformId: 'merkur_versicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667'],
        },
        {
            id: '27072',
            title: 'MoneyMaxx Lebensversicherung',
            logo: null,
            serviceplatformId: 'moneymaxx_lebensversicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27073',
            title: 'Monuta Versicherungen Zweigniederlassung Deutschland der Monuta Verzekeringen N.V.',
            logo: null,
            serviceplatformId: 'monuta_versicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27075',
            title: 'muki Versicherungsverein',
            logo: null,
            serviceplatformId: 'muki_versicherungsverein',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27078',
            title: 'Münchener und Magdeburger Agrarversicherung AG',
            logo: null,
            serviceplatformId: 'm_nchener_und_magdeburger_agrarversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27079',
            title: 'MÜNCHENER VEREIN Allgemeine Versicherungs-AG',
            logo: null,
            serviceplatformId: 'm_nchener_verein_allgemeine_versicherungs_ag',
            finapiId: '883be88f-3768-4af0-8808-899b6e8ac6fe',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27080',
            title: 'MÜNCHENER VEREIN Krankenversicherung a.G.',
            logo: null,
            serviceplatformId: 'm_nchener_verein_krankenversicherung',
            finapiId: '9dfc825f-da43-46f1-803d-6e3bd9910ca7',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27082',
            title: 'MÜNCHENER VEREIN Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'm_nchener_verein_lebensversicherung_a_g',
            finapiId: '3a82d7b8-0261-4bd8-9402-98ac9dca5697',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27085',
            title: 'myLife Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'my_life_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27086',
            title: 'Nassau Versicherungen - Niederlassung Deutschland',
            logo: null,
            serviceplatformId: 'nassau_versicherungen_niederlassung_deutschland',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27088',
            title: 'Neckermann Versicherung AG',
            logo: null,
            serviceplatformId: 'neckermann_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26669'],
        },
        {
            id: '27090',
            title: 'Neue Leben Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'neue_leben_lebensversicherung_ag',
            finapiId: 'ed62fc3e-9567-4d11-893e-fcfd39b7e74e',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27092',
            title: 'Neue Leben Versicherung AG',
            logo: null,
            serviceplatformId: 'neue_leben_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27093',
            title: 'Neue Rechtsschutz Versicherungsgesellschaft AG',
            logo: null,
            serviceplatformId: 'neue_rechtsschutz_versicherungsgesellschaft_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '0', '26665'],
        },
        {
            id: '27094',
            title: 'Neuendorfer Brand-Bau-Gilde VVaG',
            logo: null,
            serviceplatformId: 'neuendorfer_brand_bau_gilde_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27097',
            title: 'Nova Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'nova_allgemeine_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27099',
            title: 'Nürnberger Beamten Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'n_rnberger_beamten_allgemeine_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26662', '26667'],
        },
        {
            id: '27100',
            title: 'Nürnberger Beamten Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'n_rnberger_beamten_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665', '26662'],
        },
        {
            id: '27101',
            title: 'Nürnberger Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'n_rnberger_krankenversicherung_ag',
            finapiId: 'dfedc151-75d3-4759-9106-ea59be2e099c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27102',
            title: 'Nürnberger Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'n_rnberger_lebensversicherung_ag',
            finapiId: 'dacec16e-0117-4408-be12-7f9696c22282',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27103',
            title: 'NÜRNBERGER Allgemeine Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'n_rnberger_versicherung_ag',
            finapiId: '91560d62-5240-4a7d-b8b9-5c2c50628e61',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27104',
            title: 'NV-Versicherungen VVaG',
            logo: null,
            serviceplatformId: 'nv_versicherungen_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27105',
            title: 'Oberösterreichische Versicherung AG',
            logo: null,
            serviceplatformId: 'ober_sterreichische_versicherung_ag',
            finapiId: '5b9571ba-37a8-4fd3-85f3-d2ee1d2bdc05',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26664', '26667', '26668', '26669'],
        },
        {
            id: '27107',
            title: 'Öffentliche Versicherung Braunschweig',
            logo: null,
            serviceplatformId: '_ffentliche_versicherung_braunschweig',
            finapiId: '8667281c-6c59-4188-8e6f-04aed07a7042',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667'],
        },
        {
            id: '27108',
            title: 'Öffentliche Versicherungen Oldenburg',
            logo: null,
            serviceplatformId: '_ffentliche_versicherungen_oldenburg',
            finapiId: '5d923299-ddb9-4224-b5ae-05788413fa2c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27109',
            title: 'Ontos Versicherung AG',
            logo: null,
            serviceplatformId: 'ontos_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27110',
            title: 'Optima Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'optima_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27111',
            title: 'Optima Versicherungs-AG',
            logo: null,
            serviceplatformId: 'optima_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27112',
            title: 'ÖSA - Öffentliche Versicherungen Sachsen-Anhalt',
            logo: null,
            serviceplatformId: '_sa_ffentliche_versicherungen_sachsen_anhalt',
            finapiId: '1f4627be-3e2b-478f-8d40-1c151568b7ec',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27113',
            title: 'Ostangler Brandgilde',
            logo: null,
            serviceplatformId: 'ostangler_brandgilde',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27114',
            title: 'Ostfr. Landschaftliche Brandkasse',
            logo: null,
            serviceplatformId: 'ostfr_landschaftliche_brandkasse',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26662'],
        },
        {
            id: '27115',
            title: 'Ostfriesische Versicherungsbörse Assekuranzen GmbH',
            logo: null,
            serviceplatformId: 'ostfriesische_versicherungsb_rse_assekuranzen_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27116',
            title: 'OVAG - Ostdeutsche Versicherung AG',
            logo: null,
            serviceplatformId: 'ovag_ostdeutsche_versicherung_ag',
            finapiId: 'faf077fc-dd53-4a54-afe6-1c4387b4a8c5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27120',
            title: 'PAX Schweizerische Lebensversicherungs-Gesellschaft AG',
            logo: null,
            serviceplatformId: 'pax_schweizerische_lebensversicherungs_gesellschaft_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27121',
            title: 'PAX-Familienfürsorge Krankenversicherung AG im Raum der Kirchen',
            logo: null,
            serviceplatformId: 'pax_familienf_rsorge_krankenversicherung_ag_im_raum_der_kirchen',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27122',
            title: 'Plus Lebensversicherungs AG',
            logo: null,
            serviceplatformId: 'plus_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27125',
            title: 'Presseversorgungswerk',
            logo: null,
            serviceplatformId: 'presseversorgungswerk',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27126',
            title: 'Prisma Life Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'prisma_life_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27127',
            title: 'Pro bAV Pensionskasse AG',
            logo: null,
            serviceplatformId: 'pro_bav_pensionskasse_ag',
            finapiId: '6da62468-09f1-4df8-886b-d3ff9755c0e9',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27128',
            title: 'prokundo GmbH',
            logo: null,
            serviceplatformId: 'prokundo_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27129',
            title: 'Protektor Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'protektor_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27130',
            title: 'Provinzial Nord Brandkasse AG',
            logo: null,
            serviceplatformId: 'provinzial_nord_brandkasse_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27132',
            title: 'Provinzial NordWest Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'provinzial_nordwest_lebensversicherung_ag',
            finapiId: '0af83bdd-ae0b-4429-92e3-a6fe9ffb2174',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27133',
            title: 'Provinzial Rheinland Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'provinzial_rheinland_lebensversicherung_ag',
            finapiId: '95cc70fe-5deb-4694-b95b-9f56804dddf0',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27134',
            title: 'Provinzial Rheinland Versicherung AG',
            logo: null,
            serviceplatformId: 'provinzial_rheinland_versicherung_ag',
            finapiId: 'bf245a3f-c1b4-407c-8495-aaba42c78d43',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27138',
            title: 'R+V 24 Versicherung',
            logo: null,
            serviceplatformId: 'r_v_24_versicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27141',
            title: 'R+V Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'r_v_lebensversicherung_a_g_',
            finapiId: '22fa7824-60da-4357-86d0-3c044481ed11',
            isBrokerPossible: false,
            status: '687',
            categories: [],
        },
        {
            id: '27142',
            title: 'R+V Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'r_v_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27143',
            title: 'R+V Direktversicherung AG',
            logo: null,
            serviceplatformId: 'r_v_rechtsschutzversicherung_ag',
            finapiId: '40069716-5283-4a73-a87c-414de52e49b5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27144',
            title: 'R+V VERSICHERUNG AG',
            logo: null,
            serviceplatformId: 'r_v_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27172',
            title: 'Rechtsschutz Union Versicherungs-AG',
            logo: null,
            serviceplatformId: 'rechtsschutz_union_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27175',
            title: 'RheinLand Versicherungs Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'rheinland_versicherungs_ag',
            finapiId: '1ee7d688-5220-410b-9366-af93756ef9ec',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27176',
            title: 'Rhion Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'rhion_versicherung_ag',
            finapiId: '71c70548-4124-4f97-b104-6bac9cc20eea',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27177',
            title: 'ROLAND Rechtsschutz-Versicherungs-AG',
            logo: null,
            serviceplatformId: 'roland_rechtsschutz_versicherung_ag',
            finapiId: '180c5e88-c06b-41cb-8f7c-caf7d244660e',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27178',
            title: 'ROLAND Schutzbrief-Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'roland_schutzbrief_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27180',
            title: 'Saarland Feuerversicherung AG',
            logo: null,
            serviceplatformId: 'saarland_feuerversicherung_ag',
            finapiId: '73102635-3308-40e1-9cb3-dbbff45d1cb0',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27181',
            title: 'Saarland Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'saarland_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27187',
            title: 'Schwarzwälder Versicherung VVaG',
            logo: null,
            serviceplatformId: 'schwarzw_lder_versicherung_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27188',
            title: 'Schweizerische Lebensversicherungs- und Rentenanstalt -Niederlassung für Deutschland-',
            logo: null,
            serviceplatformId: 'schweizerische_lebensversicherungs_und_rentenanstalt_niederlassung_f_r_deutschland_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27190',
            title: 'Schwenninger Krankenkasse',
            logo: null,
            serviceplatformId: 'schwenninger_krankenkasse',
            finapiId: '80ce7ba6-74b6-437b-aefa-a99306b10246',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27191',
            title: 'Süddeutsche Allgemeine Versicherung a.G.',
            logo: null,
            serviceplatformId: 'sdk_s_ddeutsche_allgemeine_versicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27192',
            title: 'Süddeutsche Krankenversicherung a.G.',
            logo: null,
            serviceplatformId: 'sdk_s_ddeutsche_krankenversicherung_a_g_',
            finapiId: '12b2e835-b0b9-412d-9697-ffc25e1b926e',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27193',
            title: 'Süddeutsche Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'sdk_s_ddeutsche_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27195',
            title: 'Securitas Gilde Lebensversicherung AG Versicherung',
            logo: null,
            serviceplatformId: 'securitas_gilde_lebensversicherung_ag_versicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27200',
            title: 'SIGNAL Krankenversicherung a.G.',
            logo: null,
            serviceplatformId: 'signal_krankenversicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27202',
            title: 'Skandia Lebensversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'skandia_lebensversicherung_ag',
            finapiId: '7278a9e1-eacf-4cbc-afa0-ecb894a745ab',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27203',
            title: 'SKD BKK',
            logo: null,
            serviceplatformId: 'skd_bkk',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27242',
            title: 'Standard Life Assurance Ltd.',
            logo: null,
            serviceplatformId: 'standard_life_assurance_ltd_',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27243',
            title: 'Standard Life Versicherung - Zweigniederlassung Deutschland der The Standard Life Assurance Company',
            logo: null,
            serviceplatformId: 'standard_life_versicherung_zweigniederlassung_deutschland_der_the_standard_life_assurance_company',
            finapiId: '86fb4a5c-8a1e-41d3-bcd9-e871849508a8',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27246',
            title: 'Stuttgarter Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'stuttgarter_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667', '26669'],
        },
        {
            id: '27250',
            title: 'SV Sparkassen-Versicherung Gebäudeversicherung AG',
            logo: null,
            serviceplatformId: 'sv_sparkassen_versicherung_geb_udeversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27251',
            title: 'SV Sparkassen-Versicherung Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'sv_sparkassen_versicherung_lebensversicherung_ag',
            finapiId: 'c101ffe0-2622-4c3a-871d-8846b6b32ff7',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27252',
            title: 'Swiss Life AG',
            logo: null,
            serviceplatformId: 'swiss_life_ag',
            finapiId: '8ef99313-5f12-4654-bcf5-98dd5dddd85c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26664', '26667', '26668', '26669'],
        },
        {
            id: '27255',
            title: 'TARGO Versicherung AG',
            logo: null,
            serviceplatformId: 'targo_versicherung_ag',
            finapiId: '2d3dbd2e-d742-4dc7-90f5-fa1b27899f6b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669', '26667'],
        },
        {
            id: '27260',
            title: 'TRIAS Versicherung AG',
            logo: null,
            serviceplatformId: 'trias_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669', '26662'],
        },
        {
            id: '27264',
            title: 'Union Reiseversicherung AG',
            logo: null,
            serviceplatformId: 'union_reiseversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665'],
        },
        {
            id: '27265',
            title: 'UNIQA/Finance Life Insurance Group',
            logo: null,
            serviceplatformId: 'uniqa_finance_life_insurance_group',
            finapiId: '8414ae66-b6f1-4bf2-9f05-c6b122622345',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665'],
        },
        {
            id: '27266',
            title: 'uniVersa Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'universa_allgemeine_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665', '26667', '26669'],
        },
        {
            id: '27268',
            title: 'uniVersa Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'universa_lebensversicherung_a_g_',
            finapiId: '2ae6fd09-bef3-40b8-825c-2901493e3f70',
            isBrokerPossible: false,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '27269',
            title: 'VAV Versicherung',
            logo: null,
            serviceplatformId: 'vav_versicherung',
            finapiId: '857c549a-e715-4a8c-8c1e-628e4d2a9624',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '0', '26664'],
        },
        {
            id: '27271',
            title: 'VdVA - Versicherungsservice',
            logo: null,
            serviceplatformId: 'vdva_versicherungsservice',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27275',
            title: 'VGH Landschaftliche Brandkasse Hannover',
            logo: null,
            serviceplatformId: 'vgh_landschaftliche_brandkasse_hannover',
            finapiId: '3f672aef-8686-4e2a-9ad9-83be39ea8f05',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664'],
        },
        {
            id: '27276',
            title: 'VGH Provinzial Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'vgh_provinzial_krankenversicherung_hannover_ag',
            finapiId: '9dd9ada6-c123-4233-ab1e-d8d29901b19c',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27277',
            title: 'VGH Provinzial Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'vgh_provinzial_lebensversicherung_hannover',
            finapiId: 'f2990b8d-e7a2-4ef0-82cc-bcde893dba5b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27278',
            title: 'VHV Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'vhv_allgemeine_versicherung_ag',
            finapiId: '2f308f04-30b9-438d-bde0-8f77ea8e9f6a',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27280',
            title: 'VICTORIA Krankenversicherung',
            logo: null,
            serviceplatformId: 'victoria_krankenversicherung',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27281',
            title: 'vigo Krankenversicherung VVaG',
            logo: null,
            serviceplatformId: 'vigo_krankenversicherung_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27314',
            title: 'Volksfürsorge Deutsche Sachversicherung AG',
            logo: null,
            serviceplatformId: 'volksf_rsorge_deutsche_sachversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27317',
            title: 'Volkswohl-Bund Sachversicherung AG',
            logo: null,
            serviceplatformId: 'volkswohl_bund_sachversicherung_ag',
            finapiId: 'cc42a069-ca53-4462-b5cc-a4bc9e24888b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665', '26662'],
        },
        {
            id: '27321',
            title: 'VPV Allgemeine Versicherungs-AG',
            logo: null,
            serviceplatformId: 'vpv_allgemeine_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665', '26662'],
        },
        {
            id: '27334',
            title: 'Westfälische Provinzial Versicherung AG',
            logo: null,
            serviceplatformId: 'westf_lische_provinzial_versicherung_ag',
            finapiId: '968dac6b-67f3-4d58-a88d-ad4d621e52b5',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27335',
            title: 'Westfällische Provential Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'westf_llische_provential_lebensversicherung_ag',
            finapiId: '207e2d1c-799b-47e8-890a-69d9427c8533',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27336',
            title: 'WGV Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'wgv_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '27337',
            title: 'WGV-Versicherungs AG',
            logo: null,
            serviceplatformId: 'wgv_versicherungs_ag',
            finapiId: '0ea77d2e-3864-49fb-8caa-b83aa2d1f10f',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27339',
            title: 'Wiener Städtische Versicherung AG Vienna Insurance Group',
            logo: null,
            serviceplatformId: 'wiener_st_dtische_versicherung_ag_vienna_insurance_group',
            finapiId: 'fcf94bf9-e508-4c78-a53c-751bee29001b',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669', '0', '26667'],
        },
        {
            id: '27347',
            title: 'Würzburger Versicherungs-AG',
            logo: null,
            serviceplatformId: 'w_rzburger_versicherungs_ag',
            finapiId: 'c5e02ab8-2d97-4ff7-95b9-d20bd4b4efcd',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27350',
            title: 'WWK Allgemeine Versicherung AG',
            logo: null,
            serviceplatformId: 'wwk_allgemeine_versicherung_ag',
            finapiId: '3c782f6e-5f59-4990-9a8d-a1fbfc6ef083',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27352',
            title: 'ZURICH Insurance plc Niederlassung für Deutschland',
            logo: null,
            serviceplatformId: 'z_rich_versicherung_ag',
            finapiId: 'fc3440fe-b35c-469c-ba74-28c6e655dd5c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27353',
            title: 'OCC Oldie Car Cover Assekuranzkontor GmbH',
            logo: null,
            serviceplatformId: 'occ_oldie_car_cover_assekuranzkontor_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27354',
            title: 'Pantaenius GmbH',
            logo: null,
            serviceplatformId: 'pantaenius_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27355',
            title: 'Policen Direkt Versicherungsvermittlung GmbH',
            logo: null,
            serviceplatformId: 'policen_direkt_versicherungsvermittlung_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27356',
            title: 'KlinikRente Versorgungswerk GmbH',
            logo: null,
            serviceplatformId: 'klinikrente_versorgungswerk_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27357',
            title: 'Manufaktur Augsburg GmbH',
            logo: null,
            serviceplatformId: 'manufaktur_augsburg_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['0', '26667'],
        },
        {
            id: '27359',
            title: 'Sparkassen Pensionskasse AG',
            logo: null,
            serviceplatformId: 'sparkassen_pensionskasse_ag',
            finapiId: 'c0a8af03-447b-48f1-9f03-ef3831a44061',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27360',
            title: 'Neue Leben Pensionskasse AG',
            logo: null,
            serviceplatformId: 'neue_leben_pensionskasse_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27361',
            title: 'Covomo Versicherungsvergleich GmbH',
            logo: null,
            serviceplatformId: 'covomo_versicherungsvergleich_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27362',
            title: 'HDI Pensionskasse AG',
            logo: null,
            serviceplatformId: 'hdi_pensionskasse_ag',
            finapiId: 'ad717f05-602a-4529-a68e-8982c563b806',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27363',
            title: 'R+V PENSIONSVERSICHERUNG a.G.',
            logo: null,
            serviceplatformId: 'r_v_pensionsversicherung_ag',
            finapiId: '61cfc4ff-35fa-4282-9c8c-cd3ea8af1c68',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27364',
            title: 'HUK-COBURG-Rechtsschutzversicherung AG',
            logo: null,
            serviceplatformId: 'huk_coburg_rechtsschutzversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27365',
            title: '1 und 1 Internet',
            logo: null,
            serviceplatformId: '1_1_internet',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26668'],
        },
        {
            id: '27366',
            title: 'Aldi Talk',
            logo: null,
            serviceplatformId: 'aldi_talk',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27367',
            title: 'Athora Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'athora_lebensversicherung_ag',
            finapiId: '54fb38f6-2f13-43cd-94b9-51d92d6291c2',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '27368',
            title: 'BDAE Holding GmbH',
            logo: null,
            serviceplatformId: 'bdae_holding_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27370',
            title: 'ASKUMA AG',
            logo: null,
            serviceplatformId: 'askuma_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27372',
            title: 'Basler Lebensversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'moneymaxx',
            finapiId: 'c9f0270d-7009-4bac-bc8e-e4258d4720eb',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '27373',
            title: 'Concordia oeco Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'concordia_oeco_lebensversicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26669'],
        },
        {
            id: '27377',
            title: 'ALTE OLDENBURGER Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'alte_oldenburger_krankenversicherung_ag',
            finapiId: 'ae2f4a07-35ef-4473-b341-83201a81e4fe',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27378',
            title: 'Münchener Verein AG',
            logo: null,
            serviceplatformId: 'm_nchener_verein_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27379',
            title: 'DKV Deutsche Krankenversicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'dkv_deutsche_krankenversicherung_ag',
            finapiId: '6e59e7ff-a401-47f6-a1d1-95319c4b9c8c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27380',
            title: 'die Bayerische',
            logo: null,
            serviceplatformId: 'die_bayerische',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '27381',
            title: 'SHB',
            logo: null,
            serviceplatformId: 'shb',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27382',
            title: 'Ostangler Brandgilde Versicherungsverein auf Gegenseitigkeit (VVaG)',
            logo: null,
            serviceplatformId: 'ostangler_brandgilde_vvag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '27384',
            title: 'DA Deutsche Allgemeine Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'da_direkt_versicherung',
            finapiId: 'b49ebdab-34cc-4356-86e8-a58b01d6184d',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27385',
            title: 'Verti Versicherung AG',
            logo: null,
            serviceplatformId: 'verti_versicherung_ag',
            finapiId: '2930fa2b-d4d3-44ab-93dd-2e97ac30b10c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27387',
            title: 'VGH Versicherungen',
            logo: null,
            serviceplatformId: 'vgh_versicherungen',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26665'],
        },
        {
            id: '27389',
            title: 'D.A.S.',
            logo: null,
            serviceplatformId: 'd_a_s_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27391',
            title: 'BGV-Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'bgv_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27392',
            title: 'Raiffeisen Versicherung AG',
            logo: null,
            serviceplatformId: 'raiffeisen_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27394',
            title: 'blau direkt GmbH & Co KG',
            logo: null,
            serviceplatformId: 'blau_direkt_gmbh_co_kg',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['14365'],
        },
        {
            id: '27399',
            title: 'AGA International S.A.',
            logo: null,
            serviceplatformId: 'aga_international_s_a_',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '27400',
            title: 'Dialog Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'dialog_versicherungs_ag',
            finapiId: '82530eb1-05dc-4317-bcfa-da6ed49b92e8',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '27402',
            title: 'Allcura Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'allcura_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667', '26668'],
        },
        {
            id: '28173',
            title: 'Isselhorster Versicherung',
            logo: null,
            serviceplatformId: 'isselhorster_versicherung',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32042',
            title: 'AUXILIA Rechtsschutz-Versicherungs-AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/auxilia.jpg',
            serviceplatformId: 'auxilia_rechtsschutz_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32043',
            title: 'BD24 Berlin Direkt Versicherung AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/bd24BerlinDirektVersicherungAg.png',
            serviceplatformId: 'bd24BerlinDirektVersicherungAg',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32044',
            title: 'DELA Lebensversicherungen',
            logo: 'https://dev-broker.go-digital-software.de/logo/delaLebensversicherungen.png',
            serviceplatformId: 'delaLebensversicherungen',
            finapiId: '3ae65ce0-b59e-417f-b910-d65fb7586462',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662'],
        },
        {
            id: '32045',
            title: 'Deutsche Rentenversicherung',
            logo: 'https://dev-broker.go-digital-software.de/logo/Deutsche Rentenversicherung Logo Serviceplattform 4yourfinance.png',
            serviceplatformId: 'deutsche_rentenversicherung',
            finapiId: '09a42d75-af14-44de-a497-8d13ee131413',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '32047',
            title: 'Feuersozietät Berlin Brandenburg Versicherung Aktiengesellschaft',
            logo: 'https://dev-broker.go-digital-software.de/logo/feuersoziet_t_berlin_brandenburg_versicherung_aktiengesellschaft.png',
            serviceplatformId: 'feuersoziet_t_berlin_brandenburg_versicherung_aktiengesellschaft',
            finapiId: 'c9639a4f-186b-4264-8816-25659978d638',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '32048',
            title: 'Generali Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'generali_lebensversicherung_ag',
            finapiId: 'd92b5cfa-7cac-4a1c-b595-50599555ddb7',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '32050',
            title: 'Karlsruher Lebensversicherung AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/karlsruher_lebensversicherung_ag.png',
            serviceplatformId: 'karlsruher_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '32053',
            title: 'KS Versicherungs AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/auxilia.jpg',
            serviceplatformId: 'ks_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32054',
            title: 'LBN Versicherungsverein a.G.',
            logo: 'https://dev-broker.go-digital-software.de/logo/LBN-Logo-serviceplattform.png',
            serviceplatformId: 'lbn_versicherungsverein_auf_gegenseitigkeit',
            finapiId: '888a3839-f931-4b6b-aaa1-01a34428e8cc',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26669'],
        },
        {
            id: '32056',
            title: 'ÖRAG Rechtsschutzversicherungs-AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/_rag_rechtsschutzversicherungs_ag.png',
            serviceplatformId: '_rag_rechtsschutzversicherungs_ag',
            finapiId: '1e528247-37c9-4a71-9d90-3dc4c565a32a',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32057',
            title: 'UNION KRANKENVERSICHERUNG AKTIENGESELLSCHAFT',
            logo: 'https://dev-broker.go-digital-software.de/logo/ukv_union_krankenversicherung_ag.png',
            serviceplatformId: 'ukv_union_krankenversicherung_ag',
            finapiId: 'b0971c11-ed36-47e1-9776-d4c7eabc98e6',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '32060',
            title: 'Zurich Life Assurance Public Limited Company',
            logo: 'https://dev-broker.go-digital-software.de/logo/Zurich_Logo_serviceplattform (1).png',
            serviceplatformId: 'zurich_insurance_ireland_limited_da_direkt_',
            finapiId: 'c98ab5fe-36b0-4334-9182-9a3ba26a0b16',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '32063',
            title: 'Ammerländer Versicherung V.V.a.G.',
            logo: 'https://dev-broker.go-digital-software.de/logo/ammerl_nder_versicherung_v_v_a_g_.png',
            serviceplatformId: 'ammerl_nder_versicherung_v_v_a_g_',
            finapiId: '75f24a4f-9690-47f2-afe2-820ab6f0ff26',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '52644',
            title: 'OCC Oldie Car Cover Assekuranzkontor GmbH',
            logo: null,
            serviceplatformId: 'occ_oldie_car_cover_assekuranzkontor_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '52646',
            title: 'R + V LEBENSVERSICHERUNG AKTIENGESELLSCHAFT',
            logo: null,
            serviceplatformId: 'r_v_lebensversicherung_ag',
            finapiId: '7e8bcc7d-c2af-4ede-851e-502a86313fb0',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '52648',
            title: 'Volkswohl-Bund Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['26669', '26662'],
        },
        {
            id: '27000',
            title: 'INTER Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'inter_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26669'],
        },
        {
            id: '27396',
            title: 'FRIDAY Insurance S.A.',
            logo: null,
            serviceplatformId: 'friday_insurance_s_a_',
            finapiId: 'a4c05e5a-c1b7-4c5b-b602-a5edfa0befcb',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27401',
            title: 'Proxalto Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'proxalto_lebensversicherung_ag',
            finapiId: '2120e90a-709a-4752-9aa5-821982dd77be',
            isBrokerPossible: true,
            status: '685',
            categories: ['26662', '26669'],
        },
        {
            id: '27403',
            title: 'Schwieswiger Versicherungsverein a.G.',
            logo: null,
            serviceplatformId: 'schwieswiger',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667', '26664'],
        },
        {
            id: '27405',
            title: 'Getsafe Digital GmbH',
            logo: null,
            serviceplatformId: 'getsafe_digital_gmbh',
            finapiId: 'd8444df0-7ef8-4cde-a9f4-8c0a2a5d053e',
            isBrokerPossible: false,
            status: '685',
            categories: ['26665', '26667', '26669'],
        },
        {
            id: '27406',
            title: 'LBS Landesbausparkasse Südwest',
            logo: null,
            serviceplatformId: 'lbs_landesbausparkasse_s_dwest',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26662', '26663', '26666'],
        },
        {
            id: '27407',
            title: 'Adolf ROTH GmbH & Co. KG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27408',
            title: 'mailo Versicherung AG',
            logo: null,
            serviceplatformId: 'mailo',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26664'],
        },
        {
            id: '27409',
            title: 'WERTGARANTIE AG',
            logo: null,
            serviceplatformId: 'wertgarantie',
            finapiId: 'fa6d1d24-511e-4441-8f7e-af7c9caed614',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27412',
            title: 'NORDVERS GmbH',
            logo: null,
            serviceplatformId: 'nordversGmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27413',
            title: 'ONE Insurance Ltd.',
            logo: null,
            serviceplatformId: '',
            finapiId: '888a3839-f931-4b6b-aaa1-01a34428e8cc',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27414',
            title: 'FitX Deutschland GmbH',
            logo: null,
            serviceplatformId: 'fitx',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27415',
            title: 'Energieversorgung Offenbach AG (EVO)',
            logo: null,
            serviceplatformId: 'evo',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27416',
            title: 'Allgemeine Beamten Kasse Kreditbank AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14363'],
        },
        {
            id: '27417',
            title: 'Advanzia Bank S.A.',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27418',
            title: 'Aegon Ireland plc - Niederlassung Deutschland',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27419',
            title: 'AIA AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27420',
            title: 'Allstern - Assekuradeur GmbH & Co. KG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27421',
            title: 'Alpha Real Estate Holding GmbH',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27422',
            title: 'Generali Deutschland Lebensversicherung AG (früher AachenMünchener)',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662', '26669', '26665', '26667'],
        },
        {
            id: '27423',
            title: 'Ampas GmbH',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['14365'],
        },
        {
            id: '27424',
            title: 'Artländer Brandkasse VvaG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '27910',
            title: 'Fairr.de GmbH',
            logo: null,
            serviceplatformId: 'fairr_de_gmbh',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662', '26663'],
        },
        {
            id: '32041',
            title: 'Ärzteversorgung Westfalen-Lippe',
            logo: null,
            serviceplatformId: '_rzteversorgung_westfalen_lippe',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662'],
        },
        {
            id: '32046',
            title: 'Deutsche Rentenversicherung Knappschaft-Bahn-See',
            logo: 'https://dev-broker.go-digital-software.de/logo/knappschaft.png',
            serviceplatformId: 'knappschaft',
            finapiId: '9dc901f7-0ff5-44b1-b9df-78b702b07c9d',
            isBrokerPossible: true,
            status: '685',
            categories: ['26662', '26669'],
        },
        {
            id: '32055',
            title: 'Lichtenstein Life Assurance AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/Lichtenstein Life Assurance AG.png',
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662'],
        },
        {
            id: '32058',
            title: 'Versicherer im Raum der Kirchen',
            logo: 'https://dev-broker.go-digital-software.de/logo/versichererImRaumDerKirchen.png',
            serviceplatformId: 'versichererImRaumDerKirchen',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26665', '26667'],
        },
        {
            id: '32059',
            title: 'Viactiv Krankenkasse',
            logo: 'https://dev-broker.go-digital-software.de/logo/viactivKrankenkasse.png',
            serviceplatformId: 'viactivKrankenkasse',
            finapiId: '4e869ea0-5f59-4f91-a0dd-295172a2d8fc',
            isBrokerPossible: true,
            status: '685',
            categories: ['26665'],
        },
        {
            id: '32062',
            title: 'Adam Riese GmbH',
            logo: null,
            serviceplatformId: 'adam_riese_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52376',
            title: 'cpx Makler Hanse Merkur',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: [],
        },
        {
            id: '52629',
            title: 'Wüstenrot Bausparkasse Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'w_stenrot_bausparkasse',
            finapiId: '4787b63c-795b-446e-962d-4b015637f450',
            isBrokerPossible: false,
            status: '685',
            categories: ['26663'],
        },
        {
            id: '52633',
            title: 'Barmenia Versicherungen a.G.',
            logo: null,
            serviceplatformId: '',
            finapiId: '8f893de9-4953-4461-a786-a8f3472e9631',
            isBrokerPossible: false,
            status: '685',
            categories: [],
        },
        {
            id: '52634',
            title: 'asspario Versicherungsdienst AG',
            logo: null,
            serviceplatformId: 'asspario_versicherungsdienst_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: [],
        },
        {
            id: '52635',
            title: 'DG-Gruppe AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662'],
        },
        {
            id: '52636',
            title: 'JURA DIREKT GmbH',
            logo: null,
            serviceplatformId: 'jura_direct_gmbh',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52637',
            title: 'Profin Prozessfinanzierung GmbH',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52638',
            title: 'Nettowelt GmbH & Co. KG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662', '26669'],
        },
        {
            id: '52639',
            title: 'Schnur & Partner GmbH',
            logo: null,
            serviceplatformId: '',
            finapiId: 'cf988ed3-1884-43c9-af46-1a7c1578b461',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52641',
            title: 'Konzept & Marketing – ihr unabhängiger Konzeptentwickler GmbH',
            logo: null,
            serviceplatformId: 'konzept_marketing_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52642',
            title: 'DF Deutsche Finance Holding AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26663'],
        },
        {
            id: '52643',
            title: 'TravelProtect GmbH',
            logo: null,
            serviceplatformId: 'travel_protect',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667', '26668'],
        },
        {
            id: '52650',
            title: 'VHV Vereinigte Hannoversche Versicherung a.G.',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '685',
            categories: [],
        },
        {
            id: '26692',
            title: 'ABK Allgemeine Beamten Bank AG',
            logo: null,
            serviceplatformId: 'abk_allgemeine_beamten_bank',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['14363'],
        },
        {
            id: '26695',
            title: 'ADAC-Schutzbrief Versicherungs AG',
            logo: null,
            serviceplatformId: 'adac_schutzbrief_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26667', '26668'],
        },
        {
            id: '26770',
            title: 'BL die Bayerische Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'bl_die_bayerische_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['14365'],
        },
        {
            id: '26842',
            title: 'DBV Winterthur Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'dbv_winterthur_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26669'],
        },
        {
            id: '26843',
            title: 'DBV-WinSelect Versicherung AG',
            logo: null,
            serviceplatformId: 'dbv_winselect_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667', '26669', '26665', '26662'],
        },
        {
            id: '26880',
            title: 'Die Hanauer 24 Versicherungsvertriebs GmbH',
            logo: null,
            serviceplatformId: 'die_hanauer_24_versicherungsvertriebs_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '26882',
            title: 'Direct Line Versicherung AG',
            logo: null,
            serviceplatformId: 'direct_line_versicherung_ag',
            finapiId: '2b6f8817-5b34-4c98-8449-06d8ce75bc35',
            isBrokerPossible: false,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '26894',
            title: 'DUK e.V. Dachverband der Unterstützungskassen',
            logo: null,
            serviceplatformId: 'duk_e_v_dachverband_der_unterst_tzungskassen',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26669', '26662'],
        },
        {
            id: '26898',
            title: 'E.ON Energie Deutschland GmbH',
            logo: null,
            serviceplatformId: 'e_on_energie_deutschland',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['56833'],
        },
        {
            id: '26944',
            title: 'Gothaer Versicherungsbank VVaG',
            logo: null,
            serviceplatformId: 'gothaer_versicherungsbank_v_v_a_g_',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '26949',
            title: 'Die Haftpflichtkasse VVaG',
            logo: null,
            serviceplatformId: 'haftpflichtkasse_darmstadt',
            finapiId: '75056226-7225-4007-b9a3-5f393c8cf660',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '26966',
            title: 'HanseMerkur Reiseversicherung AG',
            logo: null,
            serviceplatformId: 'hansemerkur_reiseversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '688',
            categories: ['14365'],
        },
        {
            id: '26971',
            title: 'HDI24 - HDI Direkt Versicherung AG',
            logo: null,
            serviceplatformId: 'hdi24_hdi_direkt_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667', '26664'],
        },
        {
            id: '26972',
            title: 'HDI Rechtsschutz Versicherung AG',
            logo: null,
            serviceplatformId: 'hdi_gerling_rechtsschutz_versicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '26992',
            title: 'IDUNA Vereinigte Lebensversicherung aG',
            logo: null,
            serviceplatformId: 'iduna_vereinigte_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26669', '26662'],
        },
        {
            id: '27042',
            title: 'LBS Bayerische Landesbausparkasse',
            logo: null,
            serviceplatformId: 'lbs_bayerische_landesbausparkasse',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26663'],
        },
        {
            id: '27095',
            title: 'Niederösterreichische Versicherung',
            logo: null,
            serviceplatformId: 'nieder_sterreichische_versicherung',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662', '0', '26664', '26669'],
        },
        {
            id: '27196',
            title: 'SIGNAL IDUNA Allgemeine Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'signal_iduna_allgemeine_versicherung_ag',
            finapiId: '7bf38e17-4153-41b4-acb2-fb496d1f9c33',
            isBrokerPossible: true,
            status: '686',
            categories: ['26664', '26667'],
        },
        {
            id: '27197',
            title: 'Signal Iduna Bauspar AG',
            logo: null,
            serviceplatformId: 'signal_iduna_bauspar_ag',
            finapiId: '527ba541-c67b-4335-a5b9-1491a34fde48',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26663', '26666'],
        },
        {
            id: '27198',
            title: 'SIGNAL IDUNA Krankenversicherung a.G.',
            logo: null,
            serviceplatformId: 'signal_iduna_krankenversicherung_a_g_',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '27199',
            title: 'SIGNAL IDUNA Pensionskasse Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'signal_iduna_pensionskasse_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662'],
        },
        {
            id: '27233',
            title: 'Sparkassen Direkt Versicherung AG',
            logo: null,
            serviceplatformId: 'sparkassen_direkt_versicherung_ag',
            finapiId: '6b7802a0-5d92-47c1-a576-2566a3d5aac4',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667', '26669'],
        },
        {
            id: '27234',
            title: 'Sparkassen Versicherung Vienna',
            logo: null,
            serviceplatformId: 'sparkassen_versicherung_vienna',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26669', '26667', '26662'],
        },
        {
            id: '27235',
            title: 'Sparkassen Versicherung AG',
            logo: null,
            serviceplatformId: 'sparkassen_versicherung_sachsen_allgemeine_versicherung_ag',
            finapiId: '7993e016-a836-45aa-bc0c-61604bbc3652',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662', '0', '26665', '26669'],
        },
        {
            id: '27253',
            title: 'Swiss Life Invest GmbH',
            logo: null,
            serviceplatformId: 'swiss_life_invest_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26663'],
        },
        {
            id: '27257',
            title: 'TK - Techniker Krankenkasse',
            logo: null,
            serviceplatformId: 'techniker_krankenkasse',
            finapiId: 'cc1d1f98-eb58-435b-a6e1-ff719aa39db1',
            isBrokerPossible: true,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '27333',
            title: 'Warburg, M.M. - Hypothekenbank',
            logo: null,
            serviceplatformId: 'warburg_m_m_hypothekenbank',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['14363'],
        },
        {
            id: '27341',
            title: 'WüBa Württembergische & Badische Versicherungs-AG',
            logo: null,
            serviceplatformId: 'w_ba_w_rttembergische_badische_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667', '26664'],
        },
        {
            id: '27342',
            title: 'Württembergische Krankenversicherung AG',
            logo: null,
            serviceplatformId: 'w_rttembergische_krankenversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '27343',
            title: 'Württembergische Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'w_rttembergische_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26669'],
        },
        {
            id: '27345',
            title: 'Württembergische Versicherung AG',
            logo: null,
            serviceplatformId: 'w_rttembergische_versicherung_ag',
            finapiId: '3587e16e-669c-4840-b6ca-27b5cb187b19',
            isBrokerPossible: true,
            status: '686',
            categories: ['26664', '26667'],
        },
        {
            id: '27346',
            title: 'Würzburger Versicherung AG',
            logo: null,
            serviceplatformId: 'w_rzburger_versicherung',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665', '26662'],
        },
        {
            id: '27369',
            title: 'PMA',
            logo: null,
            serviceplatformId: 'pma',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667', '26668'],
        },
        {
            id: '27388',
            title: 'HISCOX S.A.',
            logo: null,
            serviceplatformId: 'hiscox_s_a_',
            finapiId: '50fc7d8e-1f92-4882-a3eb-6389835d2b59',
            isBrokerPossible: true,
            status: '686',
            categories: ['26664', '26667'],
        },
        {
            id: '27428',
            title: 'abcbank GmbH',
            logo: null,
            serviceplatformId: 'abcbank',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '27438',
            title: 'Acredia Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'acredia_versicherung',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '27447',
            title: 'Agricultural Bank of China Limited Frankfurt Branch',
            logo: null,
            serviceplatformId: 'agricultural_bank_of_china_frankfurt_branch',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '27595',
            title: 'Bayerische Beamten Lebensversicherung a.G.',
            logo: null,
            serviceplatformId: 'bayerische_beamten_lebensversicherung_a_g_',
            finapiId: '974c9417-07af-476c-b66a-67ebe45bd305',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662', '26669'],
        },
        {
            id: '27977',
            title: 'Frankfurter Versicherungs-AG',
            logo: null,
            serviceplatformId: 'frankfurter_versicherungs_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '30382',
            title: 'A-T-H Silberstedter Versicherungen VVaG',
            logo: null,
            serviceplatformId: 'a_t_h_silberstedter_versicherungen_vvag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '32051',
            title: 'Karlsruher Rechtsschutzversicherung AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/karlsruher_lebensversicherung_ag (1).png',
            serviceplatformId: 'karlsruher_rechtsschutzversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '32052',
            title: 'Karlsruher Versicherung AG',
            logo: 'https://dev-broker.go-digital-software.de/logo/karlsruher_lebensversicherung_ag.png',
            serviceplatformId: 'karlsruher_versicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '52354',
            title: 'Vereinigte Tierversicherung',
            logo: null,
            serviceplatformId: '',
            finapiId: '312d08f0-beb3-41b1-bd4e-4ddd334c9b36',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '52357',
            title: 'EUROPA Versicherung Aktiengesellschaft',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '52688',
            title: 'HDI Global SE',
            logo: null,
            serviceplatformId: 'hdi_global',
            finapiId: '2ef883d7-eb31-4d28-8f62-58ea0105b8bb',
            isBrokerPossible: true,
            status: '685',
            categories: ['26662', '26664', '26667', '26669'],
        },
        {
            id: '52689',
            title: 'Gothaer Pensionskasse AG',
            logo: null,
            serviceplatformId: 'gothaer_pensionskasse',
            finapiId: 'de2d24e6-524a-47e8-9c8b-0f8d29f14e65',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662'],
        },
        {
            id: '52690',
            title: 'Extrapolice24 Verwaltungs- u. Vertriebsges. mbH',
            logo: null,
            serviceplatformId: '',
            finapiId: '3f9fc28e-b8b2-4dd0-90f0-a7acef263fe8',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52691',
            title: 'ERGO Pensionskasse Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ergo_pensionskasse',
            finapiId: '8b3da49e-bc39-4ffa-80e9-b509d6853af9',
            isBrokerPossible: false,
            status: '685',
            categories: ['26662'],
        },
        {
            id: '52694',
            title: 'Domestic & General Insurance Europe AG',
            logo: null,
            serviceplatformId: 'domestic_general',
            finapiId: '8b3bdcdb-4dce-47f3-8919-127313fbe6de',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52695',
            title: 'Niederösterreichische Versicherung AG',
            logo: null,
            serviceplatformId: '',
            finapiId: 'ba539786-e9ae-40d7-a687-d2f9f8a9d70c',
            isBrokerPossible: false,
            status: '685',
            categories: ['26667'],
        },
        {
            id: '52697',
            title: 'BKK Mobil Oil',
            logo: null,
            serviceplatformId: 'bkk_mobil',
            finapiId: '31dc4e8a-d10c-4b16-ac4c-e38c3710ff42',
            isBrokerPossible: false,
            status: '685',
            categories: ['26665'],
        },
        {
            id: '52700',
            title: 'Allianz Elementar Lebensversicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: '',
            finapiId: '14e23d28-baf9-4eca-88b2-afc7eec85185',
            isBrokerPossible: true,
            status: '685',
            categories: ['26662', '26669'],
        },
        {
            id: '52701',
            title: 'Allianz Elementar Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: '',
            finapiId: 'f455fe10-6e46-4d72-93a2-e285debee940',
            isBrokerPossible: true,
            status: '685',
            categories: ['26664', '26667'],
        },
        {
            id: '26870',
            title: 'DEVK Allgemeine Lebensversicherungs-AG',
            logo: null,
            serviceplatformId: 'devk_allgemeine_lebensversicherungs_ag',
            finapiId: 'd926b1b6-726b-4847-a1ea-8eed53951d63',
            isBrokerPossible: false,
            status: '686',
            categories: ['26669'],
        },
        {
            id: '28496',
            title: 'Nürnberger Versicherung Österreich',
            logo: null,
            serviceplatformId: 'n_rnberger_versicherung_sterreich',
            finapiId: '474ea92c-fa3b-4f0c-b0d7-58db9300ce0b',
            isBrokerPossible: false,
            status: '686',
            categories: ['26667', '26669'],
        },
        {
            id: '52704',
            title: 'Öffentliche Lebensversicherung Berlin Brandenburg',
            logo: null,
            serviceplatformId: '_ffentliche_versicherung_bb',
            finapiId: '5ad83ec9-266d-4945-aef1-ccecc059deea',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662', '26669'],
        },
        {
            id: '53563',
            title: 'E WIE EINFACH GmbH',
            logo: null,
            serviceplatformId: 'e_wie_einfach_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53564',
            title: 'E.ON Energie Deutschland GmbH',
            logo: null,
            serviceplatformId: 'e_on_energie_deutschland_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53565',
            title: 'ENTEGA Energie GmbH',
            logo: null,
            serviceplatformId: 'entega_energie_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53566',
            title: 'envia Mitteldeutsche Energie AG',
            logo: null,
            serviceplatformId: 'envia_mitteldeutsche_energie_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53567',
            title: 'eprimo GmbH',
            logo: null,
            serviceplatformId: 'eprimo_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53568',
            title: 'EWE Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'ewe_aktiengesellschaft',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53569',
            title: 'goldgas GmbH',
            logo: null,
            serviceplatformId: 'goldgas_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53570',
            title: 'immergrün-Energie GmbH',
            logo: null,
            serviceplatformId: 'immergr_n_energie_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53571',
            title: 'innogy SE',
            logo: null,
            serviceplatformId: 'innogy_se',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53572',
            title: 'lekker Energie GmbH',
            logo: null,
            serviceplatformId: 'lekker_energie_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53573',
            title: 'LEW Lechwerke AG',
            logo: null,
            serviceplatformId: 'lew_lechwerke_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53574',
            title: 'MONTANA Energie-Handel GmbH & Co. KG',
            logo: null,
            serviceplatformId: 'montana_energie_handel_gmbh_co_kg',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53575',
            title: 'AlbSW GmbH',
            logo: null,
            serviceplatformId: 'Platzhalter',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['56833'],
        },
        {
            id: '53576',
            title: 'WEMAG AG',
            logo: null,
            serviceplatformId: 'wemag_ag',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '53577',
            title: 'Yello Strom GmbH',
            logo: null,
            serviceplatformId: 'yello_strom_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '56801',
            title: 'AOK Baden-Württemberg',
            logo: null,
            serviceplatformId: 'aok_baden_w_rttemberg',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56802',
            title: 'AOK Bayern',
            logo: null,
            serviceplatformId: 'aok_bayern',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56803',
            title: 'AOK Bremen Bremerhaven',
            logo: null,
            serviceplatformId: 'aok_bremen_bremerhaven',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: [],
        },
        {
            id: '56804',
            title: 'AOK Hessen',
            logo: null,
            serviceplatformId: 'aok_hessen',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56805',
            title: 'AOK Niedersachsen',
            logo: null,
            serviceplatformId: 'aok_niedersachsen',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56806',
            title: 'AOK Nordost',
            logo: null,
            serviceplatformId: 'aok_nordost',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56807',
            title: 'AOK Nordwest',
            logo: null,
            serviceplatformId: 'aok_nordwest',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56808',
            title: 'AOK Plus',
            logo: null,
            serviceplatformId: 'aok_plus',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56809',
            title: 'AOK Plus Sachsen Thüringen',
            logo: null,
            serviceplatformId: 'aok_plus_sachsen_th_ringen',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56810',
            title: 'AOK Rheinland Hamburg',
            logo: null,
            serviceplatformId: 'aok_rheinland_hamburg',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56811',
            title: 'AOK Sachsen Anhalt',
            logo: null,
            serviceplatformId: 'aok_sachsen_anhalt',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56812',
            title: 'E WIE EINFACH GmbH',
            logo: null,
            serviceplatformId: 'e_wie_einfach_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '56813',
            title: 'E.ON Energie Deutschland GmbH',
            logo: null,
            serviceplatformId: 'e_on_energie_deutschland_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: [],
        },
        {
            id: '56814',
            title: 'Energie-BKK',
            logo: null,
            serviceplatformId: 'energie_bkk',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56819',
            title: 'Friends Provident Life Assurance Limited',
            logo: null,
            serviceplatformId: 'friends_provident_life_assurance_limited',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26664', '26667'],
        },
        {
            id: '56821',
            title: 'HKK- Handelskrankenkasse',
            logo: null,
            serviceplatformId: 'hkk_erste_gesundheit',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56824',
            title: 'KlinikRente Versorgungswerk GmbH',
            logo: null,
            serviceplatformId: 'klinikrente_versorgungswerk_gmbh',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26662', '26669'],
        },
        {
            id: '56827',
            title: 'Lichtenstein Life Assurance AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26662'],
        },
        {
            id: '56828',
            title: 'mhplus Krankenkasse',
            logo: null,
            serviceplatformId: 'mhplus_krankenkasse',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26665'],
        },
        {
            id: '56830',
            title: 'Neodigital Versicherung AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '56831',
            title: 'Policenwerk Assekuradeure',
            logo: null,
            serviceplatformId: 'policenwerk_assekuradeure',
            finapiId: '',
            isBrokerPossible: false,
            status: '686',
            categories: ['26664', '26667'],
        },
        {
            id: '56832',
            title: 'Wefox Insurance AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: true,
            status: '686',
            categories: ['26667'],
        },
        {
            id: '27023',
            title: 'KRAVAG-LOGISTIC Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: 'kravag_logistic_versicherungs_ag',
            finapiId: '00bb8387-fc95-470a-bb34-0497a9f077f1',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667', '26664'],
        },
        {
            id: '27274',
            title: 'Versicherungskammer Bayern',
            logo: null,
            serviceplatformId: 'versicherungskammer_bayern_versicherungsanstalt_des',
            finapiId: '8c86746e-fa7a-4721-8c23-f9c2509461c6',
            isBrokerPossible: true,
            status: '688',
            categories: ['26664', '26665', '0', '26667'],
        },
        {
            id: '27643',
            title: 'BKK ProVita',
            logo: null,
            serviceplatformId: 'bkk_provita',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '28589',
            title: 'Pronova BKK',
            logo: null,
            serviceplatformId: 'pronova_bkk',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '29509',
            title: 'TARGO Lebensversicherung AG',
            logo: null,
            serviceplatformId: 'targo_lebensversicherung_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: [],
        },
        {
            id: '52655',
            title: 'Condor Allgemeine Versicherungs-Aktiengesellschaft',
            logo: null,
            serviceplatformId: '',
            finapiId: '93d69bac-40f4-4e02-b5e3-b3ed87efd529',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '52692',
            title: 'Deutsche Rentenversicherung',
            logo: 'https://dev-broker.go-digital-software.de/logo/Deutsche Rentenversicherung Logo Serviceplattform 4yourfinance.png',
            serviceplatformId: 'deutsche_rentenversicherung',
            finapiId: 'af8f6b05-ac83-4c93-a841-c19ad22b4195',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '52693',
            title: 'Deutsche Rentenversicherung Mitteldeutschland',
            logo: 'https://dev-broker.go-digital-software.de/logo/Deutsche Rentenversicherung Logo Serviceplattform 4yourfinance.png',
            serviceplatformId: 'deutsche_rentenversicherung_mittel',
            finapiId: 'a9a0c183-3e5a-4457-b3e2-858398b780b1',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '52696',
            title: 'Deutsche Rentenversicherung Braunschweig-Hannover',
            logo: 'https://dev-broker.go-digital-software.de/logo/Deutsche Rentenversicherung Logo Serviceplattform 4yourfinance.png',
            serviceplatformId: 'deutsche_rentenversicherung_braunschweig',
            finapiId: 'c00874e1-f6e9-498d-972d-7221fba590d5',
            isBrokerPossible: false,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '52698',
            title: 'BKK RWE',
            logo: null,
            serviceplatformId: 'bkk_rwe',
            finapiId: 'bb8a48a8-7687-4fb1-afe2-375f64f41569',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52699',
            title: 'BKK Deutsche Bank AG',
            logo: null,
            serviceplatformId: 'bkk_db',
            finapiId: '6632a745-f462-4b2e-a0c7-c4b13032eabb',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52702',
            title: 'BKK actimonda',
            logo: null,
            serviceplatformId: 'bkk_actimonda',
            finapiId: '8a83539d-95b1-4ead-9a89-19975dc8fb5e',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52703',
            title: 'Heimat Krankenkasse',
            logo: null,
            serviceplatformId: 'heimat_kasse',
            finapiId: '88f1f6c6-0017-4e87-8959-2bde220f32d7',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52705',
            title: 'BKK R+V',
            logo: null,
            serviceplatformId: 'bkk_rv',
            finapiId: '3e2f0801-31ef-42c5-b6bb-4b376b14baf0',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52706',
            title: 'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau',
            logo: null,
            serviceplatformId: '',
            finapiId: '211bbc20-d204-486e-a11b-0913bb7954ba',
            isBrokerPossible: false,
            status: '687',
            categories: ['26665'],
        },
        {
            id: '52707',
            title: 'Telefonica Insurance S.A.',
            logo: null,
            serviceplatformId: '',
            finapiId: '490e0677-5b59-4630-8f51-9dbd6c4124bf',
            isBrokerPossible: false,
            status: '687',
            categories: ['26667', '26668'],
        },
        {
            id: '52708',
            title: 'IAS Internationale Assekuranz-Service GmbH',
            logo: null,
            serviceplatformId: 'ias',
            finapiId: 'f36b5496-fc30-4b10-b170-b8aeaf52d43c',
            isBrokerPossible: true,
            status: '687',
            categories: ['26664', '26667'],
        },
        {
            id: '52709',
            title: 'Zürich Versicherungs-Aktiengesellschaft',
            logo: 'https://dev-broker.go-digital-software.de/logo/Zurich_Logo_serviceplattform (1).png',
            serviceplatformId: 'zurich_ag',
            finapiId: '593237d0-a2d1-4809-b4ab-a5ac0282e697',
            isBrokerPossible: true,
            status: '687',
            categories: ['26667'],
        },
        {
            id: '52710',
            title: 'Zurich Deutscher Herold Lebensversicherung Aktiengesellschaft',
            logo: 'https://dev-broker.go-digital-software.de/logo/Zurich_Logo_serviceplattform (1).png',
            serviceplatformId: 'zurich_herold',
            finapiId: '1cd46906-09f6-4747-a3f8-0573daef37e2',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26669'],
        },
        {
            id: '52728',
            title: 'BHW Bausparkasse AG',
            logo: null,
            serviceplatformId: 'bhw_bausparkasse_ag',
            finapiId: '',
            isBrokerPossible: false,
            status: '687',
            categories: ['26663'],
        },
        {
            id: '52729',
            title: 'Standard Life Versicherung - Zweigniederlassung Deutschland der The Standard Life Assurance Company',
            logo: null,
            serviceplatformId: 'standard_life_versicherung_zweigniederlassung_deutschland_der_the_standard_life_assurance_company',
            finapiId: '86fb4a5c-8a1e-41d3-bcd9-e871849508a8',
            isBrokerPossible: true,
            status: '687',
            categories: ['26662', '26665', '26667'],
        },
        {
            id: '56835',
            title: 'Pfalzwerke Aktiengesellschaft',
            logo: null,
            serviceplatformId: '123_energie',
            finapiId: '',
            isBrokerPossible: true,
            status: '687',
            categories: ['56833'],
        },
        {
            id: '57947',
            title: 'ELEMENT Insurance AG',
            logo: null,
            serviceplatformId: '',
            finapiId: '',
            isBrokerPossible: true,
            status: '685',
            categories: ['26667'],
        },
    ],
    brokerStatuses: [
        {
            id: '30388',
            title: 'Im Bestand',
            serviceplatformId: 'active_with_payment',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30389',
            title: 'Offen',
            serviceplatformId: 'open',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30390',
            title: 'In Bestandsübertragung',
            serviceplatformId: 'on_work',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30391',
            title: 'Storniert/Deaktiviert',
            serviceplatformId: 'deactivation_broker',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30392',
            title: 'Angefragt',
            serviceplatformId: 'check_broker',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30393',
            title: 'Fremdbestand (Korrespondenz)',
            serviceplatformId: 'active_without_fee',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30394',
            title: 'Nachbearbeitung',
            serviceplatformId: 'post_processing',
            isBrokerPossible: true,
            status: '710',
        },
        {
            id: '30395',
            title: 'Abgelehnt',
            serviceplatformId: 'reject',
            isBrokerPossible: true,
            status: '710',
        },
    ],
    contractStatuses: [
        {
            id: 96131,
            title: 'Aktiv',
            serviceplatformId: 'active',
            isBrokerPossible: true,
            finapiId: 'ACTIVE',
            status: '893',
        },
        {
            id: 96132,
            title: 'Aktiv',
            serviceplatformId: 'active',
            isBrokerPossible: true,
            finapiId: 'ACTIVE',
            status: '893',
        },
        {
            id: 96133,
            title: 'Beitragsfrei',
            serviceplatformId: 'noncontributory',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96134,
            title: 'Beitragsfrei',
            serviceplatformId: 'noncontributory',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96135,
            title: 'Abgelaufen',
            serviceplatformId: 'ending',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96136,
            title: 'Abgelaufen',
            serviceplatformId: 'ending',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96137,
            title: 'Gekündigt',
            serviceplatformId: 'canceled',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96138,
            title: 'Gekündigt',
            serviceplatformId: 'canceled',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96139,
            title: 'In Kündigung',
            serviceplatformId: 'will_be_canceled',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96140,
            title: 'In Kündigung',
            serviceplatformId: 'will_be_canceled',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96141,
            title: 'Storniert',
            serviceplatformId: '',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96142,
            title: 'Storniert',
            serviceplatformId: '',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
        {
            id: 96143,
            title: 'Antrag',
            serviceplatformId: 'antrag',
            isBrokerPossible: false,
            finapiId: '',
            status: '894',
        },
        {
            id: 96144,
            title: 'Antrag',
            serviceplatformId: 'antrag',
            isBrokerPossible: false,
            finapiId: '',
            status: '894',
        },
        {
            id: 104080,
            title: 'Widerrufen',
            serviceplatformId: 'canceled',
            isBrokerPossible: false,
            finapiId: '',
            status: '893',
        },
    ],
    paymentMethods: [
        {
            id: '31792',
            title: 'Täglich',
            serviceplatformId: 'daily',
            isBrokerPossible: true,
            finapiId: 'DAILY',
            status: '895',
        },
        {
            id: '31793',
            title: 'Halbjährlich',
            serviceplatformId: 'half_yearly',
            isBrokerPossible: true,
            finapiId: 'HALFYEARLY',
            status: '895',
        },
        {
            id: '31794',
            title: 'Monatlich',
            serviceplatformId: 'monthly',
            isBrokerPossible: true,
            finapiId: 'MONTHLY',
            status: '895',
        },
        {
            id: '31795',
            title: 'Einmalzahlung',
            serviceplatformId: 'one_time',
            isBrokerPossible: true,
            finapiId: '',
            status: '895',
        },
        {
            id: '31796',
            title: 'Vierteljährlich',
            serviceplatformId: 'quarterly',
            isBrokerPossible: true,
            finapiId: 'QUARTERLY',
            status: '895',
        },
        {
            id: '31797',
            title: 'Jährlich',
            serviceplatformId: 'yearly',
            isBrokerPossible: true,
            finapiId: 'YEARLY',
            status: '895',
        },
        {
            id: '31798',
            title: 'Sonstiges',
            serviceplatformId: 'others',
            isBrokerPossible: true,
            finapiId: '',
            status: '895',
        },
    ],
    salutations: [
        {
            id: '31239',
            title: 'Sehr geehrter Herr',
            serviceplatformId: 'mr',
            status: '821',
        },
        {
            id: '31240',
            title: 'Sehr geehrte Frau',
            serviceplatformId: 'mrs',
            status: '821',
        },
        {
            id: '52730',
            title: 'Andere',
            serviceplatformId: '',
            status: '821',
        },
    ],
};

export default obj;
