/**
 * Created by Max Gornostayev on 12/22/22
 *
 * pageId: impressum
 * path: /impressum
 *
 * this is a page that show profile of the customer
 */

import React from 'react';
import { observer } from 'mobx-react';
import Header from '../components/theme/Header';
import trans from '../trans';
import '../styles/impressum.scss';

const ImpressumPage = observer(() => {
    //render function
    return (
        <div className="app impressum-page">
            <Header isBack />
            <div className="top">
                <div className="wrapper-big">
                    <h1>{trans.t('impressumPage', 'h1')}</h1>
                </div>
            </div>
            <div className="content">
                <div className="wrapper-big">
                    <div className="title">{trans.t('impressumPage', 'title1')}</div>
                    <div className="text">{trans.tHTML('impressumPage', 'text1')}</div>
                    <div className="title">{trans.t('impressumPage', 'title2')}</div>
                    <div className="text">{trans.t('impressumPage', 'text2')}</div>
                    <div className="title">{trans.t('impressumPage', 'title3')}</div>
                    <div className="text">{trans.t('impressumPage', 'text3')}</div>
                    <div className="title">{trans.t('impressumPage', 'title4')}</div>
                    <div className="text">{trans.t('impressumPage', 'text4')}</div>
                </div>
            </div>
        </div>
    );
});

export default ImpressumPage;
