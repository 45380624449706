/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractEdit
 * path: /onboarding/vertrag/daten
 *
 * this is a detail contract page for edit number and add documents into contract in onboarding process
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import ProviderField from '../../components/elements/ProviderField';
import InputText from '../../components/forms/InputText';
import FileDropzone from '../../components/forms/FileDropzone';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/onboarding.scss';
import Validation from '../../lib/Validation';

const ContractEditPage = observer(({ DataStore, ContractsStore }) => {
    //get contractTemp
    const { contractTemp } = ContractsStore;

    //state variables
    const [number, setNumber] = useState(contractTemp.numberValue);
    const [documents, setDocuments] = useState(contractTemp.getNewDocuments);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //ref variable for popup
    const refInput = useRef();

    //handling changing documetns array
    const onUpdatedDocuments = (files) => {
        setDocuments(files);
    };

    //handling next button click
    const next = () => {
        const validObj = refInput.current.validate();
        if (!validObj.isValid) {
            refInput.current.setValidation(validObj.isValid, validObj.validateMsg);
            return false;
        }

        // set number of temp contract
        ContractsStore.setFieldForAddingContract({ number });
        // set documents of temp contract
        ContractsStore.addDocumentsForAddingContract(documents);
        // add temp contract into array of user's contracts
        ContractsStore.addTempContract();
        // navigate to contract list page
        navigate(pageUrl.onboardingContractList, { state: { isContractAdded: true, isFinapi: !contractTemp.isManual } });

        return true;
    };

    return (
        <div className="app onboarding-contract-edit-page">
            <Header isBack expertMsg={trans.t('onboardingContractEditPage', 'expertMsg')} />
            <div className="content">
                <div className="wrapper">
                    <h1>{DataStore.getProductTypeTitle(contractTemp.productTypeValue)}</h1>
                    <ProviderField title={DataStore.getProviderTitle(contractTemp.providerWithCustomValue)} onClick={() => navigate(pageUrl.onboardingProviders)} />
                    <div className="line" />
                    <InputText
                        ref={refInput}
                        title={trans.t('onboardingContractEditPage', 'number')}
                        defaultValue={contractTemp.numberValue}
                        onChange={(val) => setNumber(val)}
                        validateFunc={(val) => Validation.emptyValue(val)}
                    />
                    <FileDropzone documents={documents} onChange={onUpdatedDocuments} />
                </div>
            </div>
            <BottomLinks />
            {contractTemp.providerWithCustomValue && (
                <Bottom>
                    <Button role="btnNext" text={trans.t('onboardingContractEditPage', 'btnNext')} onClick={next} isRightIcon is200 />
                </Bottom>
            )}
        </div>
    );
});

export default ContractEditPage;
