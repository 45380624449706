/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a header
 */

import { useNavigate } from 'react-router-dom';
import trans from '../../trans';
import IconLogo from '../../assets/icon-logo.png';
import IconExpert from '../../assets/icon-expert.svg';

export default function Header({ expertTitle, expertMsg, isBack, children, paddingBottom, expertClass }) {
    const navigate = useNavigate();

    const styles = {};

    if (paddingBottom || paddingBottom === 0) {
        styles.paddingBottom = paddingBottom + 'px';
    }

    return (
        <div className="header" style={styles}>
            <div className="wrapper-big">
                <div className={isBack ? 'top-with-back' : 'top'}>
                    {!!isBack && <div className="back" onClick={() => navigate(-1)} />}
                    <img src={IconLogo} alt="Logo" className="logo" />
                </div>
                {!!expertMsg && (
                    <div className={'expert ' + (expertClass || '')}>
                        <div className="wrapper">
                            <img src={IconExpert} alt="Experte" />
                            <div className="texts">
                                {expertTitle && <div className="titleExpert">{expertTitle}</div>}
                                {!expertTitle && <div className="title">{trans.t('common', 'expertTitle')}</div>}
                                <div className="text">{expertMsg}</div>
                            </div>
                        </div>
                    </div>
                )}
                {!!children && <div className="wrapper children">{children}</div>}
            </div>
        </div>
    );
}
