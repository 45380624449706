/**
 * Created by Max Gornostayev on 09/26/22
 *
 * pageId: authForgotEmailSend
 * path: /auth/forgot/email
 *
 * this is a forgot page where user can reset his password using email
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Loading from '../../components/elements/Loading';
import Validation from '../../lib/Validation';
import Customer from '../../models/entity/Customer';
import InputText from '../../components/forms/InputText';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';

const ForgotEmailSendPage = observer(() => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    //ref variable
    const refEmail = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const ctaClick = async () => {
        const validObj = refEmail.current.validate();
        if (!validObj.isValid) {
            refEmail.current.setValidation(validObj.isValid, validObj.validateMsg);
            return false;
        }

        setIsLoading(true);
        const res = await Customer.sendForgotEmail(email);
        setIsLoading(false);

        if (!res.status) {
            const msg = res.data && res.data.message === 'Email not confirmed.' ? trans.t('authForgotEmailSendPage', 'errorEmailNotFound') : res.msg;
            refAlert.current.error(msg);
            return false;
        }
        navigate(pageUrl.authForgotEmailConfirm);

        return true;
    };

    //main rendering
    return (
        <div className="app auth-forgot-send-page">
            <Header />
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        <h1>{trans.t('authForgotEmailSendPage', 'h1')}</h1>
                        <InputText
                            ref={refEmail}
                            role="emailTestId"
                            title={trans.t('authForgotEmailSendPage', 'email')}
                            defaultValue=""
                            onChange={(val) => setEmail(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.email(val)}
                        />
                        <Button role="btnCta" text={trans.t('authForgotEmailSendPage', 'btnNext')} onClick={ctaClick} isFullWidth className="margin-top-48" />
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ForgotEmailSendPage;
