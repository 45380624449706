/**
 * Created by Max Gornostayev on 09/26/22
 *
 * pageId: authStartRegister
 * path: /start/register
 *
 * this is a start page where user could select what he needs: register with person or company account
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';
import AsyncSearchSelect from '../../components/forms/AsyncSearchSelect';
import Checkbox from '../../components/forms/Checkbox';
import InputText from '../../components/forms/InputText';
import DataStore from '../../stores/DataStore';
import Alert from '../../components/popups/Alert';

const StartRegisterPage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();
    //state variables
    const [isReferalCustom, setIsReferalCustom] = useState(false);
    const [referalId, setReferalId] = useState('');
    const [referalCustom, setReferalCustom] = useState('');
    const [defaultOptions, setDefaultOptions] = useState([]);

    //ref variables
    const refAlert = useRef();
    const refReferalId = useRef();
    const refReferalCustomId = useRef();

    //get search query object
    const [searchParams] = useSearchParams();
    const queryReferalId = searchParams.get('referalID') || '';

    //component did mount handler
    useEffect(async () => {
        if (queryReferalId) {
            const defaultOptions = await DataStore.getUserList('', queryReferalId);
            setDefaultOptions(defaultOptions);
            if (defaultOptions.length) {
                setReferalId(queryReferalId);
            }
        }
    }, []);

    //button handler
    const nextPrivatePerson = () => {
        if (!checkReferal()) {
            return false;
        }
        ContractsStore.clearStore();
        UserStore.clearStore();
        UserStore.setIsCompany(false);
        UserStore.setUserDataFromStore({ referalID: referalId, referalName: referalCustom });
        navigate(pageUrl.onboardingTerms);
    };

    //button handler
    const nextCompany = () => {
        if (!checkReferal()) {
            return false;
        }
        ContractsStore.clearStore();
        UserStore.clearStore();
        UserStore.setIsCompany(true);
        UserStore.setUserDataFromStore({ referalID: referalId, referalName: referalCustom });
        navigate(pageUrl.onboardingTerms);
    };

    //check referal id or referal custom
    const checkReferal = () => {
        if ((isReferalCustom && !referalCustom) || (!isReferalCustom && !referalId)) {
            const errorMsg = trans.t('authStartRegisterPage', 'referalError');
            if (isReferalCustom) {
                refReferalCustomId.current.setValidation(false, errorMsg);
            } else {
                refReferalId.current.setValidation(false, errorMsg);
            }
            refAlert.current.error(errorMsg);
            return false;
        }
        refReferalId.current.setValidation(true);
        if (isReferalCustom) {
            refReferalCustomId.current.setValidation(true);
        }
        return true;
    };

    const loadReferalOptions = (inputValue: string) =>
        new Promise((resolve) => {
            DataStore.getUserList(inputValue).then((options) => resolve(options));
        });

    //main rendering
    return (
        <div className="app auth-start-page" role="app">
            <Header />
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        <h1>{trans.t('authStartRegisterPage', 'h1')}</h1>
                        <AsyncSearchSelect
                            ref={refReferalId}
                            role="selectReferal"
                            className="margin-top-48"
                            loadOptions={loadReferalOptions}
                            title={trans.t('authStartRegisterPage', 'referalSelect')}
                            onChange={(val) => setReferalId(val)}
                            noOptionsMessage={trans.t('authStartRegisterPage', 'referalNoOptions')}
                            defaultOptions={defaultOptions}
                            defaultValue={queryReferalId}
                        />
                        <Checkbox title={trans.t('authStartRegisterPage', 'referalCheckbox')} onClick={(val) => setIsReferalCustom(val)} className="checkbox-blue margin-top-16" />
                        {!!isReferalCustom && (
                            <InputText
                                ref={refReferalCustomId}
                                role="referalCustomId"
                                title={trans.t('authStartRegisterPage', 'referalCustom')}
                                defaultValue={referalCustom}
                                onChange={(val) => setReferalCustom(val)}
                                className="input-blue no-pen height-50"
                            />
                        )}
                        <div className="desc margin-top-48">{trans.t('authStartRegisterPage', 'text')}</div>
                        <Button role="btnPrivatePerson" text={trans.t('authStartRegisterPage', 'registerPrivateBtn')} onClick={nextPrivatePerson} isFullWidth className="margin-top-48" />
                        <Button
                            role="btnCompany"
                            text={trans.t('authStartRegisterPage', 'registerCompanyBtn')}
                            onClick={nextCompany}
                            isFullWidth
                            className="margin-top-12"
                            isTransparentOrange
                        />
                        <div className="row-center">
                            <ButtonLink
                                role="btnLogin"
                                text={trans.t('authStartRegisterPage', 'registerLoginBtn')}
                                onClick={() => navigate(pageUrl.authStartLogin)}
                                className="margin-top-70"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Alert ref={refAlert} />
            <BottomLinks isBgBlue />
        </div>
    );
});

export default StartRegisterPage;
