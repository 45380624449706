// check this task - https://portal.go-digital-software.de/company/personal/user/1560/tasks/task/view/35703/

const hidedProviders = [
    'die_bayerische',
    'bayerische_beamten_lebensversicherung_a_g_',
    'bayerische_beamten_versicherung_ag',
    'bayerische_beamtenkrankenkasse_ag',
    'bayerische_hausbesitzer_versicherungs_gesellschaft_a_g_',
    'bayerische_landesbank',
    'bayerische_landesbrandversicherung_ag',
    'bayerische_verm_gen_ag_verm_gensbetreuung_f_r_private_kunden_aktiengesellschaft',
    'bayerische_verm_gen_management_ag',
    'bayerische_versorgungskammer',
    'bev_bayerische_energieversorgungsgesellschaft_mbh',
    'bl_die_bayerische_lebensversicherung_ag',
    'die_bayerische_ehemals_nbbv',
];

export default hidedProviders;
