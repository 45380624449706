const initial = [
    // initial
    { type: 'secTitle', text: 'Erstinformationen gemäß §15 Versicherungsvermittlungsverordnung für den deutschen Markt:'},
    { type: 'subTitle', text: 'Gesellschaft:'},
    { type: 'textTitl', text: "die Bayerische Versicherungsmakler GmbH"},
    { type: 'textTitl', text: "Geschäftsanschrift:"},
    { type: 'textTitl', text: "Thomas-Dehler-Str.25"},
    { type: 'textTitl', text: "81737 München"},
    { type: 'textTitl', text: "Telefon: 089 6787 9250"},
    { type: 'textTitl', text: "Email:versicherungsmakler@diebayerische.de"},
    { type: 'textTitl', text: "Internet: www diebayerische-versicherungsmakler.de"},
    { type: 'subTitle', text: 'Geschäftsführer:'},
    { type: 'textTitl', text: "Geschäftsführer: Thomas Widmann"},
    { type: 'subTitle', text: 'Handelsregister:'},
    { type: 'textTitl', text: "die Bayerische Versicherungsmakler GmbH ist eingetragen im Handelsregister des Amtsgerichts München unter der Nr. HRB 271912"},
    { type: 'subTitle', text: 'Erlaubnis:'},
    { type: 'textTitl', text: "die Bayerische Versicherungsmakler GmbH ist tätig als Versicherungsmakler im Sinne des § 34d Abs. 1 der Gewerbeordnung.Die Erlaubnis nach § 34d Abs. 1 GewO wurde am 01.03.2022 erteilt. Die Bayerische Versicherungsmakler GmbH ist unter der Nummer D-WQRW-Q03BR-25 gemäß § 34d Abs. 7 GewO in das Versicherungsvermittlerregister eingetragen."},
    { type: 'subTitle', text: 'Beratungsleistung:'},
    { type: 'textTitl', text: "Hinsichtlich der vermittelten Versicherungsprodukte bietet die Bayerische Versicherungsmakler GmbH eine Beratung an."},
    { type: 'subTitle', text: 'Vergütung:'},
    { type: 'textTitl', text: "Für die Vermittlung der Versicherungsprodukte erhält die Bayerische Versicherungsmakler GmbH eine Provision, welche in der Versicherungsprämie enthalten ist. Es werden Versicherungsprodukte von einer Vielzahl unterschiedlicher Versicherer vermittelt. Die mit dem Maklerpool vereinbarten Provisionen können sich der Höhe nach unterscheiden."},
    { type: 'subTitle', text: 'Unabhängigkeit:'},
    { type: 'textTitl', text: "die Bayerische Versicherungsmakler GmbH hält keine direkten oder indirekten Beteiligungen von über 10 % der Stimmrechte oder des Kapitals an einem Versicherungsunternehmen. Das Versicherungsunternehmen Bayerische Beamten Lebensversicherung a.G., Thomas-Dehler-Str. 25, 8173 München, besitzt eine mittelbare Beteiligung von über 10 % an den Stimmrechten bzw. am Kapital der Bayerischen Versicherungsmakler GmbH."},
    { type: 'subTitle', text: 'Berufshaftpflichtversicherung:'},
    { type: 'textTitl', text: "Es besteht eine gesetzlich vorgeschriebene Haftpflichtversicherung mit gesetzlich vorgeschriebenen Deckungssummen."},
    { type: 'subTitle', text: 'Die Eintragung im Vermittlerregister kann überprüft werden:'},
    { type: 'textTitl', text: 'Deutscher Industrie- und Handelskammertag (DIHK) e.V.\n Breite Straße 2910178 Berlin\n Telefon: 0180 600 585 0\n (20 Cent/Anruf aus dem dt. Festnetz, höchstens 60 Cent/Anruf aus Mobilfunknetzen)\n www.vermittlerregister.info'},
    { type: 'subTitle', text: 'Schlichtungsstellen, die bei einem Streit angerufen werden können:'},
    { type: 'textTitl', text: 'Versicherungsombudsmann e.V.\n Prof. Dr. Günter Hirsch\n Postfach 08 06 32\n 10006 Berlin\n \n Telefon: 0800 3696000 (kostenfrei)\n (weitere Informationen unter: www.versicherungsombudsmann.de\n \n Ombudsmann für die private Kranken-und Pflegeversicherung\n Herr Heinz Lanfermann\n Postfach 06022210052 Berlin\n \n Telefon: 0800 2550444 (kostenfrei aus deutschen Telefonnetzen)\n (weitere Informationen unter: www.pkv-ombudsmann.de\n \n Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)\n Graurheindorfer Straße 108\n 53117 Bonn\n \n Telefon: 0228 4108-0'},
    { type: 'subTitle', text: 'Zuständige Erlaubnisbehörde:'},
    { type: 'textTitl', text: 'Industrie- und Handelskammer München und Oberbayern\n Max-Joseph-Str. 280333 München\n Telefon: 089 5116-0\n Email: info@muenchen.ihk.de\n Internet: www.ihk-muenchen.de\n \n '},

];

export default initial;
