const terms = [
    // terms
{ type: 'secTitle', text: 'Allgemeine Geschäftsbedingungen '},
{ type: 'subTitle', text: 'Präambel'},
{ type: 'textTitle', text: 'die Bayerische Versicherungsmakler GmbH (im Folgenden „Makler“ oder „wir“ / „uns“) ist Betreiberin der Internet-Plattform www.diebayerische-versicherungsmakler.de. Wir sind als Makler für Versicherungen und Darlehen sowie Finanzdienstleistungen tätig. '},
{ type: 'subTitle', text: 'Unsere Aufgaben'},
{ type: 'textTitle', text: 'Wir vertreten Dich gegenüber den Versicherern. Zum Beispiel:\n\tgeben wir alle notwendigen Willenserklärungen ab und\n\tnehmen diese auch entgegen,\n\tschließen neue Verträge ab,\n\tändern und beenden bestehende Verträge.\n Wir handeln immer nur mit Deinem ausdrücklichen Einverständnis.\n\n Deine bestehenden Verträge kannst Du auf unserem Portal online verwalten. Dies kann je nach technischer Verfügbarkeit sowohl auf einem Desktop als auch auf mobilen Endgeräten erfolgen.'},
{ type: 'subTitle', text: '1. Geltungsbereich: '},
{ type: 'textTitle', text: '1.1 Diese Bedingungen (im Folgenden „AGB“) regeln das gesamte vertragliche Verhältnis zwischen Dir und uns. Die AGB gelten für sämtliche Leistungen und Dienste und Geschäfte, die Du über unsere Internet-Plattform, ggf. per App oder im direkten Kontakt mit uns tätigst\n\n'},
{ type: 'textTitle', text: '1.2 Die AGB gelten in der zum Zeitpunkt der Bestellung gültigen Fassung. Du findest diese AGB online unter:www.diebayerische-versicherungsmakler.de\n\n'},
{ type: 'textTitle', text: '1.3 Abweichende AGB gelten nur, wenn wir diesen schriftlich zugestimmt haben.\n\n'},
{ type: 'textTitle', text: '1.4 Die Erstinformation gem. § 15 Versicherungsvermittlungsverordnung kannst Du unter www.diebayerische-versicherungsmakler.de lesen und herunterladen. Sie enthält gesetzlich vorgeschriebene Informationen über uns.\n\n'},
{ type: 'textTitle', text: '1.5 Die bei einer Darlehensvermittlung außerdem vorgesehenen verpflichtenden Informationen erhältst Du von uns, bevor Du uns mit der Vermittlung beauftragst und bevor wir mit der Vermittlung Deines Darlehens beginnen.\n\n'},
{ type: 'subTitle', text: '2. Nutzung der Internet-Plattform'},
{ type: 'textTitle', text: '2.1 Du kannst auf unserer Internet-Plattform, bzw. ggf. per App bestehende Versicherungen einfach und digital verwalten. Du kannst unsere Internet-Plattform bzw. ggf. per App kostenlos nutzen und die Nutzung jederzeit beenden.\n\n'},
{ type: 'textTitle', text: '2.2 Wenn Du auf unserer Internet-Plattform bzw. ggf. per App unsere Leistungen nutzt, ändert sich an Deinen Verträgen nichts. Wir übernehmen nicht automatisch Deine Versicherungsverträge oder verändern diese.\n\n'},
{ type: 'textTitle', text: '2.3 Wir werden nur als Makler für Dich tätig, wenn Du uns ausdrücklich beauftragst. Soweit Du uns entsprechend beauftragt hast, können wir wie folgt tätig werden:\n\t Bestehende Verträge in unsere Internet-Plattform bzw. ggf. per App aufnehmen und Dir anzeigen,\n\t neue Verträge abschließen,\n\t bestehende Verträge ändern,\n\t Verträge kündigen.\n'},
{ type: 'textTitle', text: '2.4 Wir sind darum bemüht, Dir unsere Dienste jederzeit bestmöglich über unsere Internet-Plattform zugänglich zu machen. Wir schulden oder garantieren Dir aber nicht, dass Du unsere Internet-Plattform bzw. ggf. die App erreichen kannst.\n\n Wir haften nicht - außer im Fall eines vorsätzlichen oder grob fahrlässigen Handelns - für Ausfälle aus:\n\t technischen Gründen, z.B.:\n\t gestörte Hardware,\n \t fehlerhafte Software,\n\t Wartungs-Arbeiten.\n\n\t Gründen, die wir nicht beeinflussen können, z.B.:\n\t höhere Gewalt,\n \t Verschulden durch Dritte.\n\n Die vorstehende Haftungsbeschränkung gilt nicht für Schäden an Gesundheit und Leben, oder für typischerweise vorhersehbare Schäden, die aufgrund einer Verletzung einer wesentlichen Vertragspflicht entstehen (sog. Kardinalpflichten).\n\n'},
{ type: 'subTitle', text: '3. Bedingungen für die Nutzung der Internet-Plattform bzw. ggf. der App '},
{ type: 'textTitle', text: '3.1 Um unsere Dienste vollständig zu nutzen, musst Du Dich auf unserer Internet-Plattform bzw. ggf. der App registrieren. \n\n'},
{ type: 'textTitle', text: '3.2 Zur Nutzung und Registrierung sind nur folgende unbeschränkt geschäftsfähige Personen berechtigt:\n\t volljährige natürliche Personen,\n\t Personen-Gesellschaften,\n \t juristische Personen.\n Personen-Gesellschaften und juristische Personen müssen sich durch eine vertretungsberechtigte Person unter Angabe des Vor- und Zunamens registrieren.\n\n'},
{ type: 'textTitle', text: '3.3 Wenn Du unsere Dienste nutzt, bist Du verpflichtet alle Angaben, die für die Anmeldung und Nutzung unserer Dienste erforderlich sind vollständig und wahrheitsgemäß zu machen und alle Fragen vollständig und wahr zu beantworten. Daten musst Du ebenfalls vollständig und richtig angeben. Verstößt Du hiergegen, können wir die Zusammenarbeit jederzeit beenden und Deine Registrierung rückgängig machen.Im Hinblick auf die Verarbeitung von Daten gelten unsere Datenschutzerklärungen. Diese kannst Du unter www.diebayerische-versicherungsmakler.de einsehen und herunterladen. Du hast keinen Anspruch auf ein Nutzerkonto. Wir können Deinen Zugang übergangsweise sperren, sofern ein berechtigtes Interesse daran besteht und Deine Interessen angemessen berücksichtigt sind. Bei einer Sperrung informieren wir dich per E-Mail.\n\n'},
{ type: 'textTitle', text: '3.4 Durch die Nutzung unserer Dienste kommt zwischen uns ein Nutzungsvertrag zustande, dem diese AGB zu Grunde liegen. \n\n'},
{ type: 'textTitle', text: '3.5 Wenn Du unsere Internet-Plattform bzw. ggf. die App benutzt, kannst Du bestehende Verträge übersichtlich verwalten und mit unserer Unterstützung optimieren. \n\n'},
{ type: 'textTitle', text: '3.6 Die Nutzung unserer Internet-Plattform bzw. ggf. der App kann eine persönliche Beratung nicht unbedingt ersetzen. Daher bieten wir Dir auf Wunsch an, Dich individuell zu beraten. Der notwendige Kontakt hierfür kann über verschiedene Kanäle erfolgen. Wir beraten Dich entweder persönlich oder beauftragen damit einen kompetenten Dritten.\n\n'},
{ type: 'textTitle', text: '3.7 Du verpflichtest Dich, die angebotenen Dienste nur entsprechend deren Zweck zu verwenden.Du darfst die Software nicht in Deine eigenen Computer-Programme einbringen oder mit diesen\n\t zusammensetzen,\n\t verkaufen,\n\t vermieten,\n\t verleihen,\n\t vertreiben,\n\t unterlizenzieren,\n\t in weitere Programme integrieren,\n\t mit weiteren Programmen verknüpfen,\n\t für illegale Zwecke verwenden.\n'},
{ type: 'textTitle', text: '3.8 Wir sind jederzeit berechtigt:\n\t die Bereitstellung der Internet-Plattform bzw. ggf. der App einzustellen oder\n\t Dein Recht zur Nutzung dieser zu beenden.\n Dies gilt insbesondere dann, wenn Du gegen die Nutzungsbedingungen verstößt.\n\n'},
{ type: 'textTitle', text: '3.9 Du kannst jederzeit verlangen, dass wir Dein Nutzerkonto löschen. Im Hinblick auf die Speicherung von Daten über die Geschäftsbeziehung hinaus gelten unsere Datenschutzerklärungen. Diese kannst Du unter www.diebayerische-versicherungsmakler.de einsehen und herunterladen.\n\n'},
{ type: 'textTitle', text: '3.10 Du bist für das sichere Aufbewahren Deiner Zugangsdaten zuständig. Du musst die Zugangsdaten vertraulich behandeln und geheim halten, so dass Dritte keine Kenntnis von ihnen erlangen können. Besteht der Verdacht auf Missbrauch der Daten, bist Du verpflichtet, uns dies unverzüglich mitzuteilen. Am besten geht dies zum Beispiel per Mail an: versicherungsmakler@diebayerische.de. \n\n'},
{ type: 'textTitle', text: '3.11 Du musst entsprechende Vorkehrungen treffen, Deine auf unserer Internet-Plattform bzw. ggf. der App verwendeten Daten zu sichern. Gemeint sind alle Daten, die von Dir auf der Internet-Plattform:\n\t eingegebe\t hochgelade\t gespeicher\t anderweitig an uns übersandt od\t von uns an Dich versandt wurden.\n Diese Daten und Inhalte solltest Du regelmäßig und angemessen sichern und eigene Sicherungskopien erstellen. Dies dient dazu, dass Du bei Verlust von Daten und Informationen diese selbst wiederherstellen kannst.\n\n'},
{ type: 'textTitle', text: '3.12 Wir dürfen telefonische Gespräche mit Dir aufnehmen und speichern, wenn Du dazu ausdrücklich einwilligst. Dies erfolgt aufgrund gesetzlicher Pflichten und zum Zweck des gesetzlich erforderlichen Nachweises. Auf Nachfrage am Beginn des Telefonats kannst Du den Mitschnitt des Gesprächs explizit einwilligen. Unsere Mitarbeiter können die Gespräche nachträglich anhören. Wir sind berechtigt, Niederschriften der Aufzeichnungen anzufertigen. Sollte es zum Rechtsstreit kommen, können wir die Gespräche als Beweis verwenden, wenn Du den Mitschnitt nicht ausdrücklich abgelehnt hast.'},
{ type: 'subTitle', text: '4. Vollmacht: Was gilt für die Vollmacht?'},
{ type: 'textTitle', text: '4.0 Wenn wir Deine Verträge verwalten sollen, musst Du uns eine Vollmacht erteilen.Eine Vollmacht berechtigt uns unter anderem:\n\t Deine bestehenden Verträge bei den Versicherern einzusehen und\n\t die Daten Ihrer bestehenden Verträge in unser System zu übertragen.\n Wir übernehmen keine Gewähr dafür, dass die Daten, die wir von den Versicherern erhalten, vollständig oder richtig sind.Soweit Du uns mit der Vermittlung eines Darlehens beauftragt hast, berechtigt uns die Vollmacht Erklärungen gegenüber den Banken und Finanzdienstleistern abzugeben und entgegenzunehmen.\n\n'},
{ type: 'textTitle', text: '4.1 Du erteilest uns Deine Vollmacht auf zwei Wegen:\n\n 1.	Digitale Unterschrift\n\t Wir erfassen Deine Unterschrift digital und tragen sie in die Vollmacht ein. Wir können auch andere Verfahren verwenden, um Deine Unterschrift zu digitalisieren.\n 2.	Eigenhändige Unterschrift:\n\t Du unterschreibst die Vollmacht eigenhändig und sendest uns die unterschriebene Vollmacht per Post.\n'},
{ type: 'textTitle', text: '4.2 Wir speichern Deine Unterschrift in unserem System. Wir leiten die unterschriebene Vollmacht - falls erforderlich - an Versicherer oder andere berechtigte Dritte weiter.\n\n'},
{ type: 'textTitle', text: '4.3 Wir sind berechtigt, jederzeit und ohne Angabe von Gründen die beabsichtigte Bevollmächtigung zurückzuweisen.\n\n'},
{ type: 'textTitle', text: '4.4 Der Umfang der Vollmacht richtet sich nach Deinem jeweiligen Auftrag. Die Vollmacht erstreckt sich zum Beispiel auf:\n\t Beschaffung Deiner Daten,\n\t Betreuung und Verwaltung Deiner Verträge,\n\t Vermittlung von neuen Verträgen,\n\t Unterstützung beim Regulieren von Versicherungsschäden.\n'},
{ type: 'textTitle', text: '4.5 Du kannst die Vollmacht jederzeit widerrufen. Auch wir haben dieses Recht. Allerdings dürfen wir die Vollmacht in für Dich ungünstigen Zeiten nur widerrufen, wenn ein wichtiger Grund dafür vorliegt.'},
{ type: 'subTitle', text: '5. Vermittlung von Versicherungsverträgen: Wie vermitteln wir Dir eine passende Versicherung?'},
{ type: 'textTitle', text: '5.1 Du kannst uns beauftragen, Versicherungsverträge für Dich abzuschließen. Dann sind wir verpflichtet, unserem Vorschlag eine hinreichende Anzahl von auf dem Markt angebotenen Verträgen und Versicherern zugrunde zu legen. Darauf aufbauend geben wir Dir einen fachlichen Rat, welcher Vertrag geeignet erscheint, Deine Bedürfnisse zu erfüllen. Dies gilt nicht, wenn wir im Einzelfall vor Abgabe der Vertragserklärung auf eine begrenzte Auswahl von Unternehmen und Produkten hinweisen.\n\n'},
{ type: 'textTitle', text: '5.2 Wenn wir Dich auf eine begrenzte Auswahl hinweisen, teilen wir Dir mit, auf welcher Markt- und Informationsgrundlage unser Vorschlag erfolgt. Außerdem teilen wir Dir die Namen der zugrunde gelegten Unternehmen mit. Auf diese Mitteilungen und Angaben kannst Du durch eine gesonderte schriftliche Erklärung verzichten.\n\n'},
{ type: 'textTitle', text: '5.3 Wir vermitteln Dir nur Verträge, die Deinen individuellen Wünschen und Bedürfnissen entsprechen. Unsere Vorschläge basieren unter anderem auf diesen Kriterien:\n\t dem Verhältnis von Preis und Leistung,\n\t der Zuverlässigkeit beim Bearbeiten von Schäden und\n\t unserer Expertise.\n'},
{ type: 'textTitle', text: '5.4 Wir sind verpflichtet, die Beratung unter Berücksichtigung der Komplexität des angebotenen Versicherungsvertrags zu dokumentieren. Wir sind außerdem verpflichtet, bei der Vermittlung von Versicherungsanlageprodukten die besonderen Vorgaben der §§ 7a (Querverkäufe), 7b (Information bei Versicherungsanlageprodukten) und 7c VVG (Beurteilung von Versicherungsanlageprodukten; Berichtspflicht) zu beachten. Auf die Beratung und deren Dokumentation kannst Du verzichten.\n\n'},
{ type: 'textTitle', text: '5.5 Wir vermitteln Dir nur Verträge von Unternehmen:\n\t die unter Aufsicht der Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) stehen und\n\t deren Regelwerk in deutscher Sprache verfasst ist.\n Auch ausländische Versicherer sind in Deutschland tätig. Die Produkte dieser Unternehmen vermitteln wir nur, wenn deren Angebot und Zuverlässigkeit mit einem deutschen Unternehmen vergleichbar sind. Eine Pflicht hierzu besteht nicht.\n\nEinige Unternehmen, sogenannte Direktversicherer, vertreiben ihre Produkte nur selbst. Diese lassen den Abschluss von Verträgen durch Dritte nicht zu. Wir weisen darauf hin, dass wir diese Verträge nicht vermitteln können.\n\n'},
{ type: 'textTitle', text: '5.6 Du musst alle Angaben, die wir für eine vertragsgemäße Beratung und Verwaltung benötigen, wahrheitsgemäß und innerhalb der geforderten Frist übermitteln.\n\n'},
{ type: 'textTitle', text: '5.7 Wenn Du mitwirken musst, zum Beispiel einen Antrag unterschreiben, musst Du:\n \t alle geforderten Erklärungen,\n\t auf unsere Aufforderung oder auf Aufforderung des Versicherers\n\t ohne Verzug abgeben.\n'},
{ type: 'subTitle', text: '6. Kommunikationswege'},
{ type: 'textTitle', text: 'Die Kommunikation mit Dir erfolgt elektronisch (z.B. per E-Mail), soweit die Schriftform nicht zwingend gesetzlich vorgesehen ist.'},
{ type: 'subTitle', text: '7. Beraterhaftung: In welchen Fällen und in welcher Höhe haften wir bei der Versicherungsvermittlung?'},
{ type: 'textTitle', text: '7.1 Unsere Haftung ist mit Ausnahme der in den §§ 60, 61 VVG geregelten Beratungs- und Dokumentationspflichten wie folgt begrenzt: Wir haften im Rahmen der jeweils gesetzlich vereinbarten Mindestdeckungssummen. Wir beschränken unsere Haftung, weil in der genannten Höhe eine Vermögensschaden-Haftpflichtversicherung besteht.\n\n'},
{ type: 'textTitle', text: '7.2 Wir haften aber in folgendem Fall: wenn wir wesentliche Vertragspflichten infolge leichter Fahrlässigkeit verletzen. Wesentlich sind Vertragspflichten,\n\t deren Erfüllen die Durchführung des Vertrages erst ermöglichen und\n\t auf deren Erfüllen Du Dich deshalb regelmäßig verlassen darfst.\n Unsere Haftung ist aber wie folgt begrenzt: Wir haften höchstens bis zur Höhe des im Zeitpunkt des Vertragsschlusses typischen, vorhersehbaren Schadens. Wir haften nicht für ausbleibenden wirtschaftlichen Erfolg, entgangenen Gewinn und daraus entstandene mittelbare Schäden.\n\n'},
{ type: 'textTitle', text: '7.3 In unbegrenzter Höhe haften wir nur für Schäden:\n\t die sich aus den in den §§ 60, 61 VVG geregelten Beratungs- und Dokumentationspflichten ergeben,\n\t die wir vorsätzlich und grob fahrlässig herbeigeführt haben oder\n\t aus der Verletzung des Lebens, des Körpers und der Gesundheit.\n'},
{ type: 'textTitle', text: '7.4 Für Mängel oder Fehler an der Internet-Plattform bzw. ggf. der App haften wir nur, wenn wir diesen Mangel oder Fehler arglistig, vorsätzlich oder grob fahrlässig verschwiegen haben.\n\n'},
{ type: 'textTitle', text: '7.5 Wir haften nicht für Störungen, die wir nicht beeinflussen können. Dazu gehören zum Beispiel:\n\t überlastete Leitungen oder \n\t Ausfall von Telekommunikations-Verbindungen.\n'},
{ type: 'textTitle', text: '7.6 Die hier dargestellten Begrenzungen unserer Haftung gelten ebenso für:\n\tunsere Mitarbeiter und Erfüllungsgehilfen und\n\tsonstige Dritte, die uns helfen, unsere Pflichten zu erfüllen.\n'},
{ type: 'subTitle', text: '8. Schlussbestimmungen'},
{ type: 'textTitle', text: '8.1 Wenn eine Regelung dieser AGB unwirksam sein sollte, bleiben alle weiteren Abschnitte wirksam. Auch bereits bestehende Verträge bleiben unberührt. Die unwirksame Regelung entfällt ersatzlos, an ihre Stelle tritt die gesetzliche Regelung hierzu. Stellt sich heraus, dass in den AGB Regelungslücken bestehen, bleiben alle weiteren Abschnitte wirksam. Auch die bestehenden Verträge bleiben unberührt.\n\n'},
{ type: 'textTitle', text: '8.2 Wenn wir diese AGB ändern, geschieht dies - sofern nicht lediglich rechtlich vorteilhaft für Dich - wie folgt: Bevor die angepassten AGB gültig werden, senden wir Dir die geänderten Abschnitte oder AGB in Textform zu. Wir weisen Dich gesondert auf die neuen Passagen und das Datum des geplanten Inkrafttretens hin. Außerdem räumen wir Dir eine sechswöchige Frist zum Widerspruch ein. Diese Frist beginnt, wenn Du unsere Nachricht in Textform erhalten hast. Du hast dann sechs Wochen Zeit Widerspruch einzulegen. Wenn wir innerhalb dieser Frist keinen Widerspruch von Dir erhalten, gelten die geänderten AGB als vereinbart. Wir weisen Dich bei Fristbeginn gesondert auf die Folgen Deines Schweigens hin.\n\n Im Falle eines Widerspruches wird diese Nutzungsvereinbarung zu den bestehenden Bedingungen fortgesetzt. Uns bleibt es in diesem Fall jedoch unbenommen, diese Vereinbarung zur Nutzung unserer Internet-Plattform bzw. ggf. der App außerordentlich zu kündigen, wobei ein wichtiger Grund zur Kündigung insbesondere dann anzunehmen ist, wenn die Fortsetzung für uns unzumutbar ist. \n\n'},
{ type: 'textTitle', text: '8.3 Es gilt das Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht sowie alle internationalen Kollisionsnormen sind ausgeschlossen.\n\n'},
{ type: 'textTitle', text: 'Stand: 01.04.2022'},
];

export default terms;




