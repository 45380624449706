/**
 * Created by max on 2/16/22.
 */

const de_DE = {
    testing: {
        tHTML: '{b}boldText{/b}{u}underlineText{/u}',
    },
    errors: {
        customerNotExisted: 'Die Kundendaten sind nicht vorhanden, bitte führe den Onboarding-Prozess erneut durch',
        firstSignIsEmpty: 'Erstes Zeichen ist leer, bitte starten Sie den Vorgang von vorne',
        secondSignIsEmpty: 'Zweites Zeichen ist leer, bitte starten Sie den Vorgang von vorne',
    },
    serverErrorIds: {
        email_is_already_confirmed: 'Es besteht bereits ein Account für diese E-Mail Adresse. Bitte gehe zum Login und logge Dich damit ein.',
        otp_is_already_sent: 'OTP-Code wurde bereits gesendet',
        phone_is_not_confirmed: 'Telefon ist nicht bestätigt',
        email_is_not_confirmed: 'E-Mail ist nicht bestätigt',
        credentials_not_right: 'Anmeldedaten stimmen nicht',
        unexpected_error: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
    },
    codeResponsesAPI: {
        statusTimeout: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
        statusNotAuthorize: 'Bitte log Dich erneut ein. Deine aktive Session ist abgelaufen.',
        statusBadRequest: 'Ein unbekannter Fehler ist aufgetreten. Wir haben bereits unsere Technik informiert und beheben den Fehler zeitnah.',
        statusInternalServerError: 'Leider ist ein Fehler bei der Datenübermittlung aufgetreten. Wir haben bereits den Fehler gemeldet. Bitte versuche es später noch einmal.',
    },
    common: {
        bottomImpressum: 'Impressum',
        bottomInfo: 'Erstinformation',
        bottomPrivacy: 'Datenschutz',
        expertTitle: 'DER EXPERTE',
        textSearch: 'Suchen',
        dropzoneTitle: 'Vertragsdokumente hinzufügen (optional)',
        dropzoneText: 'Dokumente hier ablegen/hochladen oder Foto Aufnehmen',
        dropzoneBtn: 'Datei auswählen',
        ok: 'Ok',
    },
    elements: {
        providerTitle: 'ANBIETER',
        yes: 'Ja',
        no: 'Nein',
        youtubeText: 'Verträge importieren - was steckt dahinter?',
        textAddProvider: 'Logo\nfolgt',
        popupYoutubeClose: 'Verstanden',
        searchPlaceholder: 'Suchen',
        asc: 'Product: Asc',
        desc: 'Product: Desc',
    },
    finApi: {
        noAccountIds: 'There is an error - no accounts ids',
        noBankConnections: 'No bank connections',
        noDataSourceIds: 'There is an error - no datasource ids',
        noContracts: 'There is an error - no contracts object in response',
    },
    validations: {
        phoneNotOwnPhone: 'Du kannst Dir selbst keine Einladung senden.',
        validationPhoneNumber: "Bitte gebe eine gültige Telefonnummer ein",
        validationEmail: "Bitte gebe eine gültige E-Mail Adresse ein.",
        validationName: "Bitte gebe Deinen Namen ein.",
        validationProviderEmpty: "Bitte wähle zuerst Deinen Anbieter.",
        validationCustomProviderEmpty: "Bitte wähle zuerst Anbieter manuell Eintragen.",
        validationSelectAllContracts: "Wähle zuerst einen Vertrag, um den Maklerservice aktivieren zu können.",
        validationAddSignature: "Bitte unterschreibe zuerst, um fortzufahren.",
        validationAppoitmentDate: "Du kannst kein vergangenes Datum auswählen. Versuche es erneut.",
        validationJob: "Hier sind nur Buchstaben erlaubt.",
        validationNationality: "Bitte hier nur Buchstaben eingeben. Dann werden Dir Nationalitäten zur Auswahl gestellt.",
        validationBDEmpty: "Bitte gib Dein Geburtsdatum ein.",
        validationBD18: "Du musst mindestens 18 Jahre alt sein, um unseren Maklerauftrag nutzen zu können.",
        validationBD90: "Das zulässige höchstalter ist 90 Jahre.",
        validationEmailEmpty: "Die E-Mail Adresse ist ein Pflichtfeld.",
        validationEmailNotValid: "Bitte gib eine gültige E-Mail Adresse ein.",
        validationPhoneNotValid: "Bitte gib eine gültige Telefonnummer ein.",
        validationPhoneNotZeroStart: 'Deine Mobilfunknummer kann nicht mit 0 beginnen, da Du bereits die Ländervorwahl nutzt. Lösche bitte die 0.',
        validationPhoneStart7: "Deine Mobilnummer muss mit 7 beginnen.",
        validationPhoneLength9: "Deine Mobilnummer muss genau 9 Ziffern beinhalten.",
        validationPhoneStart1: "Deine Mobilfunknummer muss mit 1 beginnen.",
        validationPhoneLength5: "Deine Mobilfunknummer muss mindestens 8 Ziffern beinhalten.",
        validationPhoneStart6: "Deine Mobilfunknummer muss mit 6 beginnen.",
        validationPhoneLength12: "Deine Mobilfunknummer darf maximal 12 Ziffern beinhalten.",
        validationPhoneStart3: "Deine Mobilfunknummer muss mit 3 beginnen.",
        validationPhoneLength10: "Deine Mobilnummer muss genau 10 Ziffern beinhalten.",
        validationPhoneLength8: "Deine Mobilfunknummer muss genau 8 Ziffern beinhalten.",
        validationPasswordEmpty: "Dein Passwort ist eine Pflichtangabe.",
        validationPasswordNotValid: "Dein Passwort muss mindestens 8 Zeichen, 1 Grossbuchstaben, 1 Kleinbuchstaben, 1 Zahl oder ein Sonderzeichen haben.",
        validationPasswordConfirmEmpty: "Passwort wiederholen ist eine Pflichtangabe.",
        validationPasswordConfirmNotValid: "Beide Passwörter müssen identisch sein.",
        validationFreebeeCodeLength10: "Dein Freischalt-Code muss 10 Zeichen beinhalten.",
        validationIBANNotValid: "Bitte gib eine gültige IBAN Nummer eingeben.",
        validationFirstNameEmpty: "Dein Vorname ist eine Pflichtangabe.",
        validationFirstNameLength: "Dein Name muss länger als 1 Buchstabe sein.",
        validationFirstNameNotNumbers: "Dein Name darf keine Nummer beinhalten.",
        validationLastNameEmpty: "Bitte gib Deinen Nachnamen ein.",
        validationLastNameLength: "Dein Name muss länger als 1 Buchstabe sein.",
        validationLastNameNotNumbers: "Ein Name darf nicht mit einer Nummer beginnen.",
        validationPostcodeLength4: "Die Postleitzahl muss 4 Ziffern beinhalten.",
        validationPostcodeLength5: "Die Postleitzahl muss 5 Ziffern beinhalten.",
        validationPriceEmpty: "Beitrag ist ein Pflichtfeld.",
        validationPriceNotValid: "Bitte einen gültigen Beitrag bzw. eine Rate eingeben. Der Beitrag kann nur eine Zahl sein mit Angabe von Komma.",
        validationYearlySpendingEmpty: "Verbrauch im letzten Jahr ist ein Pflichtfeld.",
        validationYearlySpendingNotValid: "Bitte einen gültigen 'Verbrauch im letzten Jahr' bzw. eine Rate eingeben. Der 'Verbrauch im letzten Jahr' kann nur eine Zahl sein mit Angabe von Komma.",
        validationYearlySpendingCostEmpty: "Kosten im letzten Jahr ist ein Pflichtfeld.",
        validationYearlySpendingCostNotValid: "Bitte einen gültigen 'Kosten im letzten Jahr' bzw. eine Rate eingeben. Der 'Kosten im letzten Jahr' kann nur eine Zahl sein mit Angabe von Komma.",
        validationStartDate: "Der Vertragsbeginn darf nicht in der Zukunft liegen.",
        validationStartDateEmpty: "Der Vertragsbeginn darf nicht leer sein",
        validationEndDate: "Das Vertragsende darf nicht in der Vergangenheit liegen.",
        validationPaymentMethodRequired: "Die Zahlungsart ist ein Pflichtfeld.",
        validationAddressEmpty: "Strasse und Hausnummer ist ein Pflichtfeld.",
        validationAddressWithoutNumberEmpty: "Strasse ist ein Pflichtfeld.",
        validationHouseNumberEmpty: "Hausnummer ist ein Pflichtfeld.",
        validationPostcodeEmpty: "Postleitzahl ist ein Pflichtfeld.",
        validationCityEmpty: "Ort ist ein Pflichtfeld.",
        validationPhoneEmpty: "Mobilfunknummer ist ein Pflichtfeld.",
        validationCityNotNumbers: "Ein Ort darf nicht mit einer Nummer beginnen.",
        validationAddressContainNumbers: "Bitte gib eine aktuelle Strasse und Hausnummer ein.",
        validationHouseNumberContainNumbers: "Bitte gib eine aktuelle Hausnummer ein.",
        validationErrorPhoneUserExisted: "Ein Account mit der von dir angegebenen Mobilfunknummer existiert bereits. Gehe zurück zum Login und melde Dich mit deinem Account an.",
        validationPaypalEmailEmpty: 'Die Paypal E-Mail Adresse ist ein Pflichtfeld.',
        validationPaypalEmailNotValid: 'Bitte eine gültige Paypal E-Mail Adresse eingeben.',
        validationNumberEmpty: "Vertragsnummer ist ein Pflichtfeld.",
        validationSalutationEmpty: "Anrede ist ein Pflichtfeld.",
    },
    header: {
        menuTopChat: 'Chat',
        menuTopDashboard: 'Dashboard',
        menuTopContracts: 'Vertragsordner',
        menuTopProfile: 'Profil',
    },
    slideMenu: {
        profile: 'Profil',
        impressum: 'Impressum',
        logout: 'Ausloggen',
    },
    //-----
    //LISTS
    salutation: { mr: 'Herr', mrs: 'Frau' },
    //-----
    //-----
    //PAGES
    //-----
    termsPage: {
        h1: 'In wenigen Schritten zu Deinem kostenfreien digitalen Vertragsordner.',
        title1: 'VERTRÄGE HINZUFÜGEN',
        text1: 'Füge im nächsten Schritt Deine Verträge hinzu.',
        title2: 'MAKLERSERVICE AKTIVIEREN',
        text2: 'Aktiviere den Maklerservice für Deine Verträge und beauftrage uns als Deinen zukünftigen Finanzberater. Dadurch übernehmen wir für Dich den Support Deiner Verträge.',
        title3: 'ACCOUNT AKTIVIEREN',
        text3: 'Aktiviere Deinen kostenfreien Zugang. So hast Du jederzeit online Zugriff auf Deinen Vertragsordner.',
        checkboxText1: 'Ich akzeptiere die',
        checkboxLink1: 'AGB',
        checkboxText2: 'Ich akzeptiere die',
        checkboxLink2: 'Datenschutzrichtlinien',
        checkboxText3: 'Ich akzeptiere die',
        checkboxLink3: 'Erstinformation',
        btnNext: 'Weiter',
    },
    termsPopup: {
        termsLink: '01 Allgemeine Geschäftsbedingungen (AGB)',
        privacyLink: '02 Datenschutzrichtlinien',
        infoLink: '03 Erstinformation',
        btnAgree: 'Schließen',
    },
    onboardingContractAddPage: {
        h1: 'Wie möchtest Du Deine Verträge importieren?',
        expertMsg: 'Wir möchten Dir die Erfassung Deiner Verträge so einfach wie möglich machen. Du kannst Deine Verträge neben der manuellen Eingabe auch automatisch importieren lassen.',
        finapiExplanation: '(Dieser Service wird von unserem Partner fin API bereitgestellt. Starte das Video für mehr Informationen.)',
        finapiError: 'Deine Anfrage konnte nicht übermittelt werden. Bitte klicke erneut auf den Button. Besteht der Fehler weiterhin, bitten wir Dich Deine Verträge manuell zu erfassen.',
        btnFinApi: 'AUS BANKUMSÄTZEN IMPORTIEREN - in 30 Sekunden',
        btnManual: 'Vertragsdaten selbst erfassen - IN 5 Minuten',
        youtubeTitle: 'Starte das Video, um mehr zu erfahren.',
    },
    onboardingContractListPage: {
        expertMsg: 'In der Liste findest Du die häufigsten Versicherungen. Wähle alle aus, die Du hast, damit sie Dir in Deinem digitalen Versicherungsordner angezeigt werden. Du kannst auch später in Deinem Account weitere Verträge hinzufügen.',
        expertMsgFinapi: 'In der Übersicht findest Du die Verträge, die wir aus Deinen Bankumsätzen identifizieren konnten. Bitte prüfe die Informationen und korrigiere diese ggf. Wähle im Anschluss die Verträge aus, für die Du uns als Finanzberater aktivieren möchtest. Du kannst später in Deinem Account weitere Verträge hinzufügen.',
        btnShowListProducts: 'Weitere Versicherungen anzeigen',
        btnEditLink: 'Korrigieren',
        btnEditRedLink: 'Korrigieren - Informationen fehlen',
        textSelectBroker: 'Maklerservice gewünscht',
        successAddedMsg: 'Sehr gut, Deine Versicherung wurde hinzugefügt! Welche Versicherungen hast Du noch?',
        errorNotSelected: 'Wähle bitte "Maklerservice gewünscht" für mindestens einen Vertrag.',
        btnNext: 'Weiter',
        errorNoProductInList: 'Es gibt kein Produkt in der Produktliste des Servers.',
        btnDeleteLink: 'Löschen',
        btnUnDeleteLink: 'Vertrag hinzufügen',
        contractNumber: 'Vertragsnr.',
        closure_of_insurance: 'Betriebschließung',
        public_liability_insurance: 'Betriebshaftpflicht',
        betriebsunterbrechungsversicherung: 'Betriebsunterbrechung',
        cyber: 'Cyberversicherung',
        robbery_insurance: 'Einbruch-Diebstahl-Raubversicherung',
        business_car_insurance: 'Gewerbliche PKW Versicherung',
        business_law_insurance: 'Gewerbliche Rechtsschutzversicherung',
        fidelity_insurance: 'Kautionsversicherung',
        landwirtschaftliche_feuerversicherung: 'Landwirtschaftliche Feuer',
        truck_insurance: 'LKW Versicherung',
        machine_insurance: 'Maschinenversicherung',
        property_loss_insurance: 'Vermögensschadenversicherung',
    },
    onboardingProductsPage: {
        h1: 'Produktart auswählen',
        btnNext: 'Weiter',
        selectedProductText: 'Ausgewählte Produktart',
    },
    onboardingProvidersPage: {
        productTitle: 'Anbieter wählen für',
        customProvider: 'Mein Anbieter fehlt in der liste',
        noProviders: 'Für die gewählte Vertragsart konnten wir keinen Anbieter finden. Bitte trage daher den Namen Deines Anbieters im unteren Feld ein.',
        btnNext: 'Weiter',
        selectedProviderText: 'Ausgewählte Anbieter',
        providerIsNotRelated: 'Du hast die Produktart gewechselt. Der zuvor ausgewählte Anbieter scheint nicht mehr richtig zu sein. Wähle bitte den passenden Anbieter aus.',
    },
    onboardingContractEditPage: {
        expertMsg: 'Vervollständige Deine Angaben.',
        number: 'Vertragsnummer',
        btnNext: 'Speichern',
    },
    onboardingContractListFinishPage: {
        h1: 'Starte das Video und erfahre mehr über Deine Vorteile mit unserem Maklerauftrag.',
        expertMsg: 'Unterzeichne im nächsten Schritt den Maklerauftrag, um den Maklerservice zu aktivieren.',
        headerText1: 'Der Maklerauftrag ist 100 % kostenfrei.',
        headerText2: 'Du kannst den Maklerauftrag jederzeit widerrufen.',
        headerText3: 'Deine Unterschrift ist rechtsgültig und wird von mehr als 140 Versicherern anerkannt.',
        successMsg: 'Sehr gut, Deine Verträge wurden hinzugefügt!',
        btnNext: 'Weiter',
    },
    onboardingUserDataPage: {
        expertMsg: 'Bitte ergänze Deine persönlichen Angaben. Deine Daten sind bei uns jederzeit sicher und werden ausschließlich nach gesetzlichen Vorgaben verarbeitet.',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        bd: 'Geburtsdatum',
        address: 'Strasse und Hausnummer',
        postcode: 'PLZ',
        city: 'Ort',
        btnNext: 'Weiter',
        email: 'E-mail adresse',
        emailDesc: 'An diese E-Mail Adresse bekommst Du eine Bestätigungsmail. Wir senden keine Newsletter.',
        phoneCode: 'Vorwahl',
        phoneNumber: 'Mobilnummer',
        phoneNumberDesc: 'Mit dieser Mobilnummer kannst Du Dich zukünftig in Deinem Account einloggen.',
        addressCompany: 'Strasse und Hausnummer',
        postcodeCompany: 'PLZ',
        cityCompany: 'Ort',
        h1: 'Angaben gesetzliche Vertretung',
        companyName: 'Firmenname',
        position: 'Position',
    },
    onboardingSignBrokerPage: {
        h1: 'Maklerauftrag unterschreiben - 100% kostenfrei.',
        expertMsg: 'Du beauftragst uns als Deinen Finanzberater für Deine gewählten Verträge. Unser Service ist für Dich dauerhaft kostenfrei und kann jederzeit widerrufen werden. ',
        textInfo: 'Diese Einwilligung kann jederzeit ohne Angabe von Gründen – jedoch nur für die Zukunft – widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt davon unberührt. Die Rechte auf Auskunft, Berichtigung, Löschung oder Sperrung der personenbezogenen Daten können jederzeit gegenüber dem Makler sowie den zuvor genannten Stellen geltend gemacht werden.',
        textInfo1: '"Ich bestätige, dass ich die ',
        textInfo2: ', den ',
        linkInfo1: 'Erstinformation',
        linkInfo2: 'Maklerauftrag',
        textInfo3: ' einschl. der Information gem. § 60 Abs. 1 Satz 2 und Abs. 2 VVG bzgl. der eingeschränkten Versicherer- und Vertragsauswahl heruntergeladen und gelesen habe und akzeptiere diese mit meiner Unterschrift."',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        text1: 'Deine Unterschrift ist {b}rechtsgültig{/b} und wird von mehr als 140 Versicherern anerkannt.',
        text2: 'Der Maklerauftrag ist {b}100 % kostenfrei.{/b}',
        text3: 'Du kannst den Maklerauftrag {b}jederzeit widerrufen.{/b}',
        btnNext: 'Weiter',
        agreeText: 'Ich möchte die Bayerische Versicherungsmakler GmbH zukünftig als Finanzberater für meine gewählten Verträge nutzen und ',
        agreeTextLink: 'habe den Maklerauftrag gelesen.',
        healthText: 'Einwilligung in die Verarbeitung von Gesundheitsdaten',
        marketingText: 'Ich willige mit meiner folgenden Unterschrift in die Erhebung, Verarbeitung und Nutzung meiner personenbezogenen Daten (per E-Mail, Fax, Telefon und SMS) zu meiner Information ein, wie im Maklerauftrag unter Punkt 12 beschrieben.',
        companyText: 'Diese Einwilligung kann jederzeit ohne Angabe von Gründen – jedoch nur für die Zukunft – widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt davon unberührt. Die Rechte auf Auskunft, Berichtigung, Löschung oder Sperrung der personenbezogenen Daten können jederzeit gegenüber dem Makler sowie den zuvor genannten Stellen geltend gemacht werden.',
    },
    onboardingSignPrivacyPage: {
        h1: 'Datenschutzerklärung unterschreiben.',
        expertMsg: 'Die Sicherheit Deiner Daten steht bei uns an erster Stelle. Wir verarbeiten Deine Daten nach ISO zertifiziertem Sicherheitsstandard. All Deine Daten, die wir über Dich speichern kannst Du jederzeit bei uns abfragen.',
        linkText: 'Datenschutzerklärung lesen',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        text1: 'Die Sicherheit Deiner Daten steht bei uns an erster Stelle. Wir verarbeiten Deine Daten nach ISO zertifiziertem Sicherheitsstandard. All Deine Daten, die wir über Dich speichern kannst Du jederzeit bei uns abfragen.',
        text2: 'Diese Einwilligung kann jederzeit ohne Angabe von Gründen – jedoch nur für die Zukunft – widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung bleibt davon unberührt. Die Rechte auf Auskunft, Berichtigung, Löschung oder Sperrung der personenbezogenen Daten können jederzeit gegenüber dem Makler sowie den zuvor genannten Stellen geltend gemacht werden.',
        btnNext: 'Weiter',
        contactText: 'Kontaktaufnahme per E-Mail, Telefon, Fax, Messenger-Dienste, Social Media und SMS zu Werbe- und Marketingzwecken',
        newServiceText: 'Vorstellung weiterer Produkte und/oder Dienstleistungen per E-Mail, Telefon, Fax, Messenger-Dienste, Social Media und SMS',
        marketingText: 'Datenauswertung zu Marketingzwecken',
        youtubeTitle: 'Starte das Video und erfahre mehr über Deine Vorteile mit unserem Maklerauftrag.',
    },
    onboardingBrokerSuccessPage: {
        expertTitle: 'DER EXPERTE',
        expertMsg: 'Sehr gut, Dein Maklerauftrag und Maklerservice wurden aktiviert! Dein Maklerauftrag und Deine Verträge findest Du zukünftig in Deinem Account. Lass uns gemeinsam Deinen Zugang aktivieren. Dadurch kannst Du jederzeit auf Deinen digitalen Vertragsordner zugreifen und wir helfen Dir bei Fragen.',
        expertMsgCompany: 'Sehr gut, Dein Maklerauftrag und Maklerservice wurden aktiviert! Dein Maklerauftrag und Deine Verträge findest Du zukünftig in Deinem Account. Lass uns gemeinsam Deinen Zugang aktivieren. Dadurch kannst Du jederzeit auf Deinen digitalen Vertragsordner zugreifen und wir helfen Dir bei Fragen.',
        btnTitle: 'Jetzt Account aktivieren',
        successMsg: 'Hervorragend! Dein Maklerauftrag und Maklerservice wurden aktiviert.',
    },
    onboardingFinAPIImportPage: {
        h1Complete: 'Bitte warten - Deine Verträge werden ermittelt!',
        textComplete: 'Danke für Dein Vertrauen. Aktuell analysiert unsere künstliche Intelligenz Deine Daten und ermittelt Deine bestehenden Verträge für Dich. Die Analyse kann je nach Umfang der Informationen 0,5 bis 1 Minute in Anspruch nehmen. Du wirst im Anschluss automatisch weitergeleitet.',
        btnNext: 'Weiter',
    },
    onboardingFinAPIErrorPage: {
        expertTitle: 'Vertragserkennung aktuell nicht möglich.',
        expertMsg: 'Es ist ein interner Fehler aufgetreten. Wir arbeiten bereits an einer Lösung. Alternativ kannst Du Deine Vertragsdaten selbst erfassen.',
        btnTitle: 'Vertragsdaten selbst erfassen',
    },
    onboardingFinAPIRestartPage: {
        expertTitle: 'Die Sitzung ist abgelaufen.',
        expertMsg: 'Deine Sitzung ist leider abgelaufen. Lade die Seite neu und starte von vorne, um Deine Vertragsdaten zu importieren.',
        btnTitle: 'Seite neu laden',
    },
    signupStepOnePage: {
        h1: 'Prüfe Deine Angaben.',
        phoneCode: 'Vorwahl',
        phoneNumber: 'Mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        email: 'E-mail adresse',
        emailDesc: 'Über die E-Mail Adresse kannst Du Deinen Account final freischalten und Dein Passwort wählen.',
        expertMsg: 'Dein Maklerauftrag und Deine Verträge findest Du zukünftig in Deinem Account. Lass uns gemeinsam Deinen Zugang aktivieren. Überprüfe hierzu unten Deine Angaben.',
        simpleTextBottomLiFirst: 'Jederzeit Zugriff auf Deine aktuellen Vertragsdaten.',
        simpleTextBottomLiSecond: 'Hilfe im Schadensfall.',
        btnNext: 'Weiter',
    },
    signupStepTwoPage: {
        h1: 'Mobilnummer verifizieren.',
        expertMsg: 'Ich habe Dir einen Code per SMS gesendet. Trage ihn bitte unten in das Feld ein. Die Zustellung einer SMS kann je nach Anbieter bis zu 3 Minuten dauern.',
        btnNext: 'Weiter',
        prefixBeforeTimerSmsVerif: 'DER SMS CODE LÄUFT AB IN:',
        textAfterTimerSmsVerif: 'MINUTEN',
        isExpiredText: 'Dein SMS Code ist abgelaufen. Bitte fordere einen neuen Code an oder überprüfe Deine Mobilnummer',
        btnSendAgain: 'SMS Code erneut anfordern',
    },
    signupStepThreePage: {
        h1: 'E-Mail Adresse verifizieren.',
        expertMsg: 'Ich habe Dir eine E-Mail gesendet! Bitte klicke den Link in der E-Mail, um ein Passwort für Deinen Account zu wählen.',
        titleLinkEmail: 'KLICKE AUF DEN LINK IN DER E-MAIL',
        descLinkEmail: 'Gehe bitte in Dein E-Mail Postfach. Öffne die E-Mail und klicke auf den Link.',
        titleEmailLetter: 'KEINE E-MAIL ERHALTEN?',
        descEmailLetterPrivate: 'Überprüfe bitte Deinen Spam Ordner, falls Du keine E-Mail erhalten hast.',
        descEmailLetter: 'Überprüfe bitte Deinen Spam Ordner, falls Du keine E-Mail erhalten hast. Falls dort ebenfalls keine E-Mail ist, ',
        descEmailLink: 'klicke bitte hier.',
        titleCreatePassw: 'ERSTELLE EIN PASSWORT',
        descCreatePassw: 'Nachdem Du auf den Link geklickt hast, wirst Du auf eine Seite weitergeleitet. Dort kannst Du nun ein Passwort für Deinen Account festlegen.',
        titleViewContract: 'DEINE VERTRAGSÜBERSICHT',
        descViewContract: 'Logge Dich mit Deiner E-Mail Adresse und Deinem Passwort ein, um Deine Vertragsübersicht zu sehen.',
        btnNext: 'Ich habe keine E-Mail erhalten',
        confirmationIsSent: 'Die Bestätigungs-E-Mail wurde erfolgreich gesendet.',
    },
    signupStepFourPage: {
        h1: 'Lege Dein gewünschtes Passwort fest.',
        successMsg: 'Deine E-Mail Adresse wurde verifiziert!',
        password: 'Passwort',
        rePassword: 'Passwort wiederholen',
        phoneNumber: 'Mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        email: 'E-Mail Adresse',
        btnNext: 'Account aktivieren',
        emailNotConfirmed: 'Es besteht bereits ein Account für diese E-Mail Adresse. Bitte gehe zum Login und logge Dich damit ein.',
    },
    signinPage: {
        h1: 'Einloggen',
        successMsg: 'Super Dein Account wurde aktiviert. Du kannst Dich einloggen.',
        password: 'Passwort',
        phoneCode: 'Vorwahl',
        phoneNumber: 'Mobilnummer',
        phoneNumberDesc: 'Du erhältst im nächsten Schritt einen SMS Code.',
        buttomLink: 'Passwort vergessen',
        email: 'E-Mail Adresse',
        btnNext: 'Einloggen',
    },
    secureDashboardPage: {
        titleHeader: 'Hallo {name}! Hier ist Deine Übersicht',
        tickets: 'Anfragen',
        contracts: 'Verträge',
        payments: 'Gesamt-ausgaben',
        qrText: 'Freunde einladen',
        titleTicket: 'Anfragen',
        btnTitle: 'neue Anfrage',
        textTicket: 'Neue Anfragen werden in wenigen Minuten angezeigt.....',
    },
    secureContractsListPage: {
        h1: 'Vertragsübersicht',
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractDocuments: 'Dokumente',
        contractTariffs: 'TARIFBAUSTEINE',
        brokerStatus_active_with_payment: 'Makler aktiv',
        brokerStatus_active_without_fee: 'Makler nicht möglich',
        brokerStatus_check_broker: 'Makler in Bearbeitung',
        brokerStatus_deactivation_broker: 'Makler aktivieren',
        brokerStatus_on_work: 'Makler in Bearbeitung',
        brokerStatus_open: 'Makler aktivieren',
        brokerStatus_post_processing: 'Makler in Bearbeitung',
        brokerStatus_reject: 'Makler nicht möglich',
        btnNewContract: 'Verträge hinzufügen',
        btnAddDocuments: 'hinzufügen',
        contractPopupTitle: 'Was bedeutet der Status: Vertragsimport nicht möglich?',
        contractPopupLeftBtnTitle: 'Abbrechen',
        contractPopupRightBtnTitle: 'Vergleichsangebot anfordern',
        contractPopupText1: 'In diesem Fall unterstützt Dein Anbieter den Vertragsimport nicht und wir können für Dich Deine Vertragsdetails leider nicht bereitstellen.',
        contractPopupText2: 'Tipp: Gerne finden wir für Dich einen alternativen Vertrag zum gleichen oder günstigeren Preis inklusive Maklerservice und Vertragsimport. Unser Angebot ist kostenlos und unverbindlich.',
    },
    secureContractDetailPage: {
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractMaklerservice: 'Maklerservice',
        btnDocuments: 'Dokumente ({numberDocuments})',
        btnSupport: 'Support',
        btnCheckContract: 'Vertrag überprüfen lassen',
        titleDetail: 'Vertragsdetails',
        contractOwner: 'Vertragsinhaber',
        contractTariff: 'Tarifbezeichnung',
        titlePrice: 'Beitrag',
        contractPaymentMethod: 'Zahlungsmethode',
        contractPrice: 'Beitrag bzw. Rate',
        titleDates: 'Laufzeit',
        contractStartDate: 'Beginn',
        contractEndDate: 'Ende',
        fieldNotes: 'Notizen',
        btnDelete: 'Vertrag löschen',
        contractUpdatedText: 'Der Vertrag wird aktualisiert',
        btnSave: 'Speichern',
        brokerStatus_active_with_payment: 'Maklerservice aktiv',
        brokerStatus_active_without_fee: 'Maklerservice aktiv',
        brokerStatus_check_broker: 'Maklerservice aktivieren',
        brokerStatus_deactivation_broker: 'Vertragsdaten werden importiert',
        brokerStatus_on_work: 'Maklerservice aktivieren',
        brokerStatus_open: 'Vertragsdaten werden importiert',
        brokerStatus_post_processing: 'Maklerservice aktivieren',
        brokerStatus_reject: 'Vertragsimport nicht möglich',
    },
    secureContractDocumentsPage: {
        contractNumber: 'Vertragsnr',
        contractYearlyCost: 'Kosten jährlich',
        contractStatus: 'Vertragsstatus',
        contractMaklerservice: 'Maklerservice',
        btnAddDocument: 'Dokumente hinzufügen',
        brokerStatus_active_with_payment: 'Maklerservice aktiv',
        brokerStatus_active_without_fee: 'Maklerservice aktiv',
        brokerStatus_check_broker: 'Maklerservice aktivieren',
        brokerStatus_deactivation_broker: 'Vertragsdaten werden importiert',
        brokerStatus_on_work: 'Maklerservice aktivieren',
        brokerStatus_open: 'Vertragsdaten werden importiert',
        brokerStatus_post_processing: 'Maklerservice aktivieren',
        brokerStatus_reject: 'Vertragsimport nicht möglich',
        documentAdded : 'Dokument wurde hinzugefügt',
        documentDelete: 'Dokument wurde gelöscht',
        h4: 'Vertragsdokumente',
    },
    secureCustomerProfilePage: {
        h1: 'Profil',
        h1Company: 'Unternehmensprofil',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        birthdate: 'Geburstdatum',
        titleCommunication: 'Kommunikation',
        email: 'E-mail adresse',
        emailNotConfirmed: 'Nicht verifiziert',
        emailNotConfirmedLink: 'Jetzt Verifizierungslink anfordern',
        phoneCode: 'Vorwahl',
        phoneNumber: 'Mobilnummer',
        titleAddress: 'Anschrift',
        street: 'Straße',
        postcode: 'Postleitzahl',
        city: 'Ort',
        titleBroker: 'Maklerauftrag',
        contactPerson: 'Angaben gesetzlicher Vertreter',
        companyName: 'firmenname',
        dateActiveBroker: 'aktiv seit',
        btnShowBrokerPdf: 'Maklerauftrag lesen & herunterladen',
        btnShowPrivacyPdf: 'Maklervollmacht herunterladen',
        btnCancelBroker: 'Maklerauftrag kündigen',
    },
    referralShareQR: {
        h2: 'Freunde einladen',
        h3ImageQR: 'Dein persönlicher QR Code',
        h3ClipBoard: 'Dein persönlicher Einladungslink',
        textDescroption: 'Teile Deine Begeisterung mit Anderen. Du kannst Deinen persönlichen QR Code herunterladen und teilen oder Du kopierst einfach Deinen persönlichen Einladungslink und teilst diesen mit Anderen.',
        btnDownload: 'QR Code herunterladen',
        bottomNote: '*Um zusätzlich Maklerservice zu bekommen, muss Dein Kontakt ebenfalls Maklerservice für mindestens einen Vertrag aktiviert haben.',
        btnCopyLink: 'Kopieren',
        alertMessage: 'Kopiert',
        btnCancel: 'ABBRECHEN',
    },
    confirmEmailPage: {
        expertTitle: 'DER EXPERTE',
        expertMsg: 'Sehr gut, Du hast dDine E-Mail Adresse erfolgreich verifiziert',
        btnTitle: 'Weiter',
    },
    authStartRegisterPage: {
        h1: 'Registrieren',
        text: 'Möchtest Du den Account für Dich als Privatperson oder für ein Unternehmen erstellen?',
        registerPrivateBtn: 'Ich bin eine Privatperson',
        registerCompanyBtn: 'Ich bin ein Unternehmen',
        registerLoginBtn: 'In bestehenden Account einloggen.',
        referalSelect: 'EMPFEHLUNGSGEBER SUCHEN',
        referalCheckbox: 'Mein Empfehlungsgeber fehlt in der Liste',
        referalCustom: 'EMPFEHLUNGSGEBER EINTRAGEN',
        referalError: 'Bitte erfasse den Namen Deines Empfehlungsgebers.',
        referalNoOptions: 'Kein Ergebnis',
    },
    authStartLoginPage: {
        h1: 'Einloggen',
        text: 'Möchtest Du Dich als Privatperson oder als ein Unternehmen einloggen?',
        registerPrivateBtn: 'Als Privatperson einloggen',
        registerCompanyBtn: 'Als Unternehmen einloggen',
        registerLoginBtn: 'Ich habe keinen Account - zur Registrierung',
    },
    onboardingSignPowerAttorneyPage: {
        h1: 'Maklervollmacht unterschreiben',
        expertMsg: 'Du beauftragst uns als Deinen Finanzmakler für Deine gewählten Verträge. Unser Service ist für Dich dauerhaft kostenfrei und kann jederzeit widerrufen werden.',
        text: 'Ich erteile der Bayerischen Versicherungsmakler GmbH die notwendige Maklervollmacht und habe die ',
        linkText: 'Maklervollmacht gelesen.',
        preNameText: 'Unterschrift - ',
        preNameCompanyText: ', vertreten durch:',
        btnNext: 'Weiter',
    },
    authForgotEmailSendPage: {
        h1: 'Gebe bitte Deine E-Mail Adresse an.',
        email: 'E-Mail Adresse',
        btnNext: 'Weiter',
        errorEmailNotFound: 'Deine E-Mail Adresse wurde bisher nicht bestätigt. Bitte überprüfe Deinen E-Mail Account inkl. Spam-Ordner bzgl. unserer E-Mail mit dem Bestätigungslink und klicke den Link.',
    },
    authForgotEmailConfirmPage: {
        h1: 'Code eintragen',
        desc: 'Wir haben Dir eine E-Mail mit einem Code gesendet. Trage ihn bitte unten ein und wähle ein neues sicheres Passwort.',
        code: 'Code aus der E-Mail eintragen',
        password: 'Neues Passwort',
        rePassword: 'Passwort wiederholen',
        btnNext: 'Passwort speichern',
    },
    impressumPage: {
        h1: 'Impressum',
        title1: 'die Bayerische Versicherungsmakler GmbH',
        text1: '{n}Geschäftsführer: Thomas Widmann{/n}' +
            '{n}Handelsregister Nummer: HRB 271912, Amtsgericht München{/n}' +
            '{n}Thomas-Dehler-Str. 25, 81737 München{/n}' +
            '{n}{/n}' +
            '{n}Steuernummer.: 143/108/70332{/n}' +
            '{n}Telefon: 089/6787 9250{/n}' +
            '{n}E-Mail: versicherungsmakler@diebayerische.de{/n}' +
            '{n}{/n}' +
            '{n}Vermittlerregister (http://www.vermittlerregister.info):{/n}' +
            '{n}Register-Nr. D-WQRW-Q03BR-25 (für § 34 d Abs. 1 GewO){/n}' +
            '{n}{/n}' +
            '{n}Finanzanlagenvermittlung (im Rahmen der Bereichsausnahme nach § 2 Abs. 6 S. 1 Nr. 8 KWG) gem. § 34f Abs. 1 Nr. 2 GewO als vertraglich gebundener Vermittler i.S.d. § 2 Abs. 10 S. 1 KWG{/n}' +
            '{n}Erlaubnis nach § 34d Abs. 1 GewO (Versicherungsmakler), Aufsichtsbehörde: IHK München Balanstr. 55-59 · 81541 München{/n}' +
            '{n}{/n}' +
            '{n}Berufsbezeichnung:{/n}' +
            '{n}Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 Gewerbeordnung; Bundesrepublik{/n}' +
            '{n}Deutschland{/n}' +
            '{n}{/n}' +
            '{n}Berufsrechtliche Regelungen:{/n}' +
            '{n}{/n}' +
            '{n}- § 34d Gewerbeordnung (GewO){/n}' +
            '{n}- §§ 59-68 Gesetz über den Versicherungsvertrag (VVG){/n}' +
            '{n}- Verordnung über die Versicherungsvermittlung und -beratung (VersVermV){/n}' +
            '{n}- Verordnung über die Finanzanlagenvermittlung (FinVermV){/n}' +
            '{n}{/n}' +
            '{n}Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage http://www.gesetze-im-internet.de eingesehen und abgerufen werden.{/n}',
        title2: 'Rechtliche Hinweise',
        text2: 'Alle auf unseren Internetseiten veröffentlichten Angaben und Informationen wurden gewissenhaft recherchiert und geprüft. Trotz aller Sorgfalt können wir für die Richtigkeit, Vollständigkeit und Aktualität der zur Verfügung gestellten Informationen keine Garantie oder Haftung übernehmen, da sich die Daten mittlerweile geändert haben können. Dies gilt auch für externe Internetseiten, auf die mithilfe eines Hyperlinks hingewiesen wird. Für die Inhalte dieser aufgrund einer Verlinkung erreichten Seiten übernehmen wir keine Verantwortung. Alle Inhalte und Gestaltungen der Internetseiten von der Bayerischen Versicherungsmakler GmbH sind urheberrechtlich geschützt. Eine Vervielfältigung dieser Seiten oder Inhalte bedarf einer vorherigen schriftlichen Zustimmung von der Bayerischen Versicherungsmakler GmbH.',
        title3: 'Haftung für Inhalte',
        text3: 'Alle Inhalte unserer Internetseite wurden mit größter Sorgfalt und Gewissenhaftigkeit erstellt. die Bayerische Versicherungsmakler GmbH übernimmt jedoch keine Gewähr für Richtigkeit, Vollständigkeit und Aktualität der Inhalte. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diesbezügliche Inhalte umgehend entfernen.',
        title4: 'Urheberrecht',
        text4: 'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir diesbezügliche Inhalte umgehend entfernen.',
    }
};

export default de_DE;
