/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractList
 * path: /onboarding/vertrag/typ
 *
 * this is a page show added contracts and list of products in onboarding proccess
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { v4 as getUid } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
import ProviderImage from '../../components/elements/ProviderImage';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import Utils from '../../lib/Utils';
import config from '../../config';
import '../../styles/terms.scss';

//icons
// import IconUmbrella from '../../assets/products/umbrella.svg';
// import IconBandair from '../../assets/products/bandair.svg';
// import IconCar from '../../assets/products/car.svg';
// import IconCardio from '../../assets/products/cardio.svg';
// import IconDocsuitcase from '../../assets/products/docsuitcase.svg';
// import IconDog from '../../assets/products/dog.svg';
// import IconFence from '../../assets/products/fence.svg';
// import IconHouse from '../../assets/products/house.svg';
// import IconLibra from '../../assets/products/libra.svg';
// import IconPatient from '../../assets/products/patient.svg';
// import IconShield from '../../assets/products/shield.svg';

import IconElectricity from '../../assets/products/electricity.png';
import IconCarNew from '../../assets/products/car.png';
import IconHand from '../../assets/products/hand.png';
import IconShieldNew from '../../assets/products/shield.png';
import IconSurge from '../../assets/products/surge.png';
import IconUmbrellaNew from '../../assets/products/umbrella.png';
import IconHospital from '../../assets/products/hospital.png';
import IconEuro from '../../assets/products/euro.png';
import IconHeart from '../../assets/products/heart.png';
import IconTooth from '../../assets/products/tooth.png';
import IconDogNew from '../../assets/products/dog.png';
import IconHome from '../../assets/products/home.png';

const ContractListPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [checkedIds, setCheckedIds] = useState([]);
    const [, setReload] = useState(0);
    const [isError, setIsError] = useState(false);

    //ref variable for popup
    const refAlert = useRef();

    //get prop 'isContractAdded' from another screen
    const { state } = useLocation();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get variable to understand that user authorized through login form
    const { isAuthorized, isCompany } = UserStore;

    //preloaded product types that needs to be shown as list of buttons
    const productsObjCompany = {
        closure_of_insurance: trans.t('onboardingContractListPage', 'closure_of_insurance'),
        public_liability_insurance: trans.t('onboardingContractListPage', 'public_liability_insurance'),
        betriebsunterbrechungsversicherung: trans.t('onboardingContractListPage', 'betriebsunterbrechungsversicherung'),
        cyber: trans.t('onboardingContractListPage', 'cyber'),
        robbery_insurance: trans.t('onboardingContractListPage', 'robbery_insurance'),
        business_car_insurance: trans.t('onboardingContractListPage', 'business_car_insurance'),
        business_law_insurance: trans.t('onboardingContractListPage', 'business_law_insurance'),
        fidelity_insurance: trans.t('onboardingContractListPage', 'fidelity_insurance'),
        landwirtschaftliche_feuerversicherung: trans.t('onboardingContractListPage', 'landwirtschaftliche_feuerversicherung'),
        truck_insurance: trans.t('onboardingContractListPage', 'truck_insurance'),
        machine_insurance: trans.t('onboardingContractListPage', 'machine_insurance'),
        property_loss_insurance: trans.t('onboardingContractListPage', 'property_loss_insurance'),
    };
    //preloaded product types that needs to be shown as list of buttons
    const productsObjPrivate = {
        private_liability: IconElectricity,
        car_liability_insurance: IconCarNew,
        home_content_insurance: IconHand,
        legal_expense_insurance: IconShieldNew,
        accident_insurance: IconSurge,
        disability: IconUmbrellaNew,
        private_health_insurance: IconHospital,
        private_pension_insurance: IconEuro,
        term_life_insurance: IconHeart,
        denture: IconTooth,
        supplementary_health_insurance: IconHospital,
        liability_insurance_for_dogs: IconDogNew,
        homeowners_insurance: IconHome,
    };
    const productsObj = isCompany ? productsObjCompany : productsObjPrivate;

    //click handler for clicking on product category
    const openProviderPage = (productId) => {
        ContractsStore.startAddingContract();
        if (!productId) {
            refAlert.current.show(trans.t('onboardingContractListPage', 'errorNoProductInList'), 'error');
            return null;
        }
        ContractsStore.setProductTypeForAddingContract(productId);
        navigate(pageUrl.onboardingProviders);
        return true;
    };

    //handler for clicking on show all list of products
    const openProductPage = () => {
        ContractsStore.startAddingContract();
        navigate(pageUrl.onboardingProducts);
    };

    //handler for edit contract
    const editContract = (contractId) => {
        ContractsStore.editContract(contractId);
        navigate(pageUrl.onboardingProducts, { state: { contractId } });
    };

    //handler for deleteing contract
    const deleteContract = (contractId) => {
        const index = checkedIds.indexOf(contractId);
        if (index !== -1) {
            setContractChecked(contractId);
        }
        ContractsStore.deleteContract(contractId);
        setReload(getUid());
    };

    //handler for deleteing contract
    const undeleteContract = (contractId) => {
        ContractsStore.undeleteContract(contractId);
        setReload(getUid());
    };

    //handler checked the checkbox
    const setContractChecked = (contractId, isDisabled) => {
        if (isDisabled) {
            return false;
        }
        const newCheckedIds = [...checkedIds];
        const index = newCheckedIds.indexOf(contractId);
        if (index !== -1) {
            newCheckedIds.splice(index, 1);
        } else {
            newCheckedIds.push(contractId);
        }
        setCheckedIds(newCheckedIds);
        return true;
    };

    //handler for "next" button
    const next = async () => {
        const { customer } = UserStore;

        if (isAuthorized && customer.isHaveBroker) {
            setIsLoading(true);
            ContractsStore.addTempContractForBroker(checkedIds);
            let res = await ContractsStore.saveTempContracts();
            setIsLoading(false);
            if (!res.status) {
                refAlert.current.error(res.msg);
                return null;
            }
            if (checkedIds.length) {
                setIsLoading(true);
                res = await ContractsStore.activateBrokerForCheckedContracts();
                setIsLoading(false);
                if (!res.status) {
                    refAlert.current.error(res.msg);
                    return null;
                }
            }
            navigate(pageUrl.secureDashboard);
            return true;
        }

        if (!checkedIds.length) {
            refAlert.current.show(trans.t('onboardingContractListPage', 'errorNotSelected'), 'error');
            setIsError(true);
            return null;
        }
        ContractsStore.addTempContractForBroker(checkedIds);
        navigate(pageUrl.onboardingContractListFinish);
        return true;
    };

    //determine is it for finapi list or for manual
    const isFinapi = state && state.hasOwnProperty('isFinapi') && state.isFinapi;

    //manual contracts
    const contracts = isFinapi ? ContractsStore.getOnboardingContracts(false) : ContractsStore.getOnboardingContracts(true);

    const renderContract = (contract, index) => {
        const isChecked = checkedIds.indexOf(contract.idValue) !== -1;

        const providerObj = DataStore.getProvider(contract.providerValue);

        return (
            <div className={!contract.isDeleted ? 'item' : 'item deleted'} key={index}>
                <div className="provider-icon">
                    <ProviderImage imageSrc={providerObj.logoUrl} title={providerObj.titleValue} />
                </div>
                <div className="texts">
                    <div className="product">{DataStore.getProductTypeTitle(contract.productTypeValue)}</div>
                    <div className="provider">
                        {contract.priceValue ? contract.priceTitle + ' ' + config.currency.title + ' ' + DataStore.getPaymentMethodTitle(contract.paymentMethodId) + ' - ' : ''}
                        {providerObj.titleValue ? Utils.addDotsToString(providerObj.titleValue) : ''}
                        {contract.numberValue ? (
                            <span className="contract-number">
                                {trans.t('onboardingContractListPage', 'contractNumber')} : {contract.numberValue}
                            </span>
                        ) : null}
                    </div>
                    <div className="edit-link">
                        {!contract.isDeleted && (
                            <ButtonLink
                                text={trans.t('onboardingContractListPage', contract.providerWithCustomValue ? 'btnEditLink' : 'btnEditRedLink')}
                                onClick={() => editContract(contract.idValue)}
                                isTouch
                                isBlue={!!contract.providerWithCustomValue}
                                isRed={!contract.providerWithCustomValue}
                            />
                        )}
                        {!contract.isDeleted && (
                            <ButtonLink
                                text={trans.t('onboardingContractListPage', 'btnDeleteLink')}
                                onClick={() => deleteContract(contract.idValue)}
                                isTouch
                                isBlue
                                className="padding-left-16"
                            />
                        )}
                        {contract.isDeleted && <ButtonLink text={trans.t('onboardingContractListPage', 'btnUnDeleteLink')} onClick={() => undeleteContract(contract.idValue)} isTouch />}
                    </div>
                </div>
                <div className={isError && !isChecked ? 'selecting red' : 'selecting'}>
                    <div
                        className={!contract.isPossibleToSelectOnboarding || contract.isDeleted ? 'checkbox disable' : 'checkbox checkbox-white'}
                        role={'checkbox-' + index}
                        onClick={() => setContractChecked(contract.idValue, !contract.isPossibleToSelectOnboarding || contract.isDeleted)}
                    >
                        <div className="checkbox-inner">
                            <div className={isChecked ? 'element checked' : 'element'} />
                        </div>
                    </div>
                    <div className={'text-blue' + (!contract.isPossibleToSelectOnboarding || contract.isDeleted ? ' text-disable' : '')}>
                        {trans.t('onboardingContractListPage', 'textSelectBroker')}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="app onboarding-contract-list-page">
            <Header isBack expertMsg={trans.t('onboardingContractListPage', isFinapi ? 'expertMsgFinapi' : 'expertMsg')} />
            <div className="content">
                <div className="wrapper-big">
                    <div className="contracts">{contracts.map((contract, index) => renderContract(contract, index))}</div>
                    {!isFinapi ? (
                        <div className="products">
                            {Object.keys(productsObj).map((productId, index) => (
                                <div className="item" key={index} role={productId} onClick={() => openProviderPage(productId)}>
                                    <div className="item-inner">
                                        {isCompany ? null : (
                                            <div className="img">
                                                <img src={productsObj[productId]} alt="" />
                                            </div>
                                        )}
                                        <div className="title">{isCompany ? productsObj[productId] : DataStore.getProductTypeTitle(productId)}</div>
                                    </div>
                                    <div className="item-arrow" />
                                </div>
                            ))}
                            <ButtonLink text={trans.t('onboardingContractListPage', 'btnShowListProducts')} onClick={openProductPage} className="margin-top-16" isTouch />
                        </div>
                    ) : null}
                </div>
            </div>
            {!!contracts.length && (
                <Bottom>
                    <Button role="btnNext" text={trans.t('onboardingContractListPage', 'btnNext')} onClick={next} isRightIcon is200 />
                </Bottom>
            )}
            <BottomLinks />
            <Alert ref={refAlert} message={state && state.isContractAdded ? trans.t('onboardingContractListPage', 'successAddedMsg') : ''} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractListPage;
