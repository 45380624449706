/**
 * Created by Max Gornostayev on 10/28/21
 *
 * This is a library for syncing data
 */

import API from '../api';
import syncLists from '../const/syncLists';

class Sync {
    static instance;

    static getInstance() {
        if (!Sync.instance) {
            Sync.instance = new Sync();
        }
        return Sync.instance;
    }

    /*
     * load values of the store
     * @storeName - name of the store
     * return @array - values of the store
     */
    getList(storeName) {
        return Object.values(syncLists).find((obj) => obj.storeName === storeName);
    }

    /*
     * load cache data from server
     * @listName - name of store in the object of fields relations from consts that needs to be recieved
     * @setFunc - function to setup particular object in store
     * @getObjFunc - function to get object of item
     * return @count - length of items
     */
    async getAllItems(storeName, setFunc) {
        const list = this.getList(storeName);
        const limit = 200;
        let items = [];
        for (let i = 0; i < list.isActive.value.length; i++) {
            let offset = 0;
            let isAllItems = false;
            const isActiveobj = { field: list.isActive.field, value: list.isActive.value[i] };
            while (!isAllItems) {
                const res = await API.sync.getElements(list.iblockId, list.fields, Object.values(list.selectFields), isActiveobj, limit, offset);
                if (!res.status || !res.data || !Array.isArray(res.data.items) || !res.data.items.length) {
                    isAllItems = true;
                } else {
                    items = items.concat(res.data.items);
                    if (res.data.items.length < limit) {
                        isAllItems = true;
                    }
                }
                offset += limit;
            }
        }

        items.map((item) => {
            if (item.hasOwnProperty('fields')) {
                setFunc(item);
            }
        });

        return items.length;
    }

    /*
     * get users list
     * @searchText - name to find
     * return @array - values of the store
     */
    async getUserList(searchText, id) {
        const res = await API.sync.getUserList(searchText, id);
        if (res.status && res.data && Array.isArray(res.data.items)) {
            return res.data.items.map((i) => ({ value: i.bitrixId, label: i.firstName + ' ' + i.lastName }));
        }
        return [];
    }
}

export default Sync.getInstance();
