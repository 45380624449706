/**
 * Created by Max Gornostayev on 2/28/22
 *
 * MODEL - contract
 */

import DataStore from '../../stores/DataStore';
import Main from './Parent';
import model from './fields/contract';
import Document from './Document';
import Utils from '../../lib/Utils';
import { v4 as getUid } from 'uuid';
import API from '../../api';

class Contract extends Main {
    isChecked = false;

    isDeleted = false;

    isManual = false;

    isBroker = false;

    documents = [];

    newDocuments = [];

    constructor(obj) {
        super(obj, model);

        if (!this.brokerStatus) {
            this.brokerStatus = 'open';
        }
    }

    /*
     * get server object from model
     */
    getServerObject() {
        const options = super.getServerObject(model);
        return { contract: { ...options.contract }, finAPI: { ...options.finAPI }, brokerTransmission: { ...options.brokerTransmission } };
    }

    /*
     * get id value
     */
    get idValue() {
        return this.contractId ? this.contractId : this.tempId;
    }

    /*
     * get temp id
     */
    get tempId() {
        return this.contractTempID ? this.contractTempID : '';
    }

    /*
     * get is temp contract (not saved)
     */
    get isTemp() {
        return !this.contractId;
    }

    /*
     * get product value
     */
    get productTypeValue() {
        return this.productType || '';
    }

    /*
     * get provider with custom value
     */
    get providerWithCustomValue() {
        if (this.provider) {
            return this.provider;
        }
        if (this.customProvider) {
            return this.customProvider;
        }
        return '';
    }

    /*
     * get provider value
     */
    get providerValue() {
        return this.provider || '';
    }

    /*
     * get provider custom value
     */
    get customProviderValue() {
        return this.customProvider || '';
    }

    /*
     * get product title
     */
    get productTitle() {
        return this.productTypeValue ? DataStore.getProductTypeTitle(this.productTypeValue) : '';
    }

    /*
     * get provider title
     */
    get providerTitle() {
        if (this.provider) {
            return DataStore.getProviderTitle(this.provider);
        }
        if (this.customProvider) {
            return this.customProvider;
        }
        return '';
    }

    /*
     * get number value
     */
    get numberValue() {
        return this.number || '';
    }

    /*
     * get number value
     */
    get priceValue() {
        return this.price || '';
    }

    /*
     * get number value
     */
    get priceTitle() {
        return this.price ? Utils.formatNumber(this.price, 2) : '';
    }

    /*
     * get newDocuments array
     */
    get getNewDocuments() {
        return Array.isArray(this.newDocuments) ? this.newDocuments : [];
    }

    /*
     * get paymentMethod id
     */
    get paymentMethodId() {
        return this.paymentMethod || '';
    }

    /*
     * get paymentMethod id
     */
    get isPossibleToSelectOnboarding() {
        return this.productTypeValue && this.providerWithCustomValue;
    }

    /*
     * get brokerStatus
     */
    get brokerStatusValue() {
        return this.brokerStatus || 'open';
    }

    /*
     * get provider logo url
     */
    get providerLogoUrl() {
        if (this.provider) {
            return DataStore.getProviderLogoUrl(this.provider);
        }
        return '';
    }

    /*
     * get yearly cost value
     */
    get yearlyCostValue() {
        if (!this.price || !this.paymentMethod) {
            return 0;
        }

        let ret = 0;
        switch (this.paymentMethod) {
            case 'daily':
                ret = 365 * this.price;
                break;
            case 'half_yearly':
                ret = 2 * this.price;
                break;
            case 'monthly':
                ret = 12 * this.price;
                break;
            case 'quarterly':
                ret = 4 * this.price;
                break;
            case 'others':
            case 'yearly':
            case 'one_time':
                ret = this.price;
                break;
            default:
                ret = 0;
        }
        return ret;
    }

    /*
     * get status value
     */
    get statusValue() {
        return this.status || 'active';
    }

    /*
     * get notes value
     */
    get notesValue() {
        return this.notes || '';
    }

    /*
     * get status title
     */
    get statusTitle() {
        return DataStore.getContractStatusTitle(this.statusValue);
    }

    /*
     * get tariff arrays
     */
    get tariffsArr() {
        return Array.isArray(this.tariffs) ? this.tariffs : [];
    }

    /*
     * get number of documents value
     */
    get numberDocumentsValue() {
        return this.numberDocuments || 0;
    }

    /*
     * get existing documents
     */
    get documentsList() {
        return this.documents || [];
    }

    /*
     * get startDateV value
     */
    get startDateValue() {
        return this.dateStart || '';
    }

    /*
     * get endDate value
     */
    get endDateValue() {
        return this.dateEnd || '';
    }

    /*
     * set id
     */
    setId(id) {
        this.contractId = id;
    }

    /*
     * set temp id
     */
    setTempId() {
        this.contractTempID = getUid();
    }

    /*
     * set product type
     */
    setProductType(productType) {
        this.productType = productType;
    }

    /*
     * set provider
     */
    setProvider({ provider, customProvider }) {
        this.provider = provider || null;
        this.customProvider = customProvider || null;
    }

    /*
     * set number
     */
    setNumber(number) {
        this.number = number;
    }

    /*
     * set price
     */
    setPrice(price) {
        this.price = Math.abs(price);
    }

    /*
     * set number
     */
    setChecked(val) {
        this.isChecked = val;
    }

    /*
     * set status
     */
    setStatus(value) {
        this.status = value;
    }

    /*
     * set status
     */
    setStartDate(value) {
        this.dateStart = value;
    }

    /*
     * set paymentMethod
     */
    setPaymentMethod(value) {
        this.paymentMethod = value;
    }

    /*
     * set bank name from finpai
     */
    setBankName(value) {
        this.bankName = value;
    }

    /*
     * set iban from finpai
     */
    setIBAN(value) {
        this.accountIban = value;
    }

    /*
     * set accoun holder name from finpai
     */
    setFinAPIAccountOwner(value) {
        this.accountOwner = value;
    }

    /*
     * set temp id
     */
    setIsBroker(val) {
        this.isBroker = val;
    }

    /*
     * set is deleted
     */
    setIsDeleted(val) {
        this.isDeleted = val;
        this.setChecked(false);
    }

    /*
     * set fields
     */
    setFields(fields) {
        Object.keys(fields).map((fieldName) => {
            this[fieldName] = fields[fieldName];
        });
    }

    /*
     * set field of the contract
     */
    addNewDocuments(documents) {
        this.newDocuments = [...documents];
    }

    /*
     * set contract's fields from finapi object
     * example of finapi object - https://docs.finapi.io/#get-/cases/-caseId-/reports/contracts/insurance check Example tab and 'contractsData' array
     */
    setFinAPIData(obj, accountData) {
        this.isManual = false;
        if (obj.contractId) {
            this.contractTempID = obj.contractId;
        }

        //set productTypeid
        let productType = obj.contractType ? DataStore.getListElementIdFromFinAPI('product', obj.contractType) : '';
        productType = productType || 'other_insurances';
        this.setProductType(productType);

        //set providerId
        const provider = obj.companyDetails && obj.companyDetails.companyId ? DataStore.getListElementIdFromFinAPI('provider', obj.companyDetails.companyId) : '';
        const customProvider = provider ? '' : obj.companyName;
        this.setProvider({ provider, customProvider });

        const isBrokerPossible = DataStore.getIsPossibleBroker(productType, provider);
        this.setIsBroker(isBrokerPossible);

        //set status
        // let status = obj.contractStatus ? DataStore.getListElementIdFromFinAPI('status', obj.contractStatus) : '';
        // status = status ? status : 'active';
        // this.setStatus(status);

        //set paymentMethod
        const paymentMethod = obj.billing && obj.billing.period ? DataStore.getListElementIdFromFinAPI('paymentMethod', obj.billing.period) : '';
        this.setPaymentMethod(paymentMethod);

        //set price
        const price = obj.billing && obj.billing.lastBillingAmount ? obj.billing.lastBillingAmount : 0;
        this.setPrice(price);

        //set number
        const number = obj.contractNumber ? obj.contractNumber : '';
        this.setNumber(number);

        //set startDate
        const startDate = obj.billing && obj.billing.lastBillingDate ? obj.billing.lastBillingDate : '';
        this.setStartDate(startDate);

        //set bankName and iban
        let bankName = '';
        let iban = '';
        let accountOwner = '';
        if (accountData.length === 1) {
            const account = accountData[0];
            bankName = account.bankName ? account.bankName : '';
            iban = account.accountIban ? account.accountIban : '';
            accountOwner = account.accountId ? account.accountId : '';
        } else if (accountData.length > 1) {
            for (let i = 0; i < accountData.length; i++) {
                const account = accountData[i];
                bankName = account.bankName ? account.bankName : '';
                iban = account.accountIban ? account.accountIban : '';
                accountOwner = account.accountId ? account.accountId : '';
                let isFound = false;
                if (bankName && iban && account.transactions && account.transactions.length) {
                    for (let j = 0; j < account.transactions.length; j++) {
                        const transaction = account.transactions[j];
                        if (transaction && transaction.contractIds && transaction.contractIds.length && transaction.contractIds.indexOf(this.id) !== -1) {
                            isFound = true;
                            break;
                        }
                    }
                }
                if (isFound) {
                    break;
                }
            }
        }
        this.setBankName(bankName);
        this.setIBAN(iban);
        this.setFinAPIAccountOwner(accountOwner);
    }

    /*
     * save new documents for contract from newDocuments array
     */
    async saveNewDocuments() {
        if (!this.isTemp) {
            for (const document of this.newDocuments) {
                await this.saveNewDocument(document);
            }
        }
        this.newDocuments = [];
    }

    /*
     * save document object for contract
     */
    async saveNewDocument(document) {
        const base64 = await Utils.getBase64(document);
        const obj = {
            documentTempId: getUid(),
            contractId: this.idValue,
            file: base64,
            filename: document.name,
            fileType: document.type,
        };
        const res = await API.contract.saveDocument(obj);
        return res;
    }

    /*
     * update contract
     */
    async update(fields) {
        this.setFields(fields);
        const obj = this.getServerObject();
        delete obj.brokerTransmission;
        delete obj.finAPI;
        const res = await API.contract.update(obj);
        return res;
    }

    /*
     * change number of documents on difference that get from param
     * @param diff - int
     */
    changeDocumentsNumber(diff = 0) {
        this.numberDocuments += diff;
    }

    /*
     * load documents
     */
    async loadDocuments() {
        const documents = [];
        const res = await Document.getAllDocuments(this.idValue);
        if (res.status && Array.isArray(res.data)) {
            res.data.map((document) => documents.push(new Document(document)));
        }
        this.documents = documents;
        return documents;
    }

    /*
     * delete document
     */
    async deleteDocument(documentId) {
        const res = await Document.delete(documentId);
        if (res.status) {
            const docIndex = this.documents.findIndex((document) => document.id === documentId);
            if (docIndex !== -1) {
                this.documents.splice(docIndex, 1);
            }
        }
        return res;
    }

    //------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------
    // static methods are listed below
    //------------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------------

    /*
     * get temp contract for adding new in onboarding
     */
    static getTempContract() {
        const contract = new Contract();
        contract.setTempId();
        contract.isManual = true;
        return contract;
    }

    /*
     * save contracts from arr objects
     */
    static async saveMultiContracts(arr) {
        const res = await API.contract.createMulti(arr);
        return res;
    }

    static copyContract(copiedContract) {
        const contract = new Contract();
        Object.assign(contract, { ...copiedContract });
        return contract;
    }

    /*
     * remove contract from the database
     */
    static async remove(contractId) {
        const res = await API.contract.remove(contractId);
        return res;
    }

    /*
     * get contracts list
     */
    static async getContracts() {
        const res = await API.contract.getContracts();
        return res;
    }

    /*
     * get contracts list
     */
    static async activateBrokerForContracts(checkedIds) {
        const res = await API.contract.activateBrokerForContracts(checkedIds);
        return res;
    }
}

export default Contract;
