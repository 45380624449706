/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a header for secure pages like dashboard, contracts list, etc
 */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SlideMenu from './SlideMenu';
import pageURLs from '../../const/pages';
import trans from '../../trans';

import IconLogo from '../../assets/icon-logo.png';
import IconSlideMenu from '../../assets/icon-slide-menu-white.png';

export default function Header({ isBack, children, isTopMenu, isSlideMenu, className, title, selected, isGradient, isBackUnderTitle }) {
    //state variables
    const [isOpenedSlide, setIsOpenedMenu] = useState(false);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //determine class of the menu
    let clssName = 'header-secure';
    if (className) {
        clssName += ' ' + className;
    }
    if (isGradient) {
        clssName += ' blue-gradient';
    }

    //handler for open slide menu
    const openSlideMenu = () => {
        setIsOpenedMenu(true);
    };

    return (
        <div className={clssName}>
            <div className="top">
                <div className="wrapper-big">
                    <div className="inner">
                        <div className="left">
                            {!!isBack && <div className="back" onClick={() => navigate(-1)} />}
                            {!isBack && (
                                <div className="logo" onClick={() => navigate(pageURLs.secureContractsList)}>
                                    <img src={IconLogo} alt="logo" />
                                </div>
                            )}
                        </div>
                        <div className="right">
                            {!!isTopMenu && (
                                <div className="menu-top">
                                    <div className={selected === pageURLs.secureContractsList ? 'selected' : ''} onClick={() => navigate(pageURLs.secureContractsList)}>
                                        {trans.t('header', 'menuTopContracts')}
                                    </div>
                                    <div className={selected === pageURLs.secureCustomerProfile ? 'selected' : ''} onClick={() => navigate(pageURLs.secureCustomerProfile)}>
                                        {trans.t('header', 'menuTopProfile')}
                                    </div>
                                </div>
                            )}
                            {!!isSlideMenu && (
                                <div className="menu-slide-icon" onClick={openSlideMenu}>
                                    <img src={IconSlideMenu} alt="slide" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper-big">
                {!!isBackUnderTitle && <div className="back-in-inner" onClick={() => navigate(-1)} />}
                {!!title && <h1>{title}</h1>}
                {!!children && <div className="children">{children}</div>}
                {!!isTopMenu && <SlideMenu isOpened={isOpenedSlide} closeFunc={() => setIsOpenedMenu(false)} />}
            </div>
        </div>
    );
}
