/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is configuration file with public information
 */

import countries from '../const/countries';

const config = {
    env: 'live',
    webappUrl: 'https://webapp.diebayerische-versicherungsmakler.de/start/register',
    isDebug: false,

    api: {
        timeout: 45000,
        getTokenPath: '/api/private/v1/token/jwt',
    },

    storesExpiretion: {
        customer: 4320000, //12 hours
        contracts: 360000, //1 hour
        data: 4320000, //12 hours
    },

    urls: {
        brokerTemplate: 'https://crm.bay4you.de/~p4i23',
        healthTemplate: 'https://crm.bay4you.de/~A2hKN',
        privacyTemplate: 'https://crm.bay4you.de/~zf4Bi',
        // impressumLink: 'https://app.diebayerische-versicherungsmakler.de/impressum/',
        newTicket: 'https://myfeelix.de/Angebotsservice/', //for owl it should be https://owl-direkt.de/vergleichsrechner/
    },

    bitrix24Urls: {
        supportUrl: 'http://',
        contractPopupUrl: 'http://',
        cancelBroker: 'https://formular.bay4you.de/maklerauftrag_kuendigen/',
    },

    finAPI: {
        env: 'live',
        timeout: 60000,
        profileId: 'cc4e4222-dde2-4ea7-b205-a16ba6400bdf',
    },

    youtube: {
        onboardingFinapi: 'LpDfl6CdpaE',
        onboardingInfo: 'wAVo4WCAXDs',
        onboardingSign: '0ctmX_7mGqk',
    },

    dateFormat: {
        week: 'MM/DD',
        serverDate: 'YYYY-MM-DD',
        serverDateWithTime: 'YYYY-MM-DD HH:mm:ss',
        date: 'DD.MM.YYYY',
        logDate: 'YYYYMMDDHms',
    },

    digit: {
        decimalSeparator: ',',
        separator1000: '.',
    },

    countries: ['deutschland', 'austrian'],

    currency: {
        title: 'EUR',
        sign: '€',
    },

    validationCountryCode: countries.deutschland.code,
};
export default config;
